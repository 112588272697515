import React from "react";
import css from "./BackArrow.module.css";

import Arrow from "./assets/blue_back_arrow.png";

const BackArrow = (props) => {
  const clickHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div className={css.root} onMouseDown={clickHandler}>
      <img src={Arrow} />
      <div className={css.text}>Back</div>
    </div>
  );
};

export default BackArrow;
