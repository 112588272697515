import React, { useState } from "react";
import css from "./MindsetReport.module.css";

import studentData from "../../../../../../data/students.json";
import data from "../../../../../../data/mindsetData.json";

import DropDown from "../../../../../../components/DropDown";
import Checkbox from "./Checkbox";

import rolloverTips from "../../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const MindsetReport = (props) => {
  const [checked, setChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const closeHandler = () => {
    props.onClose();
  };

  const checkItemHandler = (p) => {
    const checkedData = [...checked];

    if (p.id == -1) {
      const turnOn = checkedData.filter((x) => x == false).length > 0;

      for (let i = 0; i < 10; i++) {
        checkedData[i] = turnOn;
      }
    } else {
      checkedData[p.id] = !checkedData[p.id];
    }

    setChecked(checkedData);
  };

  const buttonEnterHandler = (ev) => {
    const rect = ev.currentTarget.getBoundingClientRect();
    toolTips.system.getManager("disabled").show(
      toolTips.createToolTip(
        rect.left + rect.width / 2 - 25,
        rect.top + rect.height,
        {
          style: { width: "200px" },
          title: rolloverTips["mindset_exportbutton"].title,
          text: rolloverTips["mindset_exportbutton"].text,
        }
      )
    );
  };

  const buttonLeaveHandler = (ev) => {
    toolTips.system.getManager("disabled").hide();
  };

  const colors = {
    Above: "#2f8718",
    On: "#de6502",
    Below: "#c92222",

    Advanced: "#282828",
    Proficient: "#7AB946",
    Basic: "#F89D02",
    Partial: "#1886C7",
    Minimal: "#D92128",
  };

  const rows = data[props.subject][props.grade].students.map((x, index) => (
    <div key={index} className={css.row}>
      <div className={css.checkbox}>
        <Checkbox
          id={index}
          checked={checked[index]}
          onChange={checkItemHandler}
        />
      </div>
      <div className={css.student}>
        {studentData.find((student) => student.id == x.id).name}
      </div>
      <div className={css.proficiency}>
        <span style={{ color: colors[x.proficiency] }}>
          {x.proficiency} Level
        </span>
      </div>
      <div className={css.responses}>{x.correctResponses}</div>
      <div className={css.attempts}>{x.incorrectAttempts}</div>
      <div className={css.hints}>{x.totalHints}</div>
      <div className={css.hints}>{`0hr ${x.timeOnSkill}min`}</div>
    </div>
  ));

  return (
    <div className={css.root}>
      <div className={css.popup}>
        <div className={css.close} onMouseDown={closeHandler}></div>
        <div className={css.top}>
          This is a target skill assigned for which the majority of your class
          has a high number of incorrect responses and / or used many hints.
        </div>
        <div className={css.dropdown}>
          <DropDown
            width="300px"
            selectedIndex={0}
            data={[{ text: props.subject == "ela" ? "RL.3.1" : "3.0A.1" }]}
            mindset
            /*
                  rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
          />
        </div>
        <div className={css.actions}>
          <div className={css.left}>
            <div className={css.dropdown}>
              <DropDown
                width="200px"
                selectedIndex={0}
                data={[{ text: "Actions to take" }]}
                mindset
              />
            </div>
          </div>
          <div className={css.right}>
            <div className={css.text}>Show:</div>
            <div className={css.dropdown}>
              <DropDown
                width="150px"
                selectedIndex={0}
                data={[{ text: "All Students" }]}
                mindset
              />
            </div>
            <div
              onMouseEnter={buttonEnterHandler}
              onMouseLeave={buttonLeaveHandler}
              className={css.button}
            >
              <div className={css.icon} />
              <div className={css.text}>Export</div>
            </div>
          </div>
        </div>
        <div className={css.row + " " + css.first}>
          <div className={css.checkbox}>
            <div className={css.cb}>
              <Checkbox
                id={-1}
                checked={checked.filter((x) => x == false).length == 0}
                onChange={checkItemHandler}
              />
            </div>
          </div>
          <div className={css.student}>
            <div className={css.center}>Student Name</div>
          </div>
          <div className={css.proficiency}>
            <div className={css.center}>Proficiency</div>
          </div>
          <div className={css.responses}>Correct Responses</div>
          <div className={css.attempts}>Incorrect Attempts</div>
          <div className={css.hints}>
            <div className={css.center}>Total Hints</div>
          </div>
          <div className={css.time}>
            <div className={css.center}>Time on Skill</div>
          </div>
        </div>
        {rows}
      </div>
    </div>
  );
};

export default MindsetReport;
