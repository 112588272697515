import React from "react";
import css from "./PriorityStar.module.css";

export const PriorityStar = (props) => {
  return (
    <div className={css.root}>
      <img src="https://practice.gogetwaggle.com/teacher/assets/images/PriorityStandards.png?v=3.0" />
    </div>
  );
};

export default PriorityStar;
