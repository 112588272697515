const initialState = {
  username: "Danielle White",
  totalMinutesSpent: 234,
  totalSessions: 17,
  averageSessionsPerWeek: 0,
  averageMinutesPerSession: 14,
  averageThemesComp: 0,
  averageScore: 93,
};

export default (state = initialState, action) => {
  return state;
};
