import React from "react";
import css from "./ResultsBar.module.css";

import Link from "../Link";

const ResultsBar = (props) => {
  const classes = [css.root];

  if (props.dark) classes.push(css.dark);

  const style = {};
  style.width = props.width;
  style.background = props.background;
  style.boxShadow = props.shadow ? "-5px 0 5px -3px rgba(0, 0, 0, 0.25)" : null;

  const linkStyle = {};
  linkStyle.fontSize = props.fontSize;

  return (
    <div style={style} className={classes.join(" ")}>
      <div className={css.link}>
        <Link
          color={props.color}
          highlightColor={props.highlightColor}
          style={linkStyle}
          underline={false}
          cursor={false}
          bold={props.bold}
        >
          {props.children}
        </Link>
      </div>
    </div>
  );
};

export default ResultsBar;
