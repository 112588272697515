/**
 * pluralize( {0: "Option for 0", 1: "Option for 1", 2: "Option for 2", n: "Option for other"})
 *
 * @param {*} options
 * @param {*} count
 */

export const pluralize = (options, count) => {
  return options[count] || options.n;
};
