import React from "react";
import { gradeToIndex } from "../../../routing-util";
import { OnOff } from "./OnOff";
import Popup from "../Popup/Popup";
import { ButtonBar, SmallBlueButton, SmallWhiteButton } from "../../../components/BlueButton";
import styled from "@emotion/styled";
import { LightColors } from "../../../light-theme";

const AssignmentSection = styled.div({
  borderBottom: `1px solid ${LightColors.grey1}`,
  paddingTop: 20,
  position: "relative"
})

export const AssignmentSettings = ({ onClose, grade, subject }) => {
  const g = gradeToIndex(grade);
  const topMessage =
    g < 3 && subject === "math" ? (
      <p >
        Not available until the Growth Measure assessment has been completed.
      </p>
    ) : g < 3 && subject === "ela" ? (
      <p >Not available for this class.</p>
    ) : (
      <p>
        Students will receive content from the grade that the Growth Measure has
        placed them.
      </p>
    );

  return (
    <Popup onClose={onClose} width={550} title="Auto Assign Options">


      <AssignmentSection>
        <b>Instructional Level (Growth Measure)</b>
        {topMessage}
        <OnOff defaultValue={g > 2} disabled={g < 3} />
      </AssignmentSection>
      <AssignmentSection>
        <b>Grade Level</b>
        <p>Students will receive assignments based on their grade level.</p>
        <OnOff />
      </AssignmentSection>

      <AssignmentSection>
        <b>Practice Fluency Timer</b>
        <p>
          Toggles the timer on all Practice Fluency assignments. You <br />{" "}
          can also adjust it for each student at the time of assigning.
        </p>
        <OnOff />
      </AssignmentSection>

      <ButtonBar>
        <SmallWhiteButton onClick={onClose} >
          Cancel
        </SmallWhiteButton>
        <SmallBlueButton onClick={onClose} >
          Save
        </SmallBlueButton>
      </ButtonBar>
    </Popup>
  );
};
