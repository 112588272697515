import React, { useState } from "react";
import Link from "../../../../../components/Link";
import { Back } from "../Back";
import css from "./GoalPage.module.css";

import Button from "../Button";

import data from "../../../../../data/trackAssignmentsGoal.json";
import Tab from "../../../../../components/Tab";
import Skills from "./Skills";
import Summary from "./Summary";
import Students from "./Students";
import { Writing } from "./Writing";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";
import { GoalContents } from "./GoalContents";
import { BlueButton } from "../../../../../components/BlueButton";

const shouldShowWriting = props => {
  // Request 3/9/2024 - don't show writing tab on grade 3
  return props.subject === "ela" && props.grade !== '3'
}

const SkillPage = (props) => {
  const [tab, setTab] = useState(0);

  const skillData = data[props.subject.toLowerCase()][props.grade];

  const backHandler = () => {
    if (props.onClose) props.onClose();
  };

  console.log(skillData);

  let contents;
  switch (tab) {
    case 0:
      contents = (
        <Summary onViewSkillGaps={() => setTab(1)} data={skillData.summary} />
      );
      break;
    case 1:
      contents = <Skills data={skillData.skills} />;
      break;
    case 2:
      contents = <Students data={skillData.students} />;
      break;
    case 3:
      contents = <Writing />;
      break;
  }

  return (
    <div className={css.root}>
      <Back onClick={backHandler} />

      <BlueButton
        style={{ float: "right", padding: "7px 25px", height: "inherit" }}
        onMouseEnter={(ev) =>
          rolloverTipBegin(ev, "TA_literarystyle_summary_exportbutton")
        }
        onMouseLeave={rolloverTipEnd}
      >
        Export/Print
      </BlueButton>

      <div className={css.title}>
        <div>{props.title}</div>
      </div>
      <div className={css.assignment}>
        <div className={css.pair}>
          <div className={css.text}>Start:</div>
          <Link
            underline={false}
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "TA_literarystyle_startdate")
            }
            onMouseLeave={rolloverTipEnd}
          >
            08/03/2022
          </Link>
        </div>
        <div className={css.pair}>
          <div className={css.text}>Due:</div>
          <Link
            underline={false}
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "TA_literarystyle_duedate")
            }
            onMouseLeave={rolloverTipEnd}
          >
            08/10/2022
          </Link>
        </div>
        <Link size="14px" bold underline={false}>
          Update Assigned Students
        </Link>
      </div>

      <div className={css.tabs}>
        <Tab onClick={() => setTab(0)} selected={tab == 0}>
          Summary
        </Tab>
        <Tab onClick={() => setTab(1)} selected={tab == 1}>
          Skills
        </Tab>
        <Tab onClick={() => setTab(2)} selected={tab == 2}>
          Students
        </Tab>
        {shouldShowWriting(props) && (
          <Tab onClick={() => setTab(3)} selected={tab == 3}>
            Writing
          </Tab>
        )}
      </div>
      <GoalContents>{contents}</GoalContents>
    </div>
  );
};

export default SkillPage;
