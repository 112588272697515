import * as React from "react"

function BlueUpArrow(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 0C4.483 0 0 4.483 0 10s4.483 10 10 10 10-4.483 10-10S15.517 0 10 0zm0 4.167l4.167 5h-3.334v6.666H9.167V9.167H5.833l4.167-5z"
                fill="#009DD1"
            />
        </svg>
    )
}

export default BlueUpArrow
