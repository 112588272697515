import * as React from "react"

function PencilIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8553_39820)" fill="#1261BF">
        <path d="M1.333 15.333h.134l3-.6-3.2-3.2-.6 3c-.067.2 0 .467.2.6.133.134.266.2.466.2zM9.078 3.34l-7.071 7.07 3.63 3.63 7.07-7.07-3.63-3.63zM15.133 4.467a.644.644 0 000-.934L12.467.867a.645.645 0 00-.934 0L10 2.4 13.6 6l1.533-1.533z" />
      </g>
      <defs>
        <clipPath id="clip0_8553_39820">
          <path
            fill="#fff"
            transform="translate(.667 .667)"
            d="M0 0H14.6667V14.6667H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PencilIcon
