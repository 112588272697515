import * as React from "react"

function GemsIcon(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.1 8.79c.1.105.1.21.1.21l10 13.684.1.105s0 .106.1.106l.1.105h1s.1 0 .1-.105l.1-.105.1-.106L22.8 9s.1-.105.1-.21c0 0 0-.106.1-.106v-.316-.105c0-.105 0-.21-.1-.316 0 0 0-.105-.1-.105v-.105l-4-6.316c0-.105-.1-.105-.2-.21-.1-.106-.2-.106-.3-.211H5.7c-.1 0-.2.105-.3.21l-.2.211-4 6.316v.105s0 .105-.1.105c-.1.21-.1.21-.1.316v.105c0 .106 0 .316.1.421zM12 18.473L9.4 9.42h5.3L12 18.474zm6.3-14l1.8 2.842h-2.7l.9-2.842zM20 9.42l-5.4 7.369 2.2-7.369H20zm-3.4-6.316l-.9 2.842-1.8-2.842h2.7zm-2.5 4.21H9.9L12 3.948l2.1 3.369zm-4-4.21L8.3 5.947l-.9-2.842h2.7zM9.4 16.79L4 9.421h3.2l2.2 7.369zM5.7 4.475l.9 2.842H3.9l1.8-2.842z"
                fill="#000"
            />
        </svg>
    )
}

export default GemsIcon
