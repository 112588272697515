export const LightColors = {
  blue: "#1261BF",
  blue2: "#2D9CDB",
  yellow: "#FFBB00",
  white: "#FBFCFD",
  black: "#131313",
  grey1: "#BFC0C0",
  grey2: "#F4F4F4",
  grey3: "#F4F4F4",
  orange: "#DE6502",
  red: "#C92222",
  green: "#2F8718",
  softYellow: "#FFFFF2",
  purple: "#7c30bc"
};
