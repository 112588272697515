import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import css from "./Popup.module.css";
import styled from "@emotion/styled";
import { LightColors } from "../../../light-theme";

const PopupDialog = styled.div(({ minHeight = 0, width = 550 }) => ({
  width: width,
  minHeight,
  backgroundColor: LightColors.white,
  boxShadow: '0px 4px 16px 0px #00000066',
  borderTop: `4px solid ${LightColors.yellow}`,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100,
  borderRadius: 8,

}));

const Header = styled.div({
  width: '100%',
  height: 50,
  paddingTop: 15,
  fontFamily: 'Open Sans',
  fontSize: 22,
  fontWeight: 700,
  lineHeight: "30px",
  letterSpacing: '0em',
  textAlign: 'center',
  borderBottom: `1px solid ${LightColors.grey1}`,
});

const Body = styled.div({
  padding: "10px 20px"
});


const CloseButton = styled.button({
  position: 'absolute',
  top: 10,
  right: 10,
  fontSize: 18,
  fontWeight: 700,
  background: 'none',
  border: 'none',
  cursor: 'pointer',

});

const Popup = (props) => {
  const history = useHistory();
  const [tab, setTab] = useState(props.index);

  const classes = props.selectClass ? <div /> : <div />;

  const contents = props.children[tab];

  const closeHandler = () => {
    if (props.onClose) props.onClose();
  };




  return (

    <PopupDialog width={props.width} minHeight={props.minHeight}>
      <Header>
        {props.title}
        {
          props.onClose && <CloseButton onClick={props.onClose}>⨉</CloseButton>
        }
      </Header>
      <Body>
        {props.children}
      </Body>
    </PopupDialog>

  );
};

export default Popup;
