import * as React from "react"

function Alert(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.9 20.5l-10-19c-.3-.7-1.4-.7-1.8 0l-10 19c-.2.3-.2.7 0 1 .2.3.5.5.9.5h20c.4 0 .7-.2.9-.5.1-.3.1-.7 0-1zM12 19c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-4h-2V8h2v7z"
        fill="#DE6502"
      />
    </svg>
  )
}

export default Alert
