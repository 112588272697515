import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import css from "./Popup.module.css";

import { Tab, TabGroup } from "../../../components/Tab/Tab"
// import Tab from "./Tab";
import Button from "./Button";
import Popup from "../Popup"

const StudentPopup = (props) => {
  const history = useHistory();
  const [tab, setTab] = useState(props.index);

  const classes = props.selectClass ? <div /> : <div />;

  const contents = props.children[tab];

  const tabHandler = (index) => {
    setTab(index);
  };



  const closeHandler = () => {
    if (props.onClose) props.onClose();
  };

  const viewReportsHandler = () => {
    history.push(`reports-${props.subject}-${props.grade}-growth`);
  };

  return (
    <Popup title={props.title} width={props.width} onClose={closeHandler} minHeight={600}>

      {/* <img
        width="11px"
        height="10px"
        onMouseDown={closeHandler}
        className={css.close}
        src="https://practice.gogetwaggle.com/teacher/assets/images/growth-report-modal-close-icon.png?v=3.0"
      /> */}

      <div className={css.subheader}>
        {classes}
        {/* {props.hideGrowth || (
          <Button
            onClick={viewReportsHandler}
            icon="https://practice.gogetwaggle.com/teacher/assets/images/growth-report-view-growth-icon.png?v=3.0"
            background="#82ce79"
          >
            View Growth
          </Button>
        )} */}
      </div>
      <TabGroup>
        {props.tabs.map((x, index) => (
          <Tab
            key={index}
            selected={index == tab}
            onClick={() => tabHandler(index)}
            name={x}
          >{x}</Tab>
        ))}
      </TabGroup>
      {contents}
    </Popup >
  );
};

export default StudentPopup;
