import React from "react";
import css from "./Students.module.css";

import Button from "../../Button";
import Box from "../../../Home/Box";
import Bold from "../../../../../components/Bold";

import data from "../../../../../data/students.json";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const Students = (props) => {
  const studentHandler = (index) => {
    if (props.onStudentSelected) props.onStudentSelected(index);
  };

  const studentRows = data.map((x, index) => {
    const gemTotal =
      x.score.diamond.overall +
      x.score.saphire.overall +
      x.score.ruby.overall +
      x.score.emerald.overall;
    const pointTotal = x.score.points.overall;

    const hours = Math.floor(x.timeLastWeek / 60);
    const minutes = x.timeLastWeek % 60;

    return (
      <div key={index} className={css.row}>
        <Box
          style={{
            textIndent: "20px",
            textAlign: "left",
            color: "#056B90",
            fontWeight: "bold",
          }}
          borderRight
          borderBottom
          width="219px"
        >
          <span
            onMouseDown={() => studentHandler(index)}
            style={{ cursor: "pointer" }}
            onMouseEnter={(ev) => {
              if (index != 0)
                rolloverTipBegin(ev, "Home_roster_viewreportbutton");
            }}
            onMouseLeave={rolloverTipEnd}
          >{`${x.lastName}, ${x.firstName}`}</span>
        </Box>
        <Box borderRight borderBottom width="120px">
          {x.assignments}
        </Box>
        <Box
          borderRight
          borderBottom
          width="130px"
        >{`${hours}hr ${minutes}min`}</Box>
        <Box borderBottom borderRight width="110px">
          {pointTotal}
        </Box>
        <Box borderBottom borderRight width="100px">
          {x.lastLogin}
        </Box>
      </div>
    );
  });

  return (
    <div className={css.root}>
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "classactivity_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>
        <div style={{ width: "10px" }} />
        <Button
          width="155px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "classactivity_changeselectionsbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Change Selections
        </Button>
      </div>
      <div className={css.details}>
        <div>
          <Bold>Time : </Bold>Overall
        </div>
        <div>
          <Bold>Class Average Time : </Bold>6hr 32min
        </div>
      </div>
      <div className={css.row}>
        <Box
          style={{ fontWeight: "bold" }}
          borderRight
          borderBottom
          width="219px"
          background="#EDF6F9"
        >
          STUDENT NAME
        </Box>
        <Box
          style={{ fontWeight: "bold", lineHeight: "20px" }}
          borderRight
          borderBottom
          width="120px"
          background="#EDF6F9"
        >
          ASSIGNMENTS COMPLETED
        </Box>
        <Box
          style={{ fontWeight: "bold" }}
          borderRight
          borderBottom
          width="130px"
          background="#EDF6F9"
        >
          TOTAL TIME
        </Box>
        <Box
          style={{ fontWeight: "bold" }}
          borderBottom
          borderRight
          width="110px"
          background="#EDF6F9"
        >
          POINTS EARNED
        </Box>
        <Box
          style={{ fontWeight: "bold" }}
          borderBottom
          width="100px"
          background="#EDF6F9"
        >
          LAST LOGIN
        </Box>
      </div>
      <div className={css.students}>{studentRows}</div>
    </div>
  );
};

export default Students;
