import React from "react";
import css from "./CheckBox.module.css";

const CheckBox = (props) => {
  const classes = [css.root];
  if (props.selected) classes.push(css.selected);

  return <div className={classes.join(" ")} onMouseDown={props.onClick}></div>;
};

export default CheckBox;
