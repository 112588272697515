const text = `					
					
Student	Proficiency Correct 	Proficiency Total	Practice Proficiency	Mindset	Time on (total minutes)
Bernard Massey	1	5	20	Avg.	120
Neil Cruz	1	5	40	Avg.	125
Rahul Kumar	1	5	60	Avg.	182
Franklin Guzman	1	5	80	Avg.	40
Lula Austin	1	5	100	Avg.	40
Angela Vargas	1	5	20	Avg.	40
Tina Powell	1	5	40	Avg.	40
Anita Dass	1	5	60	Avg.	40
Viola Moss	1	5	80	Avg.	40
Alma Herrera	1	5	100	Avg.	40`;

const parse = (rawText) => {
  const output = [];
  const lines = rawText.split("\n");

  for (var i = 4; i < lines.length; i++) {
    const line = lines[i];
    const tokens = line.split("\t");

    const row = {};
    row.name = tokens[0];
    row.correct = tokens[1];
    row.total = tokens[2];
    row.proficiency = tokens[3];
    row.mindset = tokens[4];
    row.time = tokens[5];

    output.push(row);
  }

  return output;
};

const data = parse(text);

export default data;
