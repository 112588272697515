import React from "react";
import css from "./PointEntry.module.css";

const PointEntry = (props) => {
  return (
    <div className={css.root + (props.index == 0 ? " " + css.first : "")}>
      <div className={css.index}>{`${props.index + 1})`}</div>
      <div className={css.student}>{props.data.student}</div>
      <div className={css.value}>{`${props.data.value} Points`}</div>
    </div>
  );
};

export default PointEntry;
