import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import css from "./Home.module.css";
import { LightColors } from "../../../light-theme";

// Not really functional, just for show
export const OnOff = ({ defaultValue = false, disabled = false }) => {
  const [on, setOn] = useState(defaultValue);
  const toggle = useCallback(() => disabled || setOn((o) => !o), [disabled]);
  return <Slider onClick={toggle} on={on}>
    <Ball on={on} />
  </Slider>;
};

const Ball = styled.div(({ on }) => ({
  borderRadius: "50%",
  position: "absolute",
  cursor: "pointer",
  width: 26,
  height: 26,
  backgroundColor: LightColors.white,
  left: on ? 30 : 3,
  top: 2,
  WebkitTransition: ".4s",
  transition: ".4s",
}));

const Slider = styled.div(({ on }) => ({
  borderRadius: 15,
  border: '1px solid #bfc0c0',
  position: 'absolute',
  cursor: 'pointer',
  width: 60,
  height: 30,
  right: 0,
  top: 20,
  WebkitTransition: '.4s',
  transition: '.4s',
  backgroundColor: on ? LightColors.blue : '#bfc0c0',
  borderColor: on ? LightColors.blue : '#bfc0c0',
}))