import React from "react";

export const BackIcon = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="11" fill="white" />
    <path
      d="M13.1992 5.86664L7.33255 11.2444L13.1992 16.1333"
      stroke="black"
      strokeWidth="2.93333"
      strokeLinecap="round"
    />
  </svg>
);
