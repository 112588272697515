import React from "react";
import css from "./Link.module.css";

import { components } from "@indieasy.software/indieasy-components";
import { LightColors } from "../../light-theme";
const { Markup } = components;

const Link = (props) => {
  const markupProps = {};
  markupProps.color = props.selected
    ? props.selectedColor || "black"
    : props.color || LightColors.blue;
  markupProps.highlightColor = props.selected
    ? props.selectedHighlightColor || "black"
    : props.highlightColor || "#449dac";
  markupProps.underline = !props.selected;

  markupProps.bold = props.selected || props.bold;
  markupProps.cursor = props.selected ? "default" : "pointer";
  markupProps.size = props.size;

  if (props.over) markupProps.color = markupProps.highlightColor;

  if (!props.underline && props.underline != undefined)
    markupProps.underline = false;

  if (!props.cursor && props.cursor != undefined)
    markupProps.cursor = "default";

  const clickHandler = () => {
    if (props.onClick) props.onClick(props);
  };

  const mouseEnterHandler = (ev) => {
    if (props.onMouseEnter) props.onMouseEnter(ev, props);
  };

  const mouseLeaveHandler = (ev) => {
    if (props.onMouseLeave) props.onMouseLeave(ev, props);
  };

  return (
    <Markup
      {...props}
      {...markupProps}
      onClick={clickHandler}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      {props.children}
    </Markup>
  );
};

export default Link;
