export default `IM Lesson Number	Where It Goes		Learning Goal	LG Popup	Skill Boost	Skill Popup	Waggle v.3.x Lesson	Lesson Popup	Games	Games Popup	Priority
	0	K.CC: Counting and Cardinality									
	1	K.CC.A.3: Write numbers from 0 to 20. Represent a number of objects with a written numeral 0-20 (with 0 representing a count of no objects).									
							Represent 0	Represent 0||K.CC.A.3 Write numbers from 0 to 20. Represent a number of objects with a written numeral 0-20 (with 0 representing a count of no objects).			
							Count and Write 0, 1, and 2				
							Count and Write 3 and 4				
							Count and Write 5				
							Count and Write 6 and 7				
							Count and Write 8 and 9				
							Count and Write 10				
	1	K.CC.B.4: Understand the relationship between numbers and quantities; connect counting to cardinality.									
	2	K.CC.B.4.a: When counting objects, say the number names in the standard order, pairing each object with one and only one number name and each number name with one and only one object.					Represent 0				
							Count and Write 0, 1, and 2				
							Count and Write 3 and 4				
							Count and Write 5				
							Count and Write 6 and 7				
							Count and Write 8 and 9				
							Count and Write 10				
	2	K.CC.B.4.b: Understand that the last number name said tells the number of objects counted. The number of objects is the same regardless of their arrangement or the order in which they were counted.					Count and Write 0, 1, and 2				
							Count and Write 3 and 4				
							Count and Write 5				
							Count and Write 6 and 7				
							Count and Write 8 and 9				
							Count and Write 10				
	1	K.CC.B.5: Count to answer "how many?" questions about as many as 20 things arranged in a line, a rectangular array, or a circle, or as many as 10 things in a scattered configuration; given a number from 1-20, count out that many objects.					Represent 0				
	1	K.CC.C.6: Identify whether the number of objects in one group is greater than, less than, or equal to the number of objects in another group, e.g., by using matching and counting strategies.					Greater Than				
							Less Than				
							Same Number				`;
