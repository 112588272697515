import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { BackIcon } from "./BackIcon";
import { LightColors } from "../../../../light-theme";

const Link = styled.div({ color: LightColors.blue, fontWeight: "bold" });
const BackLinkContainer = styled.div({
  display: "flex",
  gap: 10,
  margin: 5,
  cursor: "pointer",
});
export const BackLink = ({ destination, label, onClick = undefined }) => {
  const history = useHistory();
  const back = useCallback(
    () => {
      if (onClick) {
        onClick();
      } else {
      history.push(destination);
      }
    },
    [onClick, history, destination]
  );
  return (
    <BackLinkContainer onClick={back}>
      <BackIcon />
      <Link>Back to {label}</Link>
    </BackLinkContainer>
  );
};
