export const StandardsReportIcon = () => (
  <svg
    width="31"
    height="37"
    viewBox="0 0 31 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67945 31.9703L6.97156 31.1805L9.73266 35.7699C9.79058 35.8653 9.87311 35.9434 9.97158 35.9959C10.0701 36.0484 10.1808 36.0735 10.2923 36.0684C10.4053 36.0635 10.5148 36.0279 10.609 35.9655C10.7032 35.903 10.7786 35.8161 10.8271 35.714L14.7885 27.3063C11.0493 27.0007 7.58145 25.2332 5.13705 22.3873L1.02649 31.0935C0.98105 31.1934 0.962867 31.3036 0.97379 31.4128C0.984712 31.522 1.02435 31.6264 1.08868 31.7153C1.15454 31.8077 1.2444 31.8802 1.34853 31.9252C1.45266 31.9701 1.56709 31.9857 1.67945 31.9703Z"
      fill="#449DAC"
    />
    <path
      d="M17.1628 27.3063L21.1304 35.714C21.1784 35.8152 21.2528 35.9015 21.3459 35.9639C21.4389 36.0263 21.5471 36.0624 21.659 36.0684C21.7663 36.069 21.8719 36.0419 21.9657 35.9896C22.0594 35.9373 22.138 35.8616 22.1938 35.7699L24.9549 31.1805L30.247 31.9703H30.3465C30.4614 31.9706 30.5742 31.939 30.6723 31.8791C30.7704 31.8191 30.85 31.7332 30.9022 31.6307C30.9544 31.5283 30.9771 31.4134 30.968 31.2988C30.9588 31.1842 30.918 31.0744 30.8502 30.9815L26.8205 22.3873C24.3746 25.2347 20.9042 27.0023 17.1628 27.3063Z"
      fill="#449DAC"
    />
    <path
      d="M15.9755 26.1185C18.5584 26.1185 21.0833 25.3526 23.2308 23.9176C25.3784 22.4827 27.0523 20.4431 28.0407 18.0568C29.0291 15.6705 29.2877 13.0448 28.7838 10.5115C28.2799 7.97828 27.0362 5.65134 25.2098 3.82497C23.3834 1.9986 21.0565 0.75483 18.5232 0.250936C15.99 -0.252958 13.3642 0.00565832 10.978 0.994082C8.59168 1.98251 6.55211 3.65634 5.11714 5.80393C3.68217 7.95151 2.91626 10.4764 2.91626 13.0593C2.91626 16.5228 4.29214 19.8445 6.74123 22.2935C9.19031 24.7426 12.512 26.1185 15.9755 26.1185ZM9.13495 11.4051C9.17146 11.2937 9.2387 11.1949 9.32892 11.1201C9.41914 11.0453 9.52867 10.9975 9.64488 10.9822L13.6186 10.4412L15.4531 6.84057C15.5053 6.73706 15.5853 6.6502 15.6842 6.58976C15.7831 6.52933 15.8969 6.49773 16.0128 6.49854C16.1294 6.4983 16.2436 6.53083 16.3426 6.5924C16.4416 6.65398 16.5212 6.74212 16.5725 6.84678L18.32 10.4598L22.2875 11.0817C22.4021 11.0994 22.5096 11.1488 22.5976 11.2243C22.6857 11.2998 22.7509 11.3984 22.7858 11.5091C22.8207 11.6197 22.824 11.7379 22.7953 11.8503C22.7666 11.9627 22.707 12.0648 22.6233 12.1451L19.7316 14.9249L20.3535 18.8862C20.3757 19.0028 20.364 19.1233 20.32 19.2335C20.2759 19.3436 20.2012 19.4389 20.1047 19.508C19.9973 19.5831 19.8689 19.6223 19.7378 19.62C19.6363 19.6214 19.5359 19.5979 19.4455 19.5516L15.9071 17.6549L12.3376 19.5205C12.2387 19.5677 12.1292 19.588 12.02 19.5792C11.9107 19.5705 11.8058 19.5331 11.7157 19.4707C11.6204 19.4008 11.5469 19.3052 11.5039 19.1951C11.461 19.0849 11.4503 18.9648 11.4732 18.8489L12.1572 14.8689L9.30285 12.0456C9.22272 11.9621 9.16746 11.858 9.14329 11.7448C9.11912 11.6317 9.127 11.514 9.16604 11.4051H9.13495Z"
      fill="#449DAC"
    />
  </svg>
);
