export default `K - ELA		K - Math	
Lowercase a and its sound	RF.K.3.B, RF.K.1.D	Represent 0	K.CC.B.4.a, K.CC.B.5, K.CC.A.3
Lowercase b and its sound	RF.K.3.B, RF.K.1.D	Count and Write 0, 1, and 2	K.CC.B.4.b, K.CC.B.4.a, K.CC.A.3
Lowercase c and its sound	RF.K.3.B, RF.K.1.D	Count and Write 3 and 4	K.CC.B.4.b, K.CC.B.4.a, K.CC.A.3
Lowercase d and its sound	RF.K.3.B, RF.K.1.D	Count and Write 5	K.CC.B.4.b, K.CC.B.4.a, K.CC.A.3
Lowercase e and its sound	RF.K.3.B, RF.K.1.D	Greater Than	K.CC.C.6
Lowercase f and its sound	RF.K.3.B, RF.K.1.D	Less Than	K.CC.C.6
Lowercase g and its sound	RF.K.3.B, RF.K.1.D	Same Number	K.CC.C.6
Lowercase h and its sound	RF.K.3.B, RF.K.1.D	Count and Write 6 and 7	K.CC.B.4.b, K.CC.B.4.a, K.CC.A.3
Lowercase i and its sound	RF.K.3.B, RF.K.1.D	Count and Write 8 and 9	K.CC.B.4.b, K.CC.B.4.a, K.CC.A.3
Lowercase j and its sound	RF.K.3.B, RF.K.1.D	Count and Write 10	K.CC.B.4.b, K.CC.B.4.a, K.CC.A.3
Lowercase k and its sound	RF.K.3.B, RF.K.1.D		
Lowercase l and its sound	RF.K.3.B, RF.K.1.D		
Lowercase m and its sound	RF.K.3.B, RF.K.1.D		
Lowercase n and its sound	RF.K.3.B, RF.K.1.D		
Lowercase o and its sound	RF.K.3.B, RF.K.1.D		
Lowercase p and its sound	RF.K.3.B, RF.K.1.D		
Lowercase q and its sound	RF.K.3.B, RF.K.1.D		
Lowercase r and its sound	RF.K.3.B, RF.K.1.D		
Lowercase s and its sound	RF.K.3.B, RF.K.1.D		
Lowercase t and its sound	RF.K.3.B, RF.K.1.D		
Lowercase u and its sound	RF.K.3.B, RF.K.1.D		
Lowercase v and its sound	RF.K.3.B, RF.K.1.D		
Lowercase w and its sound	RF.K.3.B, RF.K.1.D		
Lowercase x and its sound	RF.K.3.B, RF.K.1.D		
Lowercase y and its sound	RF.K.3.B, RF.K.1.D		
Lowercase z and its sound	RF.K.3.B, RF.K.1.D		
Uppercase A and its sound	RF.K.3.B, RF.K.1.D		
Uppercase B and its sound	RF.K.3.B, RF.K.1.D		
Uppercase C and its sound	RF.K.3.B, RF.K.1.D		
Uppercase D and its sound	RF.K.3.B, RF.K.1.D		
Uppercase E and its sound	RF.K.3.B, RF.K.1.D		
Uppercase F and its sound	RF.K.3.B, RF.K.1.D		
Uppercase G and its sound	RF.K.3.B, RF.K.1.D		
Uppercase H and its sound	RF.K.3.B, RF.K.1.D		
Uppercase I and its sound	RF.K.3.B, RF.K.1.D		
Uppercase J and its sound	RF.K.3.B, RF.K.1.D		
Uppercase K and its sound	RF.K.3.B, RF.K.1.D		
Uppercase L and its sound	RF.K.3.B, RF.K.1.D		
Uppercase M and its sound	RF.K.3.B, RF.K.1.D		
Uppercase N and its sound	RF.K.3.B, RF.K.1.D		
Uppercase O and its sound	RF.K.3.B, RF.K.1.D		
Uppercase P and its sound	RF.K.3.B, RF.K.1.D		
Uppercase Q and its sound	RF.K.3.B, RF.K.1.D		
Uppercase R and its sound	RF.K.3.B, RF.K.1.D		
Uppercase S and its sound	RF.K.3.B, RF.K.1.D		
Uppercase T and its sound	RF.K.3.B, RF.K.1.D		
Uppercase U and its sound	RF.K.3.B, RF.K.1.D		
Uppercase V and its sound	RF.K.3.B, RF.K.1.D		
Uppercase W and its sound	RF.K.3.B, RF.K.1.D		
Uppercase X and its sound	RF.K.3.B, RF.K.1.D		
Uppercase Y and its sound	RF.K.3.B, RF.K.1.D		
Uppercase Z and its sound	RF.K.3.B, RF.K.1.D		
Digraphs: initial sh and ch	RF.K.3		
Digraphs: initial th and wh	RF.K.3		
Identify/Produce Rhyme	RF.K.2.A		
Identify Initial Sounds	RF.K.2.D		`;
