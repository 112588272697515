import React from "react";
import styled from "@emotion/styled";

import data from "../../../../../data/student-writing.json";

import {
  Cell,
  Header,
  HeaderRow,
  Row,
  Table,
} from "../../../../../components/ReportTables";
import { LightColors } from "../../../../../light-theme";

const WritingContainer = styled.div({ padding: "20px 40px" });
const Title = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  marginTop: 5,
  marginBottom: 10,
});
const LinkCell = styled(Cell)({
  textDecoration: "underline",
  color: LightColors.blue,
  fontWeight: "bold",
});

const scores = [
  [2, 0, 2],
  [0, 2, 2],
  [0, 2, 2],
  [0, 8, 8],
  [0, 8, 8],
  [3, 3, 6],
];

export const Writing = ({ grade }) => {
  const writings = data[grade] || data["3"];
  return (
    <WritingContainer>
      <Title>Student Writing</Title>
      <Table>
        <thead>
          <HeaderRow>
            <Header>Passage Name</Header>
            <Header># Responses to Grade</Header>
            <Header>Graded</Header>
            <Header>Submitted</Header>
          </HeaderRow>
        </thead>
        <tbody>
          {writings.map((title, index) => (
            <Row>
              <LinkCell>{title}</LinkCell>
              <Cell>{scores[index][0]}</Cell>
              <LinkCell>{scores[index][1]}</LinkCell>
              <LinkCell>{scores[index][2]}</LinkCell>
            </Row>
          ))}
        </tbody>
      </Table>
    </WritingContainer>
  );
};
