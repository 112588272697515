import React, { useState, useRef } from "react";
import css from "./Growth.module.css";

import Subject from "../../TopNav/Subject";
import WaggleGrowth from "./WaggleGrowth";
import GrowthMeasureReport from "./GrowthMeasure";

const Growth = (props) => {
  const useParams = useRef(true);

  let params = props.params == "hmh" || useParams.current ? props.params : null;
  useParams.current = false;

  const contents =
    params != "hmh" ? (
      <WaggleGrowth {...props} showReport={params == "report"} />
    ) : (
      <GrowthMeasureReport {...props} />
    );

  return <div className={css.root}>{contents}</div>;
};

export default Growth;
