const text = `					
					
Standard	Proficiency Correct 	Proficiency Total	Practice Proficiency	Mindset	Time on (total minutes)
3.OA.3	1	5	20	Avg.	120
3.OA.6	1	5	40	Avg.	125
3.OA.5	1	5	60	Avg.	182
3.OA.7	1	5	80	Avg.	40
3.OA.2	1	5	100	Avg.	40
3.NBT.1	1	5	20	Avg.	40`;

const parse = (rawText) => {
  const output = [];
  const lines = rawText.split("\n");

  for (var i = 4; i < lines.length; i++) {
    const line = lines[i];
    const tokens = line.split("\t");

    const row = {};
    row.name = tokens[0];
    row.correct = tokens[1];
    row.total = tokens[2];
    row.proficiency = tokens[3];
    row.mindset = tokens[4];
    row.time = tokens[5];

    output.push(row);
  }

  return output;
};

const data = parse(text);
export default data;
