import React, { useEffect, createRef } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import { store, utils } from "@indieasy.software/indieasy-engine";
import { components } from "@indieasy.software/indieasy-components";

import css from "./App.module.css";
//import tooltipCss from "../../skins/tooltip/tooltip.module.css";
import tooltipDisabledCss from "../../tooltip.module.css";
import TutorialTip from "../../../../common/components/TutorialTip";

import Outter from "./Outter";

const { ToolTip } = components;

const App = (props) => {
  const wrapperRef = createRef();

  /*

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://kit.fontawesome.com/a076d05399.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  */

  const setScrollHandler = (value) => {
    wrapperRef.current.style.overflowY = value ? "auto" : "hidden";
  };

  const scrollTo = (value) => {
    wrapperRef.current.scrollTo(value);
  };

  return (
    <React.Fragment>
      <div ref={wrapperRef} className={css.wrapper}>
        <Provider store={store.getStore()}>
          <BrowserRouter basename={utils.url.getBaseURL()}>
            <div className={css.subwrapper}></div>
            <div className={css.app}>
              <Outter />
              <ToolTip css={tooltipDisabledCss} />
              <ToolTip css={tooltipDisabledCss} managerId="disabled" />
              <TutorialTip
                headerColor={"#2191CF"}
                toggleStyle={{ top: "80px", right: "2px", minWidth: "110px" }}
              />
            </div>
          </BrowserRouter>
        </Provider>
      </div>
      <div id="popup-container" />
    </React.Fragment>
  );
};

export default App;
