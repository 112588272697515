import React from "react";
import Link from "../../../components/Link";
import css from "./Message.module.css";

const Message = (props) => {
  const closeHandler = () => {
    props.onClose();
  };

  return (
    <div className={css.root}>
      <div className={css.popup}>
        <div className={css.top}>
          <div className={css.title}>Send Message</div>
          <div onMouseDown={closeHandler} className={css.close} />
        </div>
        <div className={css.body}>
          <div className={css.title}>TO:</div>
          <div>
            <input
              className={css.to}
              type="text"
              placeholder="Enter student, class, or group name."
            />
          </div>
          <div>
            <textarea className={css.contents} type="text" multiline="true" />
          </div>
          <div className={css.buttons}>
            <div onMouseDown={closeHandler}>
              <Link bold underline={false} size="14px">
                Cancel
              </Link>
            </div>
            <div className={css.send}>Send</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
