import * as React from "react"

export function TrashIcon(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_12593_3791)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.667 0a.833.833 0 00-.834.833v2.5h-5A.833.833 0 000 4.167V7.5c0 .46.373.833.833.833H2.5v10.834c0 .46.373.833.833.833h13.334c.46 0 .833-.373.833-.833V8.333h1.667c.46 0 .833-.373.833-.833V4.167a.833.833 0 00-.833-.834h-5v-2.5A.833.833 0 0013.333 0H6.667zM12.5 3.333V1.667h-5v1.666h5zm3.333 5v10H4.167v-10h11.666zM17.5 6.667h.833V5H1.667v1.667H17.5zm-6.667 4.166v5H9.167v-5h1.666zm-3.333 5v-5H5.833v5H7.5zm6.667-5v5H12.5v-5h1.667z"
                    fill="#1261BF"
                />
            </g>
            <defs>
                <clipPath id="clip0_12593_3791">
                    <path fill="#fff" d="M0 0H20V20H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TrashIcon
