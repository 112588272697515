import React from "react";

import { useHistory, useParams } from "react-router-dom";
import { Container, Title } from "../CommonFindContent";
import styled from "@emotion/styled";
import { ToggleIcon } from "../by-strand/ToggleIcon";
import { gradeToIndex } from "../../../../routing-util";
import { NoContent } from "../NoContent";
import { LightColors } from "../../../../light-theme";
import { unitDataByType } from "./unit-data";

export const UnitBlock = styled.div({
  label: "UnitBlock",
  border: `1px solid ${LightColors.grey1}`,
  backgroundColor: LightColors.white,
  margin: 18,
  borderRadius: 8,
  display: "flex",
  padding: 18,
  alignItems: "center",
  cursor: "pointer",
});

const UnitName = styled.div({
  label: "UnitName",
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "19px",
  letterSpacing: 0.4000000059604645,
  textAlign: "left",
  flexGrow: 1,
  color: LightColors.black,
});

const Unit = ({ unit, onClick }) => {
  return (
    <UnitBlock onClick={onClick}>
      <UnitName dangerouslySetInnerHTML={{ __html: unit.name || "Unit 1" }} />
      {/* /* TODO - not sure why the unit.name doesn't come through all the time */}
      <ToggleIcon open={false} />
    </UnitBlock>
  );
};

export const UnitIndex = ({ classGrade, grade, label }) => {
  const { unitType, subject } = useParams();

  const data = unitDataByType(unitType);
  const units = data[subject][gradeToIndex(grade)][unitType];
  const history = useHistory();

  const openUnit = (index) => (event) => {
    history.push(
      `/findcontent/${subject}/${classGrade}/${grade}/${unitType}/${index}`
    );
  };

  if (units.length === 0) return <NoContent />;

  return (
    <Container>
      <Title>Select a Unit</Title>

      {units.map((u, index) => (
        <Unit key={index} unit={u} onClick={openUnit(index)} />
      ))}
    </Container>
  );
};
