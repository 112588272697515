import styled from "@emotion/styled";
import { useToggle } from "../../../../../common/util/useToggle";
import { keyframes } from "@emotion/react";
import { useRef } from "react";

export const HomeTooltip = ({ children, tooltip }) => {
  const [isHovered, , showTooltip, hideTooltip] = useToggle();
  const timer = useRef(null);

  const onHover = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      showTooltip();
    }, 300);
  };

  const onLeave = () => {
    clearTimeout(timer.current);
    hideTooltip();
  };

  return (
    <Container onMouseEnter={onHover} onMouseLeave={onLeave}>
      {isHovered && (
        <ToolTip>
          {tooltip}
          <Triangle />
        </ToolTip>
      )}
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const fadeInSlideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
`;

const ToolTip = styled.div`
  bottom: 140px;
  background: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #dddede;
  animation: ${fadeInSlideUp} 0.3s ease-in-out;
  position: absolute;
  width: 240px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
