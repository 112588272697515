import React, { useState } from "react";
import Link from "../../../../components/Link";
import css from "./Add.module.css";

const Add = (props) => {
  const [over, setIsOver] = useState(false);

  const mouseEnterHandler = (ev) => {
    setIsOver(true);
    if (props.onMouseEnter) props.onMouseEnter(ev);
  };

  const mouseLeaveHandler = (ev) => {
    setIsOver(false);
    if (props.onMouseLeave) props.onMouseLeave();
  };

  return (
    <div
      className={css.root}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onClick={props.onClick}
    >
      <div className={css.plus + (over ? " " + css.over : "")}>+</div>
      <Link over={over} underline={false}>
        {props.children}
      </Link>
    </div>
  );
};

export default Add; //
