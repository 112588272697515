const text = `									
MATH G1	SKILL	ASSIGNMENT	DUE DATE	STATUS	PROFICIENCY	MINDSET	TIME SPENT	PRIORITY	TYPE
	Determine the unknown number in a multiplication equation								
		Represent Multiplication	10/29/21	Completed	83	Avg	34		goal
		Understand Multiplication	10/29/21	Completed	4/5		6		skill


MATH G3	SKILL	ASSIGNMENT	DUE DATE	STATUS	PROFICIENCY	MINDSET	TIME SPENT	PRIORITY	TYPE
	Determine the unknown number in a multiplication equation								
		Represent Multiplication	10/29/21	Completed	83	Avg	34		goal
		Understand Multiplication	10/29/21	Completed	4/5		6		skill
	Use the symbols <, >, and = to compare two fractions with the same denominator								
		Compare Fractions	10/29/21	In Progress	73	Avg	28		goal
	Measure and Estimate Capacity in Liters								
		Capacity	10/29/21	Completed	5/5		9		lesson
	Fluently Divide within 100								
		Understand Division	10/29/21	Not Started	0		0		lesson
									
									
									
MATH G7	SKILL	ASSIGNMENT	DUE DATE	STATUS	PROFICIENCY	MINDSET	TIME SPENT	PRIORITY	TYPE
	Analyze representations of proportional relationshps to determine the constant ...								
		Constant of Proportionality	10/29/21	Completed	5/5		5		skill
	Compute unit rates associated with ratios of fractions in like or different units								
		Solve Problems with Rational Numbers	10/29/21	In Progress	56	High	26		goal
	Add and Subtract Rational Numbers								
		Adding and Subtracting Rational Numbers	10/29/21	Not Started	0		0		lesson
	Understand multiplication of Integers								
		Multiplying Integers	10/29/21	Completed	4/5		9		lesson
	Identify additive inverses								
		Identify additive inverses	10/29/21	Completed	3/5		7		skill



ELA G1	SKILL	ASSIGNMENT	DUE DATE	STATUS	PROFICIENCY	MINDSET	TIME SPENT	PRIORITY	TYPE
	Identify Words in Sentences								
		Identify Words in Sentences	10/29/21	Completed	4/5	Avg	8		skill
	Isolate Initial Sounds								
		Isolate Initial Sounds	10/29/21	Completed	92	High	48		goal
	
	


ELA G3	SKILL	ASSIGNMENT	DUE DATE	STATUS	PROFICIENCY	MINDSET	TIME SPENT	PRIORITY	TYPE
	Describe Characters								
		Describe Characters	10/29/21	Completed	4/5	Avg	8		skill
	Retell stories								
		Literary Elements	10/29/21	Completed	92	High	48		goal
	Describe plot								
		Describe Plot	10/29/21	Completed	5/5		22		lesson
	Determine Main Idea								
		Informational Text Elements	10/29/21	In Progress	53	High	28		goal
	Determing the Meaning of Unfamiliar Words								
		Context Clues	10/29/21	In Progress	0		6		game
	Analyze Word Choice								
		Analyze Word Choice	10/29/21	Completed	3/5		24		lesson		


ELA G3	SKILL	ASSIGNMENT	DUE DATE	STATUS	PROFICIENCY	MINDSET	TIME SPENT	PRIORITY	TYPE
	Describe Characters								
		Describe Characters	10/29/21	Completed	4/5	Avg	8		skill
	Retell stories								
		Literary Elements	10/29/21	Completed	92	High	48		goal
	Describe plot								
		Describe Plot	10/29/21	Completed	5/5		22		lesson
	Determine Main Idea								
		Informational Text Elements	10/29/21	In Progress	53	High	28		goal
	Determing the Meaning of Unfamiliar Words								
		Context Clues	10/29/21	In Progress	0		6		game
	Analyze Word Choice								
		Analyze Word Choice	10/29/21	Completed	3/5		24		lesson
									
ELA G7	SKILL	ASSIGNMENT	DUE DATE	STATUS	PROFICIENCY	MINDSET	TIME SPENT	PRIORITY	TYPE
	Describe Plot								
		Desribe Plot	10/29/21	Completed	4/5	Avg	8		skill
	Make Inferences								
		Literary Analysis	10/29/21	Completed	92	High	48		goal
	Determine Connotative Meanings								
		Determine Connotative Meanings	10/29/21	Completed	5/5		22		lesson
	Describe the Theme								
		Describe the Theme	10/29/21	Completed	5/5		22		lesson
	Determine Meaning of Words and Phrases								
		Determine Meaning of Words and Phrases	10/29/21	Completed	3/5		5		skill
	Use Root Words								
		Word Parts	10/29/21	In Progress	0		6		game`;

const parseGroup = (lines, startCol, endCol) => {
  const output = [];

  let currentSkill;

  for (var i = 1; i < lines.length; i++) {
    const line = lines[i];
    const tokens = line.split("\t");

    if (tokens[1]) {
      currentSkill = {
        name: tokens[1],
        assignments: [],
      };
      output.push(currentSkill);
    } else if (tokens[2]) {
      currentSkill.assignments.push({
        name: tokens[2],
        dueDate: tokens[3],
        status: tokens[4],
        proficiency: tokens[5],
        mindset: tokens[6],
        timeSpent: tokens[7],
        priority: tokens[8],
        type: tokens[9],
      });
    }
  }

  return output;
};

const parse = (rawText) => {
  const lines = rawText.split("\n");

  var lineMathG1 = lines.findIndex((x) => x.startsWith("MATH G1"));
  var lineMathG3 = lines.findIndex((x) => x.startsWith("MATH G3"));
  var lineMathG7 = lines.findIndex((x) => x.startsWith("MATH G7"));
  var lineELAG1 = lines.findIndex((x) => x.startsWith("ELA G1"));
  var lineELAG3 = lines.findIndex((x) => x.startsWith("ELA G3"));
  var lineELAG7 = lines.findIndex((x) => x.startsWith("ELA G7"));

  const output = {
    math: {
      1: parseGroup(lines.slice(lineMathG1, lineMathG3), 0, 9),
      3: parseGroup(lines.slice(lineMathG3, lineMathG7), 0, 9),
      7: parseGroup(lines.slice(lineMathG7, lineELAG3), 0, 9),
    },
    ela: {
      1: parseGroup(lines.slice(lineELAG1, lineELAG3), 0, 9),
      3: parseGroup(lines.slice(lineELAG3, lineELAG7), 0, 9),
      7: parseGroup(lines.slice(lineELAG7, lines.length), 0, 9),
    },
  };

  return output;
};

const data = parse(text);

export default data;
