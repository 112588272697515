import React from "react";
import css from "./Writing.module.css";

import Button from "../../Button";

import data from "../../../../../data/studentInsightWriting.json";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const Writing = (props) => {
  const studentData = data[props.subject][props.grade];

  const entries = studentData.map((x, index) => (
    <div key={index} className={css.row}>
      <div className={css.text}>{x.goal}</div>
      <div className={css.text}>{x.passage}</div>
    </div>
  ));

  return (
    <div className={css.root}>
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "skillstatus_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>
      </div>
      <div className={css.row + " " + css.header}>
        <div className={css.text}>GOAL</div>
        <div className={css.text}>PASSAGE</div>
      </div>
      {entries}
    </div>
  );
};

export default Writing;
