import React from "react";
import css from "./Checkbox.module.css";

const Checkbox = (props) => {
  const changeHandler = () => {
    props.onChange(props);
  };

  return (
    <input
      checked={props.checked}
      type="checkbox"
      className={css.root}
      onChange={changeHandler}
    ></input>
  );
};

export default Checkbox;
