import data from "./findcontent.json";

const domainOrStrand = (d) => d.domain || d.strand;

//export const findStandardByName = name =>

const findBy = (type, field) => (subject, value) =>
  data[subject]
    .map(domainOrStrand)
    .flat()
    .map((strand) => strand[type])
    .flat()
    .find((standard) => standard[field] == value);

export const findStandardByCategory = findBy("standard", "category");
export const findStandardByName = findBy("standard", "name");
export const findSkillByName = findBy("skill", "name");
