import React, { useRef } from "react";
import css from "./DropDown.module.css";
import cssGrowth from "./DropDownGrowth.module.css";
import cssMindset from "./DropDownMindset.module.css";
import cssDomain from "./DropDownDomain.module.css";
import cssContent from "./DropDownContent.module.css";

import { components } from "@indieasy.software/indieasy-components";
import { toolTips } from "@indieasy.software/indieasy-engine";
import { renderIntoDocument } from "react-dom/test-utils";

const { DropDown } = components;

const DropDownWrapper = (props) => {
  const toggleOpenedRef = useRef(false);

  const dropdownEnterHandler = (ev) => {
    if (!props.rolloverTip) return;
    if (toggleOpenedRef.current) return;

    const rect = ev.currentTarget.getBoundingClientRect();
    toolTips.system.getManager("disabled").show(
      toolTips.createToolTip(
        rect.left + rect.width / 2 - 25,
        rect.top + rect.height,
        {
          style: { width: "200px" },
          title: props.rolloverTipTitle,
          text: props.rolloverTip,
        }
      )
    );
  };

  const dropdownLeaveHandler = (ev) => {
    toolTips.system.getManager("disabled").hide();
  };

  const onOpen = () => {
    if (props.onOpen) props.onOpen();
    toggleOpenedRef.current = true;
  };

  const onClose = () => {
    if (props.onClose) props.onClose();
    toggleOpenedRef.current = false;
  };
  const selectIndexHandler = (index) => {
    if (props.selectIndex) props.selectIndex(index);
  };

  let finalCss;

  if (props.css) finalCss = props.css;
  else if (props.growth) finalCss = cssGrowth;
  else if (props.mindset) finalCss = cssMindset;
  else if (props.domain) finalCss = cssDomain;
  else if (props.content) finalCss = cssContent;
  else finalCss = css;

  const text =
    props.text ||
    (props.defaultText && props.selectedIndex == -1 ? props.defaultText : null);

  return (
    <div
      style={props.style}
      className={css.root}
      onMouseEnter={dropdownEnterHandler}
      onMouseLeave={dropdownLeaveHandler}
      onMouseUp={dropdownLeaveHandler}
    >
      <DropDown
        width={props.width}
        data={props.data}
        css={finalCss}
        popupCss={finalCss}
        arrow={
          <img
            style={{ width: "27px", marginRight: "-10px", marginTop: "3px" }}
            src="https://practice.gogetwaggle.com/teacher/assets/images/down-arrow-black.png?v=3.0"
          />
        }
        selectedIndex={props.selectedIndex}
        onSelectIndex={selectIndexHandler}
        onOpen={onOpen}
        onClose={onClose}
        text={text}
      />
    </div>
  );
};

export default DropDownWrapper;
