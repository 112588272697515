import React from "react";
import css from "./Skills.module.css";

import Button from "../../Button";
import Box from "../../../Home/Box";

import data from "../../../../../data/studentInsightSkills";
import Link from "../../../../../components/Link";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";
import { Fragment } from "react";

const Skills = (props) => {
  const colors = ["#282828", "#7AB946", "#F89D02", "#1886C7", "#D92128"];

  const names = ["Advanced", "Proficient", "Basic", "Partial", "Minimal"];

  const getThreshold = (value) => {
    if (value >= 90) return 0;
    else if (value >= 70) return 1;
    else if (value >= 55) return 2;
    else if (value >= 35) return 3;
    else return 4;
  };

  const getTime = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    if (hours == 0) return minutes + "min";
    else return hours + "hr " + minutes + "min";
  };

  console.log("test");

  const types = {
    skill:
      "https://practice.gogetwaggle.com/teacher/assets/images/skill-boost-gray.svg?v=3.0",
    goal: "https://practice.gogetwaggle.com/teacher/assets/images/learning-goal-gray.svg?v=3.0",
    lesson:
      "https://practice.gogetwaggle.com/teacher/assets/images/lesson-gray.svg?v=3.0",
    game: "https://practice.gogetwaggle.com/teacher/assets/images/game-gray.svg?v=3.0",
  };

  const rows = data[props.subject][props.grade].map((skill, index) => {
    var entries = skill.assignments.map((assignment, index2) => {
      return (
        <div key={index + "_" + index2} className={css.student}>
          <Box
            style={{
              color: "#616161",
              paddingLeft: "20px",
              boxSizing: "border-box",
              borderBottom: "1px solid rgb(122, 192, 209)",
              textAlign: "left",
              fontWeight: "bold",
              lineHeight: "18px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            borderRight
            width="300px"
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div style={{ width: "18px", marginRight: "10px" }}>
                <img src={types[assignment.type]} width="18px" />
              </div>
              <div style={{ verticalAlign: "middle", lineHeight: "18px" }}>
                {assignment.name}
              </div>
            </div>
          </Box>
          <Box
            style={{
              color: "#616161",
              boxSizing: "border-box",
              borderBottom: "1px solid rgb(122, 192, 209)",
            }}
            borderRight
            width="80px"
          >
            {assignment.dueDate}
          </Box>
          <Box
            style={{
              color: "#616161",
              boxSizing: "border-box",
              borderBottom: "1px solid rgb(122, 192, 209)",
            }}
            borderRight
            width="80px"
          >
            {assignment.status}
          </Box>
          <Box
            style={{
              color: "#616161",
              boxSizing: "border-box",
              borderBottom: "1px solid rgb(122, 192, 209)",
            }}
            borderRight
            width="100px"
          >
            {assignment.type == "goal" ? (
              <Fragment>
                <span
                  style={{
                    fontWeight: "bold",
                    color: colors[getThreshold(assignment.proficiency)],
                  }}
                >{`${names[getThreshold(assignment.proficiency)]} (${
                  assignment.proficiency
                })`}</span>
              </Fragment>
            ) : assignment.proficiency != "0" ? (
              <Fragment>
                <span style={{ fontWeight: "bold" }}>{`${
                  assignment.proficiency
                } (${
                  (assignment.proficiency.split("/")[0] /
                    assignment.proficiency.split("/")[1]) *
                  100
                })`}</span>
              </Fragment>
            ) : (
              <Fragment>
                <span
                  style={{ fontWeight: "bold" }}
                >{`${assignment.proficiency}`}</span>
              </Fragment>
            )}
          </Box>
          <Box
            style={{
              color: "#616161",
              boxSizing: "border-box",
              borderBottom: "1px solid rgb(122, 192, 209)",
            }}
            borderRight
            width="60px"
          >
            {assignment.mindset}
          </Box>
          <Box
            style={{
              color: "#616161",
              boxSizing: "border-box",
              borderBottom: "1px solid rgb(122, 192, 209)",
            }}
            borderRight
            width="80px"
          >
            {getTime(assignment.timeSpent)}
          </Box>
        </div>
      );
    });

    var time = 0;
    skill.assignments.forEach(
      (assignment) => (time += parseFloat(assignment.timeSpent))
    );

    return (
      <div key={index}>
        <div className={css.row}>
          <Box
            style={{
              color: "#282828",
              paddingLeft: "20px",
              height: "40px",
              lineHeight: "40px",
              fontSize: "15px",
              borderBottom: "1px solid rgb(122, 192, 209)",
              fontWeight: "bold",
              textAlign: "left",
            }}
            width="590px"
            background="rgb(244, 244, 244)"
          >
            {skill.name}
          </Box>
          <Box
            style={{
              color: "#282828",
              height: "40px",
              lineHeight: "40px",
              fontSize: "15px",
              borderBottom: "1px solid rgb(122, 192, 209)",
              fontWeight: "bold",
              textAlign: "right",
            }}
            width="100px"
            background="rgb(244, 244, 244)"
          >
            <span style={{ marginRight: "30px" }}>{getTime(time)}</span>
          </Box>
        </div>
        {entries}
      </div>
    );
  });

  return (
    <div className={css.root}>
      <div className={css.top}>
        <div className={css.left}>
          <Link selected>Assigned</Link>
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>|</div>
          <Link underline={false}>Top Skill Gaps</Link>
        </div>
        <div className={css.right}>
          <Button
            width="115px"
            background="#309acf"
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "skillstatus_exportbutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            Export
          </Button>
          {/*<Button width="155px" background="#309acf" onMouseEnter={ev => rolloverTipBegin(ev, "skillstatus_changeselectionsbutton")} onMouseLeave={rolloverTipEnd}>Change Selections</Button>*/}
          <Button
            width="125px"
            background="#309acf"
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "skillstatus_findcontentbutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            Find Content
          </Button>
        </div>
      </div>
      <div className={css.row}>
        <Box
          style={{
            textAlign: "center",
            height: "20px",
            color: "#5A5A5A",
            fontSize: "13px",
            lineHeight: "20px",
            borderBottom: "1px solid #7AC0D1",
            background: "#EDF6F9",
            fontWeight: "bold",
          }}
          width="300px"
          background="#EDF6F9"
        >
          ASSIGNMENT
        </Box>
        <Box
          style={{
            textAlign: "center",
            height: "20px",
            color: "#5A5A5A",
            fontSize: "13px",
            lineHeight: "20px",
            borderBottom: "1px solid #7AC0D1",
            background: "#EDF6F9",
            fontWeight: "bold",
          }}
          width="80px"
          background="#EDF6F9"
        >
          DUE DATE
        </Box>
        <Box
          style={{
            textAlign: "center",
            height: "20px",
            color: "#5A5A5A",
            fontSize: "13px",
            lineHeight: "20px",
            borderBottom: "1px solid #7AC0D1",
            background: "#EDF6F9",
            fontWeight: "bold",
          }}
          width="80px"
          background="#EDF6F9"
        >
          STATUS
        </Box>
        <Box
          style={{
            textAlign: "center",
            height: "20px",
            color: "#5A5A5A",
            fontSize: "13px",
            lineHeight: "20px",
            borderBottom: "1px solid #7AC0D1",
            background: "#EDF6F9",
            fontWeight: "bold",
          }}
          width="100px"
          background="#EDF6F9"
        >
          PROFICIENCY
        </Box>
        <Box
          style={{
            textAlign: "center",
            height: "20px",
            color: "#5A5A5A",
            fontSize: "13px",
            lineHeight: "20px",
            borderBottom: "1px solid #7AC0D1",
            background: "#EDF6F9",
            fontWeight: "bold",
          }}
          width="60px"
          background="#EDF6F9"
        >
          MINDSET
        </Box>
        <Box
          style={{
            textAlign: "center",
            height: "20px",
            color: "#5A5A5A",
            fontSize: "13px",
            lineHeight: "20px",
            borderBottom: "1px solid #7AC0D1",
            background: "#EDF6F9",
            fontWeight: "bold",
          }}
          width="80px"
          background="#EDF6F9"
        >
          TIME SPENT
        </Box>
      </div>
      <div className={css.students}>{rows}</div>
    </div>
  );
};

export default Skills;
