import React from "react";
import css from "./ClassActivityPopup.module.css";

import Popup from "..";
import Summary from "./Summary";
import Standards from "./Standards";
import Students from "./Students";
import Skills from "./Skills";
import StudentPopup from "../StudentPopup";

const ClassActivityPopup = (props) => {
  const studentSelectedHandler = (index) => {
    if (props.onStudentSelected) props.onStudentSelected(index);
  };

  return (
    <StudentPopup
      {...props}
      index={props.index}
      hideGrowth={props.grade < 3}
      tabs={["SUMMARY", "SKILLS", "STANDARDS", "STUDENTS"]}
    >
      <Summary {...props} />
      <Skills {...props} />
      <Standards {...props} />
      <Students {...props} onStudentSelected={studentSelectedHandler} />
    </StudentPopup>
  );
};

export default ClassActivityPopup;
