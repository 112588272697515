import React from "react";
import css from "./GroupDialog.module.css";

export const StudentList = ({
  students,
  children,
  selectStudent,
  selectedStudent,
  selectedClass = css.leftArrow,
}) => (
  <div className={css.studentListHeader}>
    <h1>{children}</h1>
    <div className={css.studentList}>
      <ul>
        {students.map((student, idx) => (
          <li
            key={idx}
            onClick={() => selectStudent(student)}
            className={
              student === selectedStudent
                ? `${css.selected} ${selectedClass}`
                : ""
            }
          >
            {student.name}
          </li>
        ))}
      </ul>
    </div>
  </div>
);
