import React from "react";
import css from "./Detail.module.css";

const Detail = (props) => {
  return (
    <div className={css.root}>
      <div style={{ width: props.nameWidth }} className={css.name}>
        {props.name}
      </div>
      <div className={css.line}>.............................</div>
      <div style={{ width: props.valueWidth }} className={css.value}>
        {props.value}
      </div>
    </div>
  );
};

export default Detail;
