import React, { useState } from "react";
import Link from "../../../../../../components/Link";
import Button from "../../Button";
import css from "./Students.module.css";
import {
  Table,
  Header,
  HeaderRow,
  Row,
  Cell,
  YellowRow,
} from "../../../../../../components/ReportTables";
import DropDown from "../../../../../../components/DropDown";
import CheckBox from "../../../../../../components/CheckBox";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../../data/rollovertips";
import TrackAssignmentDropdown from "../../../TrackAssignmentSmallDropdown.module.css";
import styled from "@emotion/styled";
import { SmallBlueButton } from "../../../../../../components/BlueButton";

const profNames = ["Minimal", "Partial", "Proficient", "Blank", "Proficient", "Below-Level", "--"];
const statusNames = [
  "Not Started",
  "Needs Help",
  "Making Progress",
  "Completed",
];

const CheckboxHeader = styled(Header)({ borderRight: "none" });
const NameHeader = styled(Header)({ textAlign: "left", borderLeft: "none" });
const CheckboxCell = styled(Cell)({
  textAlign: "center",
  width: 50,
  borderRight: "none",
});
const NameCell = styled(Cell)({
  width: 300,
  borderLeft: "none",
  textAlign: "left",
  paddingTop: 8,
  paddingBottom: 8,
});

const StatCell = styled(Cell)({ paddingLeft: 20, paddingRight: 20 });

const StudentRow = ({ student, index, selected, selectEntryHandler }) => (
  <Row>
    <CheckboxCell>
      <input
        type="checkbox"
        checked={selected}
        onChange={() => selectEntryHandler(index)}
      />
    </CheckboxCell>
    <NameCell>{student.name}</NameCell>
    <StatCell>{profNames[student.proficiency]}</StatCell>
    <StatCell>{statusNames[student.status]}</StatCell>
    <StatCell>{student.gap}</StatCell>
  </Row>
);

const Title = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  marginTop: 5,
  marginBottom: 10,
});

const LeftOptions = styled.div({
  "> div": {
    display: "inline-block",
  },
});

const Students = (props) => {
  const [selected, setSelected] = useState([]);

  const selectAllHandler = () => {
    if (selected.length < props.data.length) {
      setSelected(props.data.map((x, index) => index));
    } else {
      setSelected([]);
    }
  };

  const selectEntryHandler = (index) => {
    let d = [...selected];

    if (d.find((x) => x === index) != null) d = d.filter((x) => x !== index);
    else d.push(index);
    setSelected(d);
  };

  return (
    <div className={css.root}>
      <Title>Skill Target Proficiency</Title>
      <div className={css.desc}>
        The scores reflect all student work on a skill throughout Waggle, not
        just this one assignment.
      </div>
      <div className={css.dropdowns}>
        <LeftOptions>
          {/* <SmallBlueButton>Send Message</SmallBlueButton> */}
          <DropDown
            width="155px"
            selectedIndex={0}
            css={TrackAssignmentDropdown}
            disabled={true}
            data={[
              { text: "Add Students To Group" },
              { text: "Create a new Group" },
              { text: "Add to Group #1" },
              { text: "Add to Group #2" },
            ]}
            mindset
          /*rolloverTipTitle="Choose Subject"
                      rolloverTip="Choose english or math."*/
          />
        </LeftOptions>
        <div className={css.right}>
          <DropDown
            css={TrackAssignmentDropdown}
            selectedIndex={0}
            data={[{ text: "Viewing All Students" }]}
            mindset
          /*rolloverTipTitle="Choose Subject"
                      rolloverTip="Choose english or math."*/
          />
        </div>
      </div>

      <Table>
        <thead>
          <HeaderRow>
            <CheckboxHeader>
              <input
                type="checkbox"
                checked={selected.length == props.data.length}
                onChange={selectAllHandler}
              />
            </CheckboxHeader>
            <NameHeader>STUDENT NAME</NameHeader>
            <Header>PROFICIENCY</Header>
            <Header>STATUS</Header>
            <Header>TOP SKILL GAP DETECTED</Header>
          </HeaderRow>
        </thead>
        <tbody>
          <YellowRow><td colSpan={5} /></YellowRow>
          {props.data.map((x, index) => (
            <StudentRow
              selectEntryHandler={selectEntryHandler}
              selected={selected.filter((y) => y == index).length > 0}
              student={x}
              index={index}
              key={index}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Students;
