import React from "react";
import Hexagon from "./Hexagon";
import styled from "@emotion/styled";

const Root = styled.div({
  height: 45,
});

const Icon = styled.div({
  width: 120,
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: 5,
  position: "relative",
  top: -4,
});

const Contents = styled.div({
  position: "absolute",
  top: "-1px",
  width: "100%",
  paddingTop: 2,
});

const Text = styled.div({
  color: "white",
  fontWeight: "bold",
  fontSize: 17,
  textAlign: "center",
});

const Student = styled.div({
  fontSize: 11,
  textAlign: "center",
  color: "white",
});

const Trend = (props) => {
  const colors = ["#2F8718", "#DE6502", "#C92222"];

  return (
    <Root>
      <Icon>
        <Hexagon
          up={props.up}
          down={props.down}
          width="120"
          height="40"
          color={colors[props.row]}
        >
          <Contents>
            <Text>{props.value}</Text>
            <Student>{`STUDENT${props.value > 1 ? "S" : ""}`}</Student>
          </Contents>
        </Hexagon>
      </Icon>
    </Root>
  );
};

export default Trend;
