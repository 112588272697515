import React from "react";
import styled from "@emotion/styled";
import { StrandDetails } from "./StrandDetail";

const Name = styled.div({
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 700,

  letterSpacing: 0.4000000059604645,
  textAlign: "left",
});
const SkillName = ({ detail }) => (
  <Name>
    {detail.name} - {detail.category}
    {/* {detail.star && <PriorityStar />} */}
  </Name>
);

export const StrandSkills = ({
  classGrade,
  grade,
  label,
  groups,
  languageIndex,
}) => {
  return (
    <StrandDetails
      groups={groups}
      strandType="skill"
      classGrade={classGrade}
      languageIndex={languageIndex}
      grade={grade}
      label={label}
      mapToDetail={(strand) => strand.skill}
      renderDetailName={SkillName}
      yellowBar={false}
    />
  );
};
