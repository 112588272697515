import React from "react";
import styled from "@emotion/styled";
import { LightColors } from "../../../light-theme";

const TabPill = styled.div(({ selected }) => ({
  backgroundColor: selected ? LightColors.yellow : LightColors.white,
  color: LightColors.black,
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 5,
  paddingBottom: 5,
  borderRadius: 16,
  margin: 5,
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,
  cursor: "pointer",
  textAlign: "center",
}));

const Tab = (props) => {
  return (
    <TabPill
      selected={props.selected}
      onMouseDown={() => props.onSelect && props.onSelect(props.id)}
    >
      {props.children}
    </TabPill>
  );
};

export default Tab;
