import React from "react";
import Link from "../../../../../../components/Link";
import Button from "../../Button";
import css from "./Skills.module.css";
import PriorityStar from "../../../../FindContent/PriorityStar";
import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";
import {
  BlueButton,
  SmallBlueButton,
} from "../../../../../../components/BlueButton";
import {
  Cell,
  HeaderRow,
  Row,
  Table,
} from "../../../../../../components/ReportTables";
import styled from "@emotion/styled";
import { LightColors } from "../../../../../../light-theme";

const profNames = ["Minimal", "Partial", "Proficient", "Blank"];
const mindsetNames = ["High", "Average", "Low"];

const StandardCodeCell = styled(Cell)({
  fontWeight: "bold",
  borderRight: "none",
  textAlign: "left",

  minWidth: 60,
  "& span": {
    fontSize: 15,
    color: LightColors.blue,
    paddingLeft: 10,
  },
  "& div": {
    display: "inline-block",
    marginLeft: 7,
    position: "relative",
    top: 3,
  },
});
const StandardCell = styled(Cell)({ borderLeft: "none", borderRight: "none" });
const StandardButtonCell = styled(Cell)({ minWidth: 151, borderLeft: "none" });
const StatCell = styled(Cell)({ textAlign: "center" });
const SkillRow = ({ index, item }) => (
  <Row>
    <StandardCodeCell>
      <span
        onMouseEnter={(ev) =>
          rolloverTipBegin(ev, "TA_literarystyle_skills_standardslink")
        }
        onMouseLeave={rolloverTipEnd}
      >
        {item.skill}
      </span>
      {index === 2 && <PriorityStar />}
    </StandardCodeCell>

    <StandardCell>{item.name}</StandardCell>

    <StandardButtonCell>
      <SmallBlueButton
        onMouseEnter={(ev) =>
          rolloverTipBegin(ev, "TA_literarystyle_skills_previewitembutton")
        }
        onMouseLeave={rolloverTipEnd}
      >
        Preview Item
      </SmallBlueButton>
    </StandardButtonCell>

    <StatCell>{`${profNames[item.proficiency]}`}</StatCell>
    <StatCell>{mindsetNames[item.mindset]}</StatCell>
  </Row>
);

const SkillHeader = styled.th({ width: 650 });
const StatHeader = styled.th({ width: 200 });
const Title = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  marginTop: 5,
  marginBottom: 10,
});
const SkillOptions = styled.div({
  display: "flex",
  gap: 15,
  fontWeight: "bold",
  "& div": {
    display: "inline-block",
  },
});
const Skills = (props) => {
  return (
    <div className={css.root}>
      <Title>Skill Target Proficiency</Title>
      <div className={css.desc}>
        The scores reflect all student work on a skill throughout Waggle, not
        just this one assignment.
      </div>

      <SkillOptions>
        <span>
          <input type="radio" checked />
          Assigned Skills
        </span>

        <span
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "TA_literarystyle_skills_topskillgapsbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          <input disabled={true} type="radio" />
          Top Skill Gaps
        </span>

        <span style={{ flexGrow: 1 }} />

        <span>
          <PriorityStar /> = Priority Standard
        </span>
      </SkillOptions>
      <Table>
        <HeaderRow>
          <SkillHeader colSpan={3}>Skill</SkillHeader>
          <StatHeader>AVG PROFICIENCY</StatHeader>
          <StatHeader>AVG MINDSET</StatHeader>
        </HeaderRow>

        <tbody>
          {props.data.map((x, index) => (
            <SkillRow key={index} index={index} item={x} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Skills;
