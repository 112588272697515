import React from "react";
import css from "./Summary.module.css";

import Button from "../../Button";

import { components } from "@indieasy.software/indieasy-components";
import data from "../../../../../data/classInsightsSummary.json";

import DotBlue from "./assets/dot_blue.png";
import DotGreen from "./assets/dot_green.png";
import DotGrey from "./assets/dot_grey.png";
import DotOrange from "./assets/dot_orange.png";
import DotPink from "./assets/dot_pink.png";
import DotPurple from "./assets/dot_purple.png";
import DotRed from "./assets/dot_red.png";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const { CircleChart } = components;

const Summary = (props) => {
  const classData = data[props.subject][props.grade];

  const totalMinutes =
    classData.time.learningGoals +
    classData.time.skillGap +
    classData.time.skillBoosts +
    classData.time.games +
    classData.time.lessons;

  const totalHours = Math.floor(totalMinutes / 60);
  const remainderMinutes = totalMinutes % 60;

  return (
    <div className={css.root}>
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "classactivity_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>
      </div>
      <div className={css.split}>
        <div className={css.left}>
          <div className={css.title}>Summary</div>

          {classData.completed.classProficiency && (
            <div className={css.row}>
              <div className={css.text}>Class Proficiency:</div>
              <div className={css.value}>
                <b>Proficient {classData.completed.classProficiency}%</b>
              </div>
            </div>
          )}

          {classData.completed.learningGoalsCompleted && (
            <div className={css.row}>
              <div className={css.text}>Practice Completed*</div>
              <div className={css.value}>
                {classData.completed.learningGoalsCompleted}
              </div>
            </div>
          )}

          <div className={css.row}>
            <div className={css.text}>Skill Quizzes Completed*</div>
            <div className={css.value}>
              {classData.completed.skillBoostsCompleted}
            </div>
          </div>
          <div className={css.row}>
            <div className={css.text}>Learn Completed*</div>
            <div className={css.value}>
              {classData.completed.lessonsCompleted}
            </div>
          </div>
          <div className={css.row}>
            <div className={css.text}>
              <span>Points Earned</span>
              <span>(total)</span>
            </div>
            <div className={css.value}>{classData.completed.pointsEarned}</div>
          </div>
          {props.grade >= 3 ? (
            <div className={css.row}>
              <div className={css.text}>
                <span>Gems Earned</span>
                <span>(total)</span>
              </div>
              <div className={css.value}>{classData.completed.gemsEarned}</div>
            </div>
          ) : null}
        </div>
        <div className={css.divider} />
        <div className={css.right}>
          <div className={css.title}>
            <span>Student Activity</span>
            <span className={css.total}>(total)</span>
          </div>
          <div className={css.chart}>
            <CircleChart
              dropshadow
              style={{ marginLeft: "50px", marginTop: "-30px" }}
              scale="0.7"
              strokeWidth="15px"
              base={{ stroke: "#BCDFE8" }}
              data={[
                {
                  percent:
                    classData.time.learningGoals / classData.time.thisWeek,
                  stroke: "#7BBA46",
                },
                {
                  percent: classData.time.skillGap / classData.time.thisWeek,
                  stroke: "#0288C5",
                },
                {
                  percent: classData.time.skillBoosts / classData.time.thisWeek,
                  stroke: "#F89D02",
                },
                {
                  percent: classData.time.games / classData.time.thisWeek,
                  stroke: "#77368B",
                },
                {
                  percent: classData.time.lessons / classData.time.thisWeek,
                  stroke: "#FFC9E4",
                },
              ]}
            />
            <div className={css.hours}>
              <div className={css.value}>{totalHours}</div>
              <div className={css.title}>HR</div>
            </div>
            <div className={css.minutes}>
              <div className={css.value}>{remainderMinutes}</div>
              <div className={css.title}>MIN</div>
            </div>
          </div>

          <div className={css.legend}>
            {classData.time.learningGoals !== null && (
              <div className={css.row}>
                <img className={css.icon} src={DotGreen} />
                <div className={css.text}>Practice</div>
                <div className={css.line}>...................</div>
                <div className={css.value}>
                  {Math.round(
                    (100 * classData.time.learningGoals) /
                      classData.time.thisWeek
                  )}
                  %
                </div>
              </div>
            )}

            {classData.time.skillGap !== null && (
              <div className={css.row}>
                <img className={css.icon} src={DotBlue} />
                <div className={css.text}>{`Skill Gap & Remediation`}</div>
                <div className={css.line}>...................</div>
                <div className={css.value}>
                  {Math.round(
                    (100 * classData.time.skillGap) / classData.time.thisWeek
                  )}
                  %
                </div>
              </div>
            )}

            {classData.time.skillBoosts !== null && (
              <div className={css.row}>
                <img className={css.icon} src={DotOrange} />
                <div className={css.text}>Skill Quiz</div>
                <div className={css.line}>...................</div>
                <div className={css.value}>
                  {Math.round(
                    (100 * classData.time.skillBoosts) / classData.time.thisWeek
                  )}
                  %
                </div>
              </div>
            )}

            {classData.time.games !== null && (
              <div className={css.row}>
                <img className={css.icon} src={DotPurple} />
                <div className={css.text}>Play</div>
                <div className={css.line}>...................</div>
                <div className={css.value}>
                  {Math.round(
                    (100 * classData.time.games) / classData.time.thisWeek
                  )}
                  %
                </div>
              </div>
            )}

            {classData.time.lessons !== null && (
              <div className={css.row}>
                <img className={css.icon} src={DotPink} />
                <div className={css.text}>Learn</div>
                <div className={css.line}>...................</div>
                <div className={css.value}>
                  {Math.round(
                    (100 * classData.time.lessons) / classData.time.thisWeek
                  )}
                  %
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
