import { toolTips } from "@indieasy.software/indieasy-engine";

const data = `ROLLOVER TEACHER  DEMO TIPS							
LOCATION	ID	Title Copy	Body Copy	Placement/Trigger Notes			MC notes
Teacher Home screen: righthand panel: Class Activity view: Students tab	classactivity_exportbutton	Export	Exports student data to a downloadable spreadsheet. Not available in the demo.  	Home>righthand panel>click View Class Activity>all 4 tabs>  Export button			ok
Teacher Home screen: righthand panel: Class Activity view: Students tab	classactivity_viewgrowthbutton	Proficiency Growth Report	Displays the Proficiency Growth Report for the selected grade and subject. Not available in the demo.	Home>righthand panel>click Class Activity>all 4 tabs>  View Growth button	This actually works, doesnt need a tip		ok
Teacher Home screen: righthand panel: Class Activity view: Students tab	classactivity_changeselectionsbutton	Change Selections	Allows teachers to filter time of report. Not available in the demo.	Home>righthand panel>click Class Activity>Students tab> Change Selections button			ok
Teacher Home screen: righthand panel: Class Activity view: Standards tab	classactivity_standardstab_standards	Standards	Allows teachers to view student performance on the standard. Not available in the demo.	Home>righthand panel>click Class Activity>Standards tab> hovering over individual standards			ok
Teacher Home screen: righthand panel: Class Activity view: Skills tab	classactivity_skillstab_activity	Skills	Allows teachers to view student performance on the skill. Not available in the demo.	Home>righthand panel>click Class Activity>Skills tab> hovering over individual skill			ok
Teacher Home screen: righthand panel: Class Activity view: Student tab	classactivity_studentstab_student	Select Student 	Displays the XXX report for an individual student.  [what is the name of this report?]	Home>righthand panel>click Class Activity>Students tab> hovering over individual student names	This works in the demo and takes students to the individual student reports.		Do we want a tip to appear if you roll over students other than Bernard? Trelloed
Teacher Home screen: righthand panel: Mindset Report: Export button	mindset_exportbutton	Export	Exports student data to a downloadable spreadsheet. Not available in the demo.  	Home>righthand panel>click Mindset Report>Export button			ok
Teacher Home screen: righthand panel: Skills Status Report window	skillstatus_findcontentbutton	Find Content	Finds appropriate content for the student. Not available in the demo.	Student Popup (Skills Tab)	No Find Content option is available on this screen. Was this supposed to be referencing the View Growth button?	DL: This is in the Student report on the Skills tab	ok
Teacher Home screen: righthand panel: Skills Status Report window	skillstatus_changeselectionsbutton	Change Selections	Allows edits to selections.  Not available in the demo.	Student Popup (Skills Tab)	No Change Selections Option available for this screen	DL: Should be the students tab	ok
Teacher Home screen: righthand panel: Skills Status Report window	skillstatus_exportbutton	Export 	Exports student data to a downloadable spreadsheet. Not available in the demo.  	Home: Righthand panel: Skill Status Report>Skills tab> Export button	ALSO apply to the Student Popup. FOR ALL TABS!		ok
Teacher Home screen: righthand panel: Skills Status Report window: Activity tab	skillstatus_datedropdown	Select Time Period	Displays data based on dates selected.  Not available in the demo.	Home: Righthand panel: click Bar graph icon for Bernard Massey>Activity tab> Date dropdown		MC: Available for individual student; click bar graph icon for Bernard Massey 	ok
Teacher Home screen: righthand panel: Skills Status Report window: Skills tab	skillstatus_skill	Skills	Allows teachers to view student performance on the standard. Not available in the demo.	Home: Righthand panel: Skill Status Report>Skills tab> hovering over individual skills			ok
Teacher Home screen: Home tab: Student Needs Help tab: View by Students	Home_Help_ViewbyStudents_Find Content button	Find Content  	Click on the Find Content button for the student at the top to view functionality.	Home: Student Need Help tab: View by Students: Find Content buttons other than the top Find Content button			ok
Teacher Home screen: Home tab: Student Needs Help tab: View by Skills	Home_Help_ViewbySkills_Find Content button	Find Content  	Click on the Find Content button for the student at the top to view functionality.	Home: Student Need Help tab: View by Skills: Find Content buttons other than the top Find Content button			ok
Teacher Home screen: Home tab: Student Needs Help tab: View by Skills	Home_Help_ViewbyStudents_studentname	Student Data	Allows teachers to view each student's data. Not available in the demo.  	Home: Student Need Help tab: View by Skills: hovering over individual student names			ok
Teacher Home screen: Home tab: Student Needs Help tab: View by Skills	Home_Help_ViewbyStudents_bargraphicon	Student Report	Allows teachers to view each student's data. Click on the top report icon for an example.  	Home: Student Need Help tab: View by Student: hovering over bar graph icon			ok
Teacher Home screen: Home tab: Shout-outs tab	Home_Shout_lastweek	Shout-outs	Displays shout-outs from the previous week.  Not available in the demo.	Home: Shout-outs: Last Week link			ok
Teacher Home screen: Home tab: Leaderboard tab: Gems tab	Home_Leaderboardgems_viewgemsreport	Gems	Allows teachers to view all students and export Leaderboard data.  Not available in the demo.	Home: Leaderboard tab: Gems tab: View Gems Report link			ok
Teacher Home screen: Home tab: Leaderboard tab: Points tab	Home_Leaderboardgems_viewpointsreport	Points	Allows teachers to view all students and export Leaderboard data.  Not available in the demo.	Home: Leaderboard tab: Points tab: View Points Report link			ok
Teacher Home screen: Track Assignments tab	TA_assignment_link	Select Assignment	Provides a class report on progress on this assignment. Click on the top link for an example.	Track Assignment: Assignment Links in "Assignment Name" column below the first row			ok - Fixed! The tip is missing this sentence: Click on the top link for an example."  Tip has this sentence instead which wasn't provided by HMH: 'Not available in the demo.' Trelloed
Teacher Home screen Track Assignments tab	TA_statusdropdown	Select Assignment Status	Filters results based on assignment status. Not available in the demo.	Track Assignment: Completion dropdown>hovering over All, Open or Completed			ok
Teacher Home screen Track Assignments tab	TA_studentsdropdown	Select Students	Filters results based on student. Not available in the demo.	Track Assignment: Completion dropdown>hovering over All, Open or Completed			ok
Teacher Home screen Track Assignments tab	TA_contenttypedropdown	Select Content Type	Filters results based on assignment type. Not available in the demo	Track Assignment: click Content Type dropdown>hover over Skill Boost, Learning Goals, or Lessons or Games			ok
Teacher Home screen Track Assignments tab	TA_editbutton	Edit	Allows teachers to edit assignment details, including start and due dates. Not available in the demo.	Track Assignments: Edit button			ok
Teacher Home screen Track Assignments tab	TA_trashbutton	Trash	Deletes assignment.  Not available in the demo.	Track Assignments: Trash button			ok
Teacher Home screen Track Assignments tab	TA_unassignbutton	Unassign	functionality?  Not available in the demo.	Track Assignments: Unassign button	Not a button. 		
Teacher Home screen Track Assignments tab: Black vertical Edit Assignments bar	TA_vertical EditAssignments	Edit Assignments	Allows the teacher to edit multiple assignments at once, including Students Assigned and Due Dates.  Not available in the demo.	Track Assignments: vertical Edit Assignments bar at right of screen			ok
Teacher Home screen Track Assignments tab: Black vertical Edit Assignments bar	TA_vertical SearchAssignments	Search Assignments	Allows the teacher to search for specific assignments.  Not available in the demo.	Track Assignments: vertical Edit Assignments bar at right of screen			ok
Teacher Home screen: Track Assignments tab: Open Assignments dropdown	TA_openassignments	Open Assignments	Filters results based on assignment status. Not available in the demo.	Track Assignments: Open Assignments dropdown	looks like this is the same as ta_statusdropdown, but when the dropdown is OPEN? Not supporting that yet, unless Im making a mistake and I forgot an easy way to do this		
Teacher Home screen: Track Assignments tab: All Content Types dropdown	TA_allcontenttypes	All Content Types	Filters results based on assignment status. Not available in the demo.	Track Assignments: All Content Types dropdown	looks like this is the same as TA_contenttypedropdown, but when the dropdown is OPEN? Not supporting that yet, unless Im making a mistake and I forgot an easy way to do this		
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab: Start date	TA_literarystyle_startdate	Start	Allows teachers to change the start date of the assignment. Not available in demo.	Track Assignments>Gr3 ELA> click first Assignment>Literary Style screen>Summary tab>Start button			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab: Due date	TA_literarystyle_duedate	Due	Allows teachers to change the due date of the assignment. Not available in demo.	Track Assignments>Gr3 ELA> click first Assignment>Literary Style screen>Summary tab>Due button			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_exportbutton	Export/Print	Allows teachers to print or export the report as a .pdf. Not available in the demo.	Track Assignments>select Gr3 ELA> click first Assignment>Literary Style screen>Summary tab>Export/Print button			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_notstartedlink	Not Started	Displays list of students who have not started this assignment. Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Not Started link			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_needshelplink	Needs Help	Displays list of students who need help on this assignment. Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Needs Help link			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_makingprogresslnk	Making Progress	Displays list of students who are making progress on this assignment. Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Making Progress link			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_completedlink	Completed	Displays list of students who have completed this assignment. Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Completed link			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab	TA_literarystyle_skills_topskillgapsbutton	Top Skill Gaps	Displays list of top skill gaps. Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Skills tab>Top Skill Gaps button			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab	TA_literarystyle_skills_previewitembutton	Preview Item	Allows teacher to preview the student content. Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Skills tab>Preview Item button			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab	TA_literarystyle_skills_findcontentbutton	Find Content	Allows teachers to view content related to the assignment. Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Skills tab>Find Content button			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab: Standards link	TA_literarystyle_skills_standardslink	Standards	Presents the standards skill definition. Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Stylet screen>Skills tab>Standards link			ok
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: LIterary Style screen: Students tab	TA_literarystyle_students_findcontent	Find Content 	Allows teachers to view content related to the assignment. Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Students tab>Find Content link			ok
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_startdate	Start	Allows teachers to change the start date of the assignment. Not available in demo.	Track Assignments>Gr3 Math> click first Lesson>Lesson screen>Start button			ok
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_duedate	Due	Allows teachers to change the due date of the assignment. Not available in demo.	Track Assignments>Gr3 Math> click first Lesson>Lesson screen>Due button			ok
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_changestudentsbutton	Change Student	Allows teachers to add or remove students to the assignment. Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>Change Student button			ok
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_previewbutton	Preview   	Allows teacher to preview the student content. Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>Preview button			ok
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_printbutton	Export / Print 	Allows teachers to export or print the report. Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>Print button			ok
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_viewclassperformancebutton	View Class Performance	functionality?  Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>View Class Performance button	Not sure what button this is.		Button has been removed, no longer needed.
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_studentnames	Student Report	Allows teachers to view individual student reports. Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>hover over student names			ok - Per Ryan: Student Report Fixed for Gr3Math
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_score	View Student Responses	Opens the content. Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>hover over individual student scores			ok - Per Ryan: View Student Responses.  
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_startdate	Start  	Allows teachers to change the start date of the assignment. Not available in demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Start button			ok
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_duedate	Due  	Allows teachers to change the due date of the assignment. Not available in demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Due button			ok
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboostscreen_changestudentsbutton	Change Student  	Allows teachers to add or remove students to the assignment. Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Change Student button			ok
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboostscreen_previewbutton	Preview  	Allows teacher to preview the student content. Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Preview button			ok
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboostscreen_printbutton	Print  	Allows teachers to print the report. Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Print button			ok
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_viewclassperformancebutton	View Class Performance  	functionality?  Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>View Class Performance button	Button has been removed, no longer needed.		ok - This link has been removed from the demo, as per Ryan
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_studentnames	Student Reports	Allows teachers to view individual student reports. Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>hover over student names			Tip should be Student Reports
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_score	View Student Responses	Opens the content. Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>hover over individual student scores			Tip should be View Student Responses
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_exportbutton	Export/Print  	Allows teachers to print or export the report as a .pdf. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Export/Print button	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_notstarted	Not Started  	Opens the content. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Not started link	same tip as ela3	DL: It's the 4 text links under "Student Status"	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_needshelp	Needs Help  	Opens the content. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Needs Help link	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_makingprogress	Making Progress  	Opens the content. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab>Making Progress link	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_completed	Completed  	Opens the content. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Completed link	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_topskillgapsbutton	Top Skill Gaps	Presents top skill gaps as a result of the assignment. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab> TopSkill Gaps button	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_previewitem button	Preview Item  	Opens the content. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab> Preview Item button	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_findcontentbutton	Find Content  	Allows teachers to view content related to the assignment. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab>Find Content button	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_standardslink	Standards  	Presents the standards skill definition. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab>Standards link	same tip as ela3		
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Students tab	TA_proportional_students_findcontentbutton	Standards  	Displays content targeted for these skills. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Students tab>Standards link	same tip as ela3		
Teacher Home screen Growth tab: Waggle Growth tab	WaggleGrowth_Timedropdown	Report Time Period	Select report time period. Not available in the demo.	Growth tab:>Waggle Growth tab> Time dropdown			ok
Teacher Home screen Rosters screen	Home_roster_addstudentbutton	Add Student  	Adds a new student to the roster.  Not available in the demo.	Home: Roster>Add Student button	Button has been removed, no longer needed.		
Teacher Home screen Rosters screen	Home_roster_addnewgroupbutton	Add New Group 	Allows teachers to group their students. Not available in the demo.	Home: Roster>Add New Group button			ok
Teacher Home screen Rosters screen	Home_roster_turnonELbutton	Turn on EL Support	Turns on English Language supports--including Spanish Language supports--for English Language Learners. Not available in the demo.	Home: Roster>Turn on EL Support button			ok
Teacher Home screen Rosters screen	Home_roster_turnonvocabbutton	Turn on Vocabulary	Turns on Academic Vocabulary supports for students.  Not available in the demo.	Home: Roster>Turn on Vocabulary button			ok
Teacher Home screen Rosters screen	Home_roster_viewprofilebutton	View Profile	Shows student log in details. Not available in the demo.	Home: Roster>View Profile button			ok - Fixed  Typo from HMH - should be 'available' - Trelloed
Teacher Home screen Rosters screen	Home_roster_printbutton	Print  	Prints student login details.  Not available in the demo.	Home: Roster>Print button	Button has been removed, no longer needed.		
Teacher Home screen Rosters screen	Home_roster_viewreportbutton	View Report  	Click on the top entry to view the individual student reports.	Home: Roster>View Report button			ok - Fixed.  Typo from HMH - should be "entry' - Trelloed
Teacher Home screen Rosters screen	Home_roster_viewblockdetails	View Block Details  	Click on the top entry to view sample data.	Home: Roster>View Report button			ok - Fixed.  Typo from HMH - should be "entry' - Trelloed
Teacher Home screen Rosters screen	Home_roster_printtogetherbutton	All Together  	Prints roster login details all together.  Not available in the demo.	Home: Roster>All Together button	Button has been removed, no longer needed.		
Teacher Home screen Rosters screen	Home_roster_printseparatebutton	Separate Page  	Prints roster login details separately.  Not availble in the demo.	Home: Roster>Separate page button	Button has been removed, no longer needed.		
Teacher Home screen Alerts button	Home_alerts	Settings	Allows teachers to modify their class settings for Auto Assign, Language Supports, and more.	Home: Alerts button			ok
Teacher Home screen Message button	Home_message	Message 	Allows teacher to send messages to groups, the class, or individual students. Not available in the demo.	Home: Message button			ok
Teacher Home screen Find Content tab: Math: Domain	FC_Choosestandard	Choosing Standards	For the purposes of this demo, click on the top Standard.	Find Content: Math:  Domain: clicking any Standard in the list other than the top Standard			ok
Teacher Home screen Find Content tab: Math: Domain	FC_Skill	Choosing Skills	For the purposes of this demo, click on the top Skill.	Find Content: Math:  Domain: clicking any Skill in the list other than the top Skill			ok - Fixed.   
Teacher Home screen Find Content tab: Math: Into Math Lesson: Choose Module	FC_Choosemodule	Choosing Modules	For the purposes of this demo, click Module 1.	Find Content: Math: Into Math Lesson: clicking any modules in the list other than the top modle			ok
Teacher Home Screen: Find Content: Gr6 ELA: Into Lit Passage: Unit 1: hover over items other than "from Brown Girl Dreaming"	FC_Unit	Choosing Units	For the purposes of this demo, click the top Unit in the list.	Into Lit Passage			ok - Fixed.   
Teacher Home Screen: Find Content: Gr6 ELA: Into Lit Passage: Unit 1: hover over Units	FC_Passage	Choosing Passages	For the purposes of this demo, click the top Passage in the list.	Into Lit Passage, 1st Unit list			ok - Fixed.   
Teacher Home screen Find Content tab: Math: Into Math Lesson: Choose Module	FC_Chooselesson	Choosing Lessons	For the purposes of this demo, select the top Lesson in the list.	Find Content: Math: Into Math Lesson: clicking any Lessons in the list other than the top Lesson			ok
Teacher Home screen Find Content tab: ELA: clicking Weeks 2,3 under Module 1	FC_Week	Choosing Weeks	For the purposes of this demo, select the top Week in the list.	Find Content: EL: Into ELA module: clicking any Week in the list			ok
Teacher Home Screen: Find Content tab: Math only	FC_Domain	Choosing Domains	For the purposes of this demo, select the top domain.	Find Content> hovering over Domains other than the top Domain			ok
Teacher Home screen Find Content tab: ELA: Choose Strand	FC_Strand	Choosing Strands	For the purposes of this demo, select the top strand.	Find Content: Choose Strand: Strands: clicking any of the individual strands other than the top Strand listed			ok
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_moreinfo	More Info	For the purposes of this demo, click the "More Info" link in the first row.	Find Content: Student Practice: clicking on "More Info" links other than the top "More Info" link			ok
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_previewbutton	Preview	Allows teachers to preview the student content. Not available in the demo.	Find Content: Student Practice>More Info>Preview button			ok
Teacher Home screen Find Content tab: Student Practice: Learning Goal	FC_Preview_item	Preview Item	Allows teachers to preview the student content. Not available in the demo.	Find Content: Student Practice>Learning Goal>More Info>Preview Item button			ok - NEW TIP - TRELLOED
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_viewreportbutton	View Report  	Allows teachers to view student results for the assignments. Not available in the demo.	Find Content: Student Practice>View Report button			ok
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_selectbutton	Select  	For the purposes of this demo, click the "Select" link in the first row.	Find Content: Student Practice: clicking on "Select" button other than the top row			ok
Teacher Home screen Find Content tab: Student Practice: vertical bar Create Assignment> Assign Students and Dates>Assignment Details window	FC_Student Practice_assignmentdetails_updatemultipledatesbutton	Update Multiple Dates	Allows the teacher to add start and end dates for assignments and assign assignments to students. Not available in the demo.	Find Content: Student Practice: clicking on the vertical "Create Assignments" bar> Assign Students and Dates>Assignment Details window			ok
Teacher Home screen Find Content tab: Student Practice: vertical bar Create Assignment> Assign Students and Dates>Assignment Details window	FC_Student Practice_assignmentdetails_sssignbutton	Assign	Allows the teacher to add start and end dates for assignments and assign assignments to students. Not available in the demo.	Find Content: Student Practice: clicking on the vertical "Create Assignments" bar> Assign Students and Dates>Assignment Details window			ok
HMH_GROWTH REPORT	HMHGrowth_overview	Students	For purposes of this demo, click the Above Grade students.				ok
HMH_GROWTH REPORT	HMHGrowth_domain	Students	For purposes of this demo, click the On-Level students in the left column.				ok
Other Reports	MindsetReport	Reports	The Mindset Report is not available in the demo
WAGGLE Growth REPORT	WaggleGrowthHowRead	How To Read	Directions for reading the report. Not available in Demo.				ok
Generic Tip	TA_NotAvailable	This feature is not available in the demo.				ok
Student Report	SR_Detail	Student Detail Report	View the detailed report for this student. Select the top student for a demo.
Skill Report	SR2_Detail	Skill Report	View the detailed report for this skill. Select the top skill for a demo.
Shout Outs	ShoutOutLastWeek	Last Week	Only this week is available in the simulator.
Teacher Home Screen	ReviewSetup	Allows teachers to revisit the Guided Setup information and videos from their first login to Waggle. Not available in the demo.`;



const lines = data.split("\n");

const rolloverTips = {};

for (var i = 2; i < lines.length; i++) {
  const line = lines[i];
  const tokens = line.split("\t");

  if (!tokens[1]) continue;

  rolloverTips[tokens[1]] = {
    title: tokens[2],
    text: tokens[3],
  };
}

export default rolloverTips;

export const rolloverTipCustomBegin = (ev, title, text, width, offset) => {
  //console.log(id);
  //console.log(rolloverTips);
  const rect = ev.currentTarget.getBoundingClientRect();
  toolTips.system.getManager("disabled").show(
    toolTips.createToolTip(
      rect.left + rect.width / 2 + (offset || 0),
      rect.top + rect.height,
      {
        style: { width: width || "250px" },
        title: title,
        text: text,
      }
    )
  );
};

export const rolloverTipBegin = (ev, id, width, offset) => {
  //console.log(id);
  //console.log(rolloverTips);
  const rect = ev.currentTarget.getBoundingClientRect();
  toolTips.system.getManager("disabled").show(
    toolTips.createToolTip(
      rect.left + rect.width / 2 + (offset || 0),
      rect.top + rect.height,
      {
        style: { width: width || "250px" },
        title: rolloverTips[id].title,
        text: rolloverTips[id].text,
      }
    )
  );
};

export const rolloverTipEnd = (ev) => {
  toolTips.system.getManager("disabled").hide();
};
