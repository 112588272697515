import React from "react";

import styled from "@emotion/styled";
import { LightColors } from "../../light-theme";

const TabContainer = styled.div(({ selected }) => {
  const bg = selected ? LightColors.white : LightColors.grey2;
  return {
    position: "relative",
    top: 1,
    padding: "15px 30px",
    backgroundColor: bg,
    border: `1px solid ${LightColors.grey1}`,
    borderBottom: selected ? `1px solid ${bg}` : `1px solid ${LightColors.grey1}`,
    fontWeight: "bold",
    color: LightColors.black,
    cursor: selected ? "arrow" : "pointer",
  };
});

export const Tab = (props) => {
  const clickHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <TabContainer selected={props.selected} onMouseDown={clickHandler}>
      {props.children}
    </TabContainer>
  );
};

export const TabGroup = styled.div({
  display: "flex",
  flexDirection: "row",
});

export default Tab;
