import React, { Fragment, useState } from "react";
import css from "./Standards.module.css";

import Button from "../../Button";
import Box from "../../../Home/Box";
import Bold from "../../../../../components/Bold";

import data from "../../../../../data/studentInsightsStandard.json";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

import ProficiencyTable from "../../ProficiencyTable";
import BackArrow from "../../BackArrow";

import selectedData from "../../../../../data/studentPopupStandard";

const Standards = (props) => {
  const [selected, setSelected] = useState(false);

  const selectedHandler = (value) => {
    //setSelected(value);
  };
  const rows = data[props.subject][props.grade].map((x, index) => {
    return (
      <div key={index} className={css.row}>
        <Box
          style={{
            textIndent: "20px",
            textAlign: "left",
            color: "#056B90",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          borderRight
          borderBottom
          width="200px"
        >
          <div
            onMouseDown={() => {
              if (index == 0) selectedHandler(true);
            }}
          >
            {x.standard}
          </div>
        </Box>
        <Box borderRight borderBottom width="100px">
          {x.proficiency}
        </Box>
        <Box borderRight borderBottom width="100px">
          {x.mindset}
        </Box>
        <Box borderRight borderBottom width="100px">
          {x.correctResponses}
        </Box>
        <Box borderRight borderBottom width="100px">
          {x.incorrectAttempts}
        </Box>
        <Box borderBottom width="93px">
          {x.growth}
        </Box>
      </div>
    );
  });

  const contents = selected ? (
    <Fragment>
      <ProficiencyTable
        style={{ height: "240px" }}
        data={selectedData}
        name="STANDARD"
        type="STANDARD"
      />
    </Fragment>
  ) : (
    <Fragment>
      <Box
        style={{
          marginLeft: "300px",
          textAlign: "center",
          height: "20px",
          color: "#5A5A5A",
          fontSize: "13px",
          lineHeight: "20px",
        }}
        width="398px"
        background="#BBDFE8"
      >
        Number of Students
      </Box>
      <div className={css.row}>
        <Box borderRight borderBottom width="200px" background="#EDF6F9">
          STANDARD
        </Box>
        <Box borderRight borderBottom width="100px" background="#EDF6F9">
          PROFICIENCY
        </Box>
        <Box borderRight borderBottom width="100px" background="#EDF6F9">
          MINDSET
        </Box>
        <Box borderRight borderBottom width="100px" background="#EDF6F9">
          <div style={{ marginTop: "4px", lineHeight: "18px" }}>CORRECT</div>
          <div style={{ lineHeight: "18px" }}>RESPONSES</div>
        </Box>
        <Box borderRight borderBottom width="100px" background="#EDF6F9">
          <div style={{ marginTop: "4px", lineHeight: "18px" }}>INCORRECT</div>
          <div style={{ lineHeight: "18px" }}>ATTEMPTS</div>
        </Box>
        <Box borderBottom width="93px" background="#EDF6F9">
          GROWTH
        </Box>
      </div>
      <div className={css.students}>{rows}</div>
    </Fragment>
  );

  return (
    <div className={css.root}>
      {/*selected ? <div className={css.back}><BackArrow onClick={() => setSelected(false)}/></div> : null*/}
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "skillstatus_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>
      </div>
      <div className={css.details}>
        <div>
          <Bold>Total Students : </Bold>10
        </div>
        {selected ? <Fragment></Fragment> : null}
      </div>
      {contents}
    </div>
  );
};

export default Standards;
