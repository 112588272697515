import React from "react";
import CheckBox from "../CheckBox";
import Row from "../Row";
import css from "./HeaderRow.module.css";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const HeaderRow = (props) => {
  return (
    <div className={css.root}>
      <Row>
        <div>NAME</div>
        <div>
          <span style={{ marginRight: "5px" }}>EL SUPPORT</span>
          <CheckBox selected={props.support} onClick={props.onSupport} />
        </div>
        <div>ID</div>
        <div>
          <span style={{ marginRight: "5px" }}>VOCABULARY</span>
          <CheckBox selected={props.vocabulary} onClick={props.onVocabulary} />
        </div>
        <div>VIEW PROFILE</div>
        <div>LOGIN DETAILS</div>
        <div>REPORTS</div>
      </Row>
    </div>
  );
};

export default HeaderRow;
