import * as React from "react"

function MinusCircle(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10} cy={10} r={9.5} fill="#FBFCFD" stroke="#BFC0C0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99994 10C4.99994 9.60512 5.32006 9.285 5.71494 9.285H14.2849C14.6798 9.285 14.9999 9.60512 14.9999 10C14.9999 10.3949 14.6798 10.715 14.2849 10.715H5.71494C5.32006 10.715 4.99994 10.3949 4.99994 10Z"
        fill="#1261BF"
      />
    </svg>
  )
}

export default MinusCircle
