import styled from "@emotion/styled";
import { InfoIcon } from "../../FindContent2/by-strand/InfoIcon";
import { useToggle } from "../../../../../../common/util/useToggle";
import { LinkButton } from "../../../../components/BlueButton";
import { keyframes } from "@emotion/react";

export const InfoTooltip = ({ children, tooltip }) => {
  const [isHover, toggleTooltip, showTooltip, hideTooltip] = useToggle();

  return (
    <>
      <InfoIcon
        onClick={toggleTooltip}
        style={{ cursor: "pointer", position: "absolute", top: 20, right: 10 }}
      />
      {isHover && (
        <Tooltip>
          {tooltip}
          <br />
          <LinkButton
            style={{ position: "absolute", right: "10px" }}
            onClick={hideTooltip}
          >
            Close
          </LinkButton>
        </Tooltip>
      )}
    </>
  );
};

const fadeInSlideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 50px;
  right: 0px;

  background-color: white;
  font-size: 12px;
  font-weight: normal;
  padding: 10px 10px 20px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border: 2px solid #e0e0e0;
  animation: ${fadeInSlideUp} 0.3s ease-in-out;
  width: 200px;
`;
