import { checkPropTypes } from "prop-types";
import React, { useState, Fragment } from "react";
import css from "./Button.module.css";

const Button = (props) => {
  const [isOver, setIsOver] = useState(false);

  const mouseEnterHandler = (ev) => {
    if (props.onMouseEnter) props.onMouseEnter(ev);
    setIsOver(true);
  };

  const mouseLeaveHandler = (ev) => {
    if (props.onMouseLeave) props.onMouseLeave(ev);
    setIsOver(false);
  };

  const mouseDownHandler = (ev) => {
    if (props.onClick) props.onClick(ev);
  };

  const style = {};
  style.width = props.width;

  if (!isOver) style.background = props.background;
  let contents;

  if (props.icon) {
    style.display = "flex";
    contents = (
      <Fragment>
        <img src={props.icon} width={props.iconWidth} />
        <div style={{ width: "100%" }}>{props.children}</div>
      </Fragment>
    );
  } else {
    contents = props.children;
  }

  return (
    <div
      style={style}
      className={css.root}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseDown={mouseDownHandler}
    >
      {contents}
    </div>
  );
};

export default Button;
