import React from "react";
import { useParams } from "react-router-dom";
import { BackLink } from "../by-strand/BackLink";
import {
  BackOrOptions,
  Container,
  DetailContainer,
  DetailDetails,
  DetailExpand,
  DetailRow,
  Filters,
  Title,
} from "../CommonFindContent";

import { useToggle } from "../../../../../../common/util/useToggle";
import { ToggleIcon } from "../by-strand/ToggleIcon";
import styled from "@emotion/styled";
import { InfoIcon } from "../by-strand/InfoIcon";
import { lessonTypes } from "../lesson-types";
import { gradeToIndex } from "../../../../routing-util";
import { FilterOptions, useFilterOptions } from "../FilterOptions";
import filters2 from "../filters2.png";
import { useSelectLesson } from "../use-select-lesson";
import { AssignmentBanner } from "../AssignmentBanner";
import { useMoreInfoPopup } from "../use-more-info-popup";
import { AboutLessonPopup } from "../AboutLessonPopup";
import { LightColors } from "../../../../light-theme";
import { unitDataByType } from "./unit-data";

export const LessonTable = styled.table({
  marginLeft: -19,
  marginRight: -19,
  marginTop: 18,
  marginBottom: -19,
  backgroundColor: LightColors.white,
  borderCollapse: "collapse",
  "& tr": {
    border: `1px solid ${LightColors.grey1}`,
    "& td": {
      borderTop: `1px solid ${LightColors.grey1}`,
      padding: 10,
    },
    "& td:nth-of-type(1)": { width: 20 },
    "& td:nth-of-type(2)": { width: 1000 },
    "& td:nth-of-type(3)": { width: 500 },

    "& td:nth-of-type(4)": { width: 20 },
  },
});

const LessonHeader = styled.td({
  color: LightColors.blue,
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,
  textAlign: "left",
  paddingLeft: "15px !important",
});

const SubLesson = ({ lesson, toggleLessons, selectedLessons }) => {
  const { popupData, openPopupHandler, closePopupHandler } =
    useMoreInfoPopup(lesson);
  return (
    <tr>
      <td>
        <input
          type="checkbox"
          onChange={toggleLessons([lesson.id])}
          checked={selectedLessons.indexOf(lesson.id) !== -1}
        />
      </td>
      <td>{lesson.title}</td>

      <td>{lessonTypes[lesson.type]}</td>

      <td>
        <InfoIcon onClick={openPopupHandler} />
        {popupData && (
          <AboutLessonPopup onClose={closePopupHandler} lesson={popupData} />
        )}
      </td>
    </tr>
  );
};

const Lesson = ({ lesson, filter, toggleLessons, selectedLessons }) => {
  const practiceLessons = lesson.practice.filter(filter);
  if (practiceLessons.length === 0) return null;
  return (
    <React.Fragment>
      <tr>
        <LessonHeader colSpan={4}>{lesson.name}</LessonHeader>
      </tr>
      {practiceLessons.map((lesson, index) => (
        <SubLesson
          lesson={lesson}
          key={index}
          toggleLessons={toggleLessons}
          selectedLessons={selectedLessons}
        />
      ))}
    </React.Fragment>
  );
};

const Module = ({ module, filter, toggleLessons, selectedLessons }) => {
  const [open, toggleOpen] = useToggle();
  const allLessons = (module.lessons || [])
    .reduce((a, c) => a.concat(c.practice), [])
    .filter(filter);

  const practiceLessons = module.practice.filter(filter);

  if (practiceLessons.length === 0 && allLessons.length === 0) return null;
  return (
    <DetailContainer backgroundColor={LightColors.white} borderRadius={10}>
      <DetailRow>
        <DetailDetails onClick={toggleOpen}>
          <span dangerouslySetInnerHTML={{ __html: module.name }} />
        </DetailDetails>

        <DetailExpand onClick={toggleOpen}>
          <ToggleIcon open={open} />
        </DetailExpand>
      </DetailRow>
      {open && module.lessons && (
        <LessonTable>
          <tbody>
            {module.lessons.map((subLesson, index) => (
              <Lesson
                key={index}
                lesson={subLesson}
                filter={filter}
                toggleLessons={toggleLessons}
                selectedLessons={selectedLessons}
              />
            ))}
          </tbody>
        </LessonTable>
      )}

      {open && practiceLessons && (
        <LessonTable>
          <tbody>
            {practiceLessons.map((subLesson, index) => (
              <SubLesson
                toggleLessons={toggleLessons}
                selectedLessons={selectedLessons}
                key={index}
                lesson={subLesson}
              />
            ))}
          </tbody>
        </LessonTable>
      )}
    </DetailContainer>
  );
};

export const Unit = ({ classGrade, grade, groups, languageIndex }) => {
  const { subject, unitType, unitIndex } = useParams();
  const data = unitDataByType(unitType);
  const unit = data[subject][gradeToIndex(grade)][unitType][unitIndex];
  const filterOptions = useFilterOptions(
    languageIndex,
    gradeToIndex(grade),
    subject
  );
  const { toggleLessons, selectedLessons, resetLessonSelection } =
    useSelectLesson();

  return (
    <Container>
      <BackOrOptions>
        <BackLink
          destination={`/findcontent/${subject}/${classGrade}/${grade}/${unitType}`}
          label={`All Units`}
        />
      </BackOrOptions>
      <Title dangerouslySetInnerHTML={{ __html: unit.name }} />
      <Filters>
        <FilterOptions {...filterOptions} />
        <img src={filters2} />
      </Filters>
      {unit.lessons.map((module, index) => (
        <Module
          module={module}
          filter={filterOptions.filter}
          toggleLessons={toggleLessons}
          selectedLessons={selectedLessons}
        />
      ))}
      <AssignmentBanner
        groups={groups}
        selectedLessons={selectedLessons}
        resetLessonSelection={resetLessonSelection}
      />
    </Container>
  );
};
