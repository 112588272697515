import * as React from "react"

export function MoveDown(props) {
    return (
        <svg
            width={8}
            height={8}
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_12586_847)">
                <path
                    d="M4 4.22l2.207-2.15a.839.839 0 111.165 1.207L4.69 5.84a1 1 0 01-1.382 0L.629 3.276A.839.839 0 111.792 2.07L4 4.22z"
                    fill="#131313"
                />
            </g>
            <defs>
                <clipPath id="clip0_12586_847">
                    <path fill="#fff" d="M0 0H8V8H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MoveDown
