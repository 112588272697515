import React, { Fragment, useState } from "react";
import css from "./Skills.module.css";

import Button from "../../Button";
import Box from "../Box";
import Bold from "../../../../../components/Bold";

import data from "../../../../../data/classInsightsSkills.json";
import selectedData from "../../../../../data/classPopupSkills";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

import ProficiencyTable from "../../ProficiencyTable";
import BackArrow from "../../BackArrow";

const Skills = (props) => {
  const [selected, setSelected] = useState(false);

  const selectedHandler = (value) => {
    setSelected(value);
  };

  const rows = data[props.subject][props.grade].map((x, index) => {
    var entries = x.entries.map((y, index2) => {
      return (
        <div key={index + "_" + index2} className={css.row}>
          <Box
            style={{
              textAlign: "left",
              color: "#056B90",
              fontWeight: "bold",
            }}
            borderRight
            borderBottom
            width="300px"
          >
            <span
              style={{ cursor: "pointer" }}
              onMouseDown={() => {
                if (index == 0)
                  selectedHandler({
                    standard: y.standard,
                    assignment: x.assignment,
                  });
              }}
              onMouseEnter={(ev) => {
                if (index > 0)
                  rolloverTipBegin(ev, "classactivity_skillstab_activity");
              }}
              onMouseLeave={rolloverTipEnd}
            >
              {y.standard}
            </span>
          </Box>
          <Box
            borderRight
            borderBottom
            width="100px"
            style={{ justifyContent: "center" }}
          >
            {y.assigned}
          </Box>
          <Box
            borderRight
            borderBottom
            width="100px"
            style={{ justifyContent: "center" }}
          >
            {y.notStarted}
          </Box>
          <Box
            borderRight
            borderBottom
            width="100px"
            style={{ justifyContent: "center" }}
          >
            {y.inProgress}
          </Box>
          <Box borderBottom width="100px" style={{ justifyContent: "center" }}>
            {y.proficientOrAbove}
          </Box>
        </div>
      );
    });

    return (
      <div key={index}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "40px",
            lineHeight: "40px",
            fontSize: "15px",
          }}
          borderBottom
          width="100%"
          background="#dceef3"
        >
          <div style={{ textIndent: "20px", color: "black" }}>
            ASSIGNMENT: {x.assignment}
          </div>
          <div style={{ marginRight: "50px", color: "black" }}>
            DUE: {x.date}
          </div>
        </Box>
        {entries}
      </div>
    );
  });

  const contents = selected ? (
    <Fragment>
      <ProficiencyTable
        style={{ height: "220px" }}
        data={selectedData}
        name="STUDENT NAME"
        type="SKILL"
      />
    </Fragment>
  ) : (
    <Fragment>
      <Box
        style={{
          marginLeft: "300px",
          textAlign: "center",
          height: "20px",
          color: "#5A5A5A",
          fontSize: "13px",
          lineHeight: "20px",
        }}
        width="398px"
        background="#D9D9D9"
      >
        Number of Students
      </Box>
      <div className={css.row}>
        <Box
          borderRight
          borderBottom
          width="300px"
          background="rgba(150, 150, 150, 0.15686275)"
        >
          SKILL
        </Box>
        <Box
          borderRight
          borderBottom
          width="100px"
          style={{ justifyContent: "center" }}
          background="rgba(150, 150, 150, 0.15686275)"
        >
          ASSIGNED
        </Box>
        <Box
          borderRight
          borderBottom
          style={{ justifyContent: "center" }}
          width="100px"
          background="rgba(150, 150, 150, 0.15686275)"
        >
          NOT STARTED
        </Box>
        <Box
          borderRight
          borderBottom
          style={{ justifyContent: "center" }}
          width="100px"
          background="rgba(150, 150, 150, 0.15686275)"
        >
          IN PROGRESS
        </Box>
        <Box
          borderBottom
          width="94px"
          style={{ justifyContent: "center" }}
          background="rgba(150, 150, 150, 0.15686275)"
        >
          <div>
            <div style={{ marginTop: "4px", lineHeight: "18px" }}>
              PROFICIENT
            </div>
            <div style={{ lineHeight: "18px" }}>OR ABOVE</div>
          </div>
        </Box>
      </div>
      <div className={css.students}>{rows}</div>
    </Fragment>
  );

  return (
    <div className={css.root}>
      {selected ? (
        <div className={css.back}>
          <BackArrow onClick={() => setSelected(false)} />
        </div>
      ) : null}
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "classactivity_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>
        {selected ? (
          <Fragment>
            <div style={{ width: "10px" }} />
            <Button
              width="155px"
              background="#309acf"
              onMouseEnter={(ev) =>
                rolloverTipBegin(ev, "classactivity_changeselectionsbutton")
              }
              onMouseLeave={rolloverTipEnd}
            >
              Change Selections
            </Button>
          </Fragment>
        ) : null}
      </div>
      <div className={css.details}>
        <div>
          <Bold>Total Students : </Bold>10
        </div>
        {selected ? (
          <Fragment>
            <div>
              <Bold>Skill: {selected.standard} </Bold>
            </div>
            <div>
              <Bold>Assignment: {selected.assignment}</Bold>
            </div>
          </Fragment>
        ) : null}
      </div>

      {contents}
    </div>
  );
};

export default Skills;
