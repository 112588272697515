import { useState } from "react";

export const useSelectLesson = () => {
  const [selectedLessons, setSelectedLessons] = useState([]);

  const toggleLesson = (id) => (event) => {
    if (event.target.checked) {
      setSelectedLessons((l) => [...l, id]);
    } else {
      setSelectedLessons((l) => l.filter((i) => i !== id));
    }
  };

  const toggleLessons = (ids) => (event) => {
    ids.forEach((id) => toggleLesson(id)(event));
  };

  const resetLessonSelection = () => setSelectedLessons([]);

  return {
    toggleLesson,
    toggleLessons,
    selectedLessons,
    resetLessonSelection,
    selectedCount: selectedLessons.length,
  };
};
