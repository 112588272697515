import React from "react";
import css from "./CreateAssignmentsBar.module.css";

import Link from "../Link";

const CreateAssignmentsBar = (props) => {
  const classes = [css.root];

  if (props.dark) classes.push(css.dark);

  if (props.shadow) classes.push(css.shadow);

  const mouseDownHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div onMouseDown={mouseDownHandler} className={classes.join(" ")}>

      <svg style={{ position: 'relative', left: 7, top: 2 }} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.41912 6L9.82013 9.49085C10.1984 9.87916 10.1984 10.4982 9.82013 10.8865L9.45841 11.2578C9.06328 11.6634 8.41053 11.6602 8.01927 11.2509L3.6605 6.69098C3.29099 6.30442 3.29099 5.69558 3.6605 5.30902L8.01927 0.749066C8.41053 0.339751 9.06327 0.336645 9.45841 0.742218L9.82013 1.11349C10.1984 1.5018 10.1984 2.12084 9.82013 2.50915L6.41912 6Z" fill="#FBFCFD" />
      </svg>

      <div className={css.link}>
        <Link
          color="white"
          highlightColor="#fff"
          underline={false}
          onMouseEnter={(ev) => {
            if (props.onMouseEnter) props.onMouseEnter(ev);
          }}
          onMouseLeave={(ev) => {
            if (props.onMouseLeave) props.onMouseLeave(ev);
          }}
        >
          {props.children}
        </Link>
      </div>
    </div>
  );
};

export default CreateAssignmentsBar;
