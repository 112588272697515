import React from "react";
import css from "./Activity.module.css";

import Button from "../../Button";

import data from "../../../../../data/studentInsightActivity.json";
import Detail from "./Detail";
import Item from "./Item";

import DropDown from "../../../../../components/DropDown";

import SkillImg from "./assets/img_0_0_1_0_0.png";
import GoalImg from "./assets/img_0_0_1_1_0.png";
import LessonImg from "./assets/img_0_0_2_0_0.png";
import GameImg from "./assets/img_0_0_2_1_0.png";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const Activity = (props) => {
  const studentData = data[props.subject][props.grade];

  console.log(props.subject);
  console.log(props.grade);
  console.log(studentData);

  return (
    <div className={css.root}>
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "skillstatus_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>

        <DropDown
          style={{ marginLeft: "5px" }}
          width="280px"
          selectedIndex={0}
          data={[
            { text: "Overall" },
            { text: "Last Week" },
            { text: "This Week" },
          ]}
          growth
          rolloverTipTitle={rolloverTips["skillstatus_datedropdown"].title}
          rolloverTip={rolloverTips["skillstatus_datedropdown"].text}
        />
      </div>
      <div className={css.split}>
        <div className={css.left}>
          <Detail
            name="Points Earned"
            nameWidth="105px"
            valueWidth="50px"
            value={studentData.overall.pointsEarned}
          />
          <Detail
            name="Gems Collected"
            nameWidth="105px"
            valueWidth="50px"
            value={studentData.overall.gems}
          />
        </div>
        <div className={css.right}>
          <Detail
            name="Time"
            nameWidth="80px"
            valueWidth="100px"
            value={studentData.overall.time}
          />
          <Detail
            name="Logins"
            nameWidth="80px"
            valueWidth="100px"
            value={studentData.overall.logins}
          />
          <Detail
            name="Last Login"
            nameWidth="80px"
            valueWidth="100px"
            value={studentData.overall.lastLogin}
          />
        </div>
      </div>
      <div className={css.row}>
        <Item
          name="SKILL QUIZ"
          icon={SkillImg}
          value={studentData.overall.skill}
        />
        <Item
          name="PRACTICE"
          icon={GoalImg}
          value={studentData.overall.goals}
        />
        <Item
          name="LEARN"
          icon={LessonImg}
          value={studentData.overall.lessons}
        />
        <Item name="PLAY" icon={GameImg} value={studentData.overall.games} />
      </div>
    </div>
  );
};

export default Activity;
