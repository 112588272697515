import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import css from "./Contents.module.css";

import studentData from "../../data/students";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import FindContent from "./FindContent";
import TrackAssignments from "./TrackAssignments";
import Growth from "./Growth";
import Rosters from "./Rosters";
import { Reports } from "./Reports";
import { Home } from "./Home/Home";
import { FindContent2 } from "./FindContent2/FindContent2";
import { initialGroups } from "./defaultGroups";

const Contents = (props) => {
  const location = useLocation();

  const [selectedId, subject, grade, params, params2] = location.pathname
    .substring(1)
    .split(/[-/]/);

  const [showResults, setShowResults] = useState(false);
  const [groups, _setGroups] = useState(
    ["ela_1", "ela_3", "ela_7", "math_1", "math_3", "math_7"].reduce(
      (acc, curr) => ({ ...acc, [curr]: initialGroups }),
      {}
    )
  );

  const setGroups = (key) => (groups) =>
    _setGroups((existing) => ({ ...existing, [key]: groups }));

  console.log(`${selectedId}-${subject}-${grade}-${params}`);

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <Switch>
          <Route
            path={[
              "/findcontent/:subject/:grade/:effectiveGrade/:report",
              "/findcontent/:subject/:grade/:effectiveGrade",
              "/findcontent/:subject/:grade",
            ]}
          >
            <FindContent2 groups={groups[`${subject}_${grade}`]} />
          </Route>

          {/* <Route path="/findcontent-:subject-:grade">
            <FindContent
              key={`${subject}_${grade}`}
              params={params}
              params2={params2}
            />
          </Route>

          <Route path="/findcontentresults-:subject-:grade">
            <FindContent
              showResults={true}
              key={`${subject}_${grade}`}
              params={params}
              params2={params2}
            />
          </Route> */}
          <Route path="/reports-:subject-:grade-:reportType">
            <Reports
              key={`${subject}_${grade}`}
              params={params}
              params2={params2}
            />
          </Route>
          <Route path="/reports-:subject-:grade">
            <Reports
              key={`${subject}_${grade}`}
              params={params}
              params2={params2}
            />
          </Route>
          <Route path="/rosters-:subject-:grade">
            <Rosters
              key={`${subject}_${grade}`}
              params={params}
              params2={params2}
              groups={groups[`${subject}_${grade}`]}
              setGroups={setGroups(`${subject}_${grade}`)}
            />
          </Route>

          <Route path="/home-:subject-:grade">
            <Home />
          </Route>
          <Route>
            <Home />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Contents;
