import * as React from "react"

function LearnIcon(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 0a1 1 0 00-1 1v22a1 1 0 001 1h18a1 1 0 001-1V1a1 1 0 00-1-1H3zm1 22V2h16v20H4zM15 5a1 1 0 100 2h2a1 1 0 100-2h-2zm-1 5a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zm-7 3a1 1 0 100 2h10a1 1 0 100-2H7zm-1 5a1 1 0 011-1h10a1 1 0 110 2H7a1 1 0 01-1-1zM7 5a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V6a1 1 0 00-1-1H7zm1 4V7h2v2H8z"
                fill="#131313"
            />
        </svg>
    )
}

export default LearnIcon
