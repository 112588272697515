import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import filters2 from "../filters2.png";
import data from "../../../../data/findcontent-with-blocks.json";
import styled from "@emotion/styled";
import { BackLink } from "./BackLink";
import {
  Container,
  StrandBlock,
  LessonTable,
  Title,
  BackOrOptions,
  Options,
  DetailContainer,
  DetailRow,
  DetailDetails,
  DetailExpand,
  Filters,
  YellowDiv,
} from "../CommonFindContent";
import { PriorityStar } from "../PriorityStar";
import { useToggle } from "../../../../../../common/util/useToggle";
import { ToggleIcon } from "./ToggleIcon";
import { lessonTypes } from "../lesson-types";
import { InfoIcon } from "./InfoIcon";
import { BlockIcon } from "../BlockIcon";
import { StandardsIcon } from "../StandardsIcon";
import { SkillsIcon } from "../SkillsIcon";
import { FilterOptions, useFilterOptions } from "../FilterOptions";
import { gradeToIndex } from "../../../../routing-util";
import { useSelectLesson } from "../use-select-lesson";
import { AssignmentBanner } from "../AssignmentBanner";
import { AboutLessonPopup } from "../AboutLessonPopup";
import { useMoreInfoPopup } from "../use-more-info-popup";
import { LightColors } from "../../../../light-theme";
import { YellowRow } from "../../../../components/ReportTables";
import { uniqBy } from "lodash";

const LessonRow = ({ lesson, toggleLessons, selectedLessons }) => {
  const { popupData, openPopupHandler, closePopupHandler } =
    useMoreInfoPopup(lesson);

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          onChange={toggleLessons([lesson.id])}
          checked={selectedLessons.indexOf(lesson.id) !== -1}
        />
      </td>
      <td>{lesson.title}</td>
      <td>{lesson.star && <PriorityStar />}</td>
      <td>{lessonTypes[lesson.type]}</td>
      <td>View Report</td>
      <td>
        <InfoIcon onClick={openPopupHandler} />
        {popupData && (
          <AboutLessonPopup onClose={closePopupHandler} lesson={popupData} />
        )}
      </td>
    </tr>
  );
};

const StrandLessons = ({
  lessons,
  toggleLessons,
  selectedLessons,
  yellowBar,
}) => {
  const uniqueLessons = uniqBy(lessons, "id");
  return (
    <LessonTable>
      <tbody>
        {yellowBar && (
          <YellowRow>
            <td colSpan={6} />
          </YellowRow>
        )}
        {uniqueLessons
          .filter((lesson) => {
            return lesson.type === "cms_lessons";
          })
          .map((lesson, index) => (
            <LessonRow
              selectedLessons={selectedLessons}
              key={index}
              lesson={lesson}
              toggleLessons={toggleLessons}
            />
          ))}
      </tbody>
    </LessonTable>
  );
};

const getAllLessons = (detail) => {
  if (detail.sub) {
    return detail.sub.reduce((acc, curr) => {
      return [...curr.practice, ...acc];
    }, detail.practice);
  }
  return detail.practice;
};

const sortByAssignmentOrder = (a, b) => {
  const aIndex = parseInt(a.assignmentOrder) || 0;
  const bIndex = parseInt(b.assignmentOrder) || 0;

  return aIndex - bIndex;
};

const StrandDetail = ({
  detail,
  renderDetailName,
  expanding,
  headerBg,
  selectedLessons,
  filter,
  toggleLessons,
  yellowBar,
}) => {
  const [open, toggleOpen] = useToggle(!expanding);
  const allLessons = getAllLessons(detail);

  const lessons = allLessons.filter(filter).sort(sortByAssignmentOrder);
  if (!lessons || lessons.length === 0) return null;

  return (
    <DetailContainer backgroundColor={headerBg} leftBorder={false}>
      <DetailRow>
        <DetailDetails onClick={expanding ? toggleOpen : undefined}>
          {renderDetailName({ detail, selectedLessons, toggleLessons })}
        </DetailDetails>
        {expanding && (
          <DetailExpand onClick={toggleOpen}>
            <ToggleIcon open={open} />
          </DetailExpand>
        )}
      </DetailRow>

      {open && (
        <StrandLessons
          selectedLessons={selectedLessons}
          toggleLessons={toggleLessons}
          lessons={lessons}
          yellowBar={yellowBar}
        />
      )}
    </DetailContainer>
  );
};

const DetailTypeOption = styled.div(({ active }) => ({
  label: "detail-type-option",
  color: active ? "#000" : LightColors.blue,
  backgroundColor: active ? LightColors.grey2 : "none",
  display: "flex",
  alignItems: "center",
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,
  textAlign: "left",

  paddingLeft: 15,
  paddingRight: 15,
  height: 50,
  borderRadius: 25,
  cursor: "pointer",
}));

export const StrandDetails = ({
  classGrade,
  grade,
  label,
  mapToDetail,
  expanding = true,
  headerBg = "#e9fcff",
  renderDetailName,
  strandType,
  groups,
  languageIndex,
  yellowBar,
}) => {
  const { strandId, subject, substandard } = useParams();
  const { toggleLessons, selectedLessons, resetLessonSelection } =
    useSelectLesson();

  let strand = data[subject][gradeToIndex(grade)].strand.find(
    (s) => s.id === strandId
  );

  if (substandard) {
    const hasChildStandard = substandard.includes(":");

    if (hasChildStandard) {
      const [substandardPart, childStandardPart] = substandard.split(":");
      const sub = strand.standard
        .filter((s) => s.category === substandardPart)
        .map((s) => s.sub)
        .flat();
      
      strand = {
        ...strand,
        standard: sub.filter((s) => s.category === childStandardPart),
      };
    } else {
      strand = {
        ...strand,
        standard: strand.standard.filter((s) => s.category === substandard),
      };
    }
  }
  const history = useHistory();

  const filterOptions = useFilterOptions(
    languageIndex,
    gradeToIndex(grade),
    subject
  );

  const openOption = (detailType) => () =>
    history.push(
      `/findcontent/${subject}/${classGrade}/${grade}/strand/${strandId}/${detailType}`
    );

  return (
    <Container>
      <BackOrOptions>
        <BackLink
          destination={`/findcontent/${subject}/${classGrade}/${grade}/strand`}
          label={`All ${label.trim()}s`}
        />
        <Options>
          <DetailTypeOption
            active={strandType === "block"}
            onClick={openOption("blocks")}
          >
            <BlockIcon
              color={strandType === "block" ? "#000" : LightColors.blue}
            />{" "}
            Blocks
          </DetailTypeOption>
          <DetailTypeOption
            active={strandType === "standard"}
            onClick={openOption("standards")}
          >
            <StandardsIcon
              color={strandType === "standard" ? "#000" : LightColors.blue}
            />{" "}
            Standards
          </DetailTypeOption>
          <DetailTypeOption
            active={strandType === "skill"}
            onClick={openOption("skills")}
          >
            <SkillsIcon
              color={strandType === "skill" ? "#000" : LightColors.blue}
            />{" "}
            Skills
          </DetailTypeOption>
        </Options>
      </BackOrOptions>

      <Title>{strand.name}</Title>

      {!!substandard || (
        <Filters>
          <FilterOptions {...filterOptions} />
          <img src={filters2} />
        </Filters>
      )}

      {mapToDetail(strand).map((detail, index) => (
        <StrandDetail
          filter={filterOptions.filter}
          headerBg={LightColors.white}
          key={index}
          expanding={expanding && !substandard}
          selectedLessons={selectedLessons}
          detail={detail}
          toggleLessons={toggleLessons}
          renderDetailName={renderDetailName}
          yellowBar={yellowBar}
        />
      ))}
      <AssignmentBanner
        groups={groups}
        selectedLessons={selectedLessons}
        resetLessonSelection={resetLessonSelection}
      />
    </Container>
  );
};
