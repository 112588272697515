import React from "react";
import CheckBox from "../../../components/CheckBox";
import styled from "@emotion/styled";
import { Header, HeaderRow as HR, Cell, CompactHeaderRowNoBorder, HeaderRowNoBorder, SubHeader, HeaderCenterAlign } from "../../../components/ReportTables";


export const HeaderRow = (props) => {
  return (
    <thead>
      <CompactHeaderRowNoBorder>

        <Header rowSpan={2} colSpan={2} />

        <Header rowSpan={2} width={20}>
          <CheckBox selected={props.selected} onClick={props.onSelect} />
        </Header>

        <Header rowSpan={2} width={310}>ASSIGNMENT NAME & TYPE</Header>
        <Header rowSpan={2} width={110}>NUMBER OF STUDENTS</Header>
        <HeaderCenterAlign colSpan={3}>ASSIGNMENT STATUS</HeaderCenterAlign>
        <Header rowSpan={2} colSpan={2}></Header>
      </CompactHeaderRowNoBorder>
      <CompactHeaderRowNoBorder>
        <SubHeader style={{ width: 100 }}>Not Started</SubHeader>
        <SubHeader style={{ width: 100 }}>In-Progress</SubHeader>
        <SubHeader style={{ width: 100 }}>Completed</SubHeader>

      </CompactHeaderRowNoBorder>
    </thead>
  );
};
