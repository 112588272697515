import React from "react";
import { LightColors } from "../../../../light-theme";

export const InfoIcon = ({ onClick, ...props }) => (
  <svg
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="10" fill={LightColors.blue} />
    <path
      d="M11.0249 2.89223C11.4156 2.89223 11.7432 3.02769 12.0078 3.2986C12.2787 3.56951 12.4142 3.89713 12.4142 4.28145C12.4142 4.66577 12.2787 4.99338 12.0078 5.26429C11.7369 5.53521 11.4093 5.67066 11.0249 5.67066C10.6469 5.67066 10.3225 5.53521 10.0515 5.26429C9.78063 4.99338 9.64517 4.66577 9.64517 4.28145C9.64517 3.89713 9.77748 3.56951 10.0421 3.2986C10.313 3.02769 10.6406 2.89223 11.0249 2.89223ZM11.6109 7.23944L9.67353 13.9587C9.56642 14.343 9.51287 14.595 9.51287 14.7147C9.51287 14.784 9.54122 14.8502 9.59792 14.9132C9.65462 14.9699 9.71448 14.9983 9.77748 14.9983C9.88459 14.9983 9.99169 14.951 10.0988 14.8565C10.3823 14.6234 10.7225 14.2013 11.1194 13.5901L11.4408 13.7791C10.4894 15.4361 9.47822 16.2646 8.40717 16.2646C7.99765 16.2646 7.67003 16.1512 7.42432 15.9244C7.18491 15.6913 7.0652 15.3983 7.0652 15.0455C7.0652 14.8124 7.11876 14.5163 7.22586 14.1572L8.53947 9.63985C8.66548 9.20513 8.72848 8.87751 8.72848 8.657C8.72848 8.5184 8.66863 8.39554 8.54892 8.28844C8.42922 8.18133 8.26541 8.12778 8.0575 8.12778C7.96299 8.12778 7.84959 8.13093 7.71728 8.13723L7.84014 7.75921L11.0438 7.23944H11.6109Z"
      fill="white"
    />
  </svg>
);
