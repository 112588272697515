import React from "react";
import Link from "../../../../../components/Link";
import { Back } from "../Back";
// import css from "./LessonPage.module.css";

import Button from "../Button";

import data from "../../../../../data/trackAssignmentsLesson.json";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

import up from "../img_0_0_0_5_0_0.png";
import check from "./completed.png";
import x from "./notCompleted.png";
import { BlueButton } from "../../../../../components/BlueButton";
import styled from "@emotion/styled";
import {
  Cell,
  HeaderRow,
  Row,
  SubHeaderRow,
  Table,
  YellowRow,
} from "../../../../../components/ReportTables";
import { LightColors } from "../../../../../light-theme";

import GreenCheckCircle from "./GreenCheckCircle";
import GreyXCircle from "./GreyXCircle";
import BlueUpArrowCircle from "./BlueUpArrowCircle";

const Root = styled.div({
  label: "Root",
  color: "#282828",
  padding: 50,
  paddingTop: 20,
});

const Title = styled.div({
  label: "Title",
  display: "flex",
  justifyContent: "space-between",
  marginTop: 20,

  color: "#000",

  //styleName: UI Text/Big 2 - Open Sans Bold 22pt;
  fontFamily: 'Open Sans',
  fontSize: 22,
  fontWeight: 700,
  lineHeight: "30px",
  letterSpacing: '0em',
  textAlign: 'left',
  alignItems: 'center',
  marginBottom: 25

});

const Buttons = styled.div({
  label: "Buttons",
  display: "flex",
  "> div": {
    marginLeft: 20,
  },
});

const AssignmentOptions = styled.div({
  label: "Assignment",
  display: "flex",
  fontSize: 16,
  fontWeight: "bold",
  justifyContent: "space-between",
  gap: 20
});

const Pair = styled.div({
  label: "Pair",
  display: "flex",
  marginRight: 10,
  gap: 15,


  fontFamily: 'Open Sans',
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: '0em',
  textAlign: 'left',
  flexGrow: 0,
  flexShrink: 1


});

const Spacer = styled.div({
  label: "Spacer",
  flexGrow: 2
});

const Footer = styled.div({
  label: "Footer",
  display: "flex",
  fontSize: 13,
  fontWeight: "bold",
  "> span": {
    marginLeft: 10,
    marginRight: 20,
    marginTop: 2,
  },
});

const NameCell = styled(Cell)({ width: 250 });
const StatusCell = styled.td({
  border: `1px solid ${LightColors.grey1}`,
  textAlign: "center",
  width: 100,
  "> img": {
    position: "relative",
    top: 2,
  },
});
const ScoreCell = styled.td({
  border: `1px solid ${LightColors.grey1}`,
  textAlign: "center",
  width: 100,
});
const PathCell = styled.td({
  //border: `1px solid ${LightColors.grey1}`,,
  textAlign: "center",
  width: 100,
});


const BaseHeader = styled.th({
  fontFamily: 'Open Sans',
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "16px",
  letterSpacing: '0em',
  textAlign: 'center',
  padding: 20,
  border: `1px solid ${LightColors.grey1}`

});

const NameHeader = styled(BaseHeader)({
  width: 250,

  //styleName: UI Text/Medium 1 Title - Open Sans Bold 16pt;

});
const AssignmentHeader = styled(BaseHeader)({ width: 300 });
const NamePlaceholder = styled.th({ border: `1px solid ${LightColors.grey1}`, width: 300 });
const StatusHeader = styled.th({
  border: `1px solid ${LightColors.grey1}`,
  width: 100,
  padding: 10,
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: '0em',
  textAlign: 'center',
});
const ScoreHeader = styled.th({
  border: `1px solid ${LightColors.grey1}`,
  padding: 10,
  width: 100,
  //styleName: UI Text/Small 2 Title - Open Sans Bold 14pt;
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: '0em',
  textAlign: 'center',

});
const PathHeader = styled.th({ border: `1px solid ${LightColors.grey1}`, width: 100 });

const status = {
  0: x,
  1: x /* TODO: Didn't have a new icon for this one, reusing X */,
  2: check,
};

const LessonPage = (props) => {
  const skillData = data[props.subject.toLowerCase()][String(props.grade)];

  const rows = skillData.map((x, index) => {
    return (
      <Row key={index}>
        <NameCell>
          <Link
            underline={false}
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "TA_lessonscreen_studentnames")
            }
            onMouseLeave={rolloverTipEnd}
          >
            {x.name}
          </Link>
        </NameCell>
        <StatusCell>
          {/* <img width="25px" src={status[x.status]} />       */}
          {x.status === 0 && <BlueUpArrowCircle />}
          {x.status === 1 && <GreyXCircle />}
          {x.status === 2 && <GreenCheckCircle />}
        </StatusCell>
        <ScoreCell
          onMouseEnter={(ev) => rolloverTipBegin(ev, "TA_lessonscreen_score")}
          onMouseLeave={rolloverTipEnd}
        >
          {x.score}
        </ScoreCell>

        {/* <PathCell>{x.path}</PathCell> */}
      </Row>
    );
  });

  const backHandler = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <Root>
      <Back onClick={backHandler} />
      <Title>
        <div>{props.title}</div>
        <Buttons>
          <BlueButton
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "TA_lessonsscreen_previewbutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            PREVIEW
          </BlueButton>
          <BlueButton
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "TA_lessonsscreen_printbutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            EXPORT/PRINT
          </BlueButton>
        </Buttons>
      </Title>
      <AssignmentOptions>
        <Pair>
          <div>Assigment 1 : Start:</div>

          <Link
            underline={false}
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "TA_lessonscreen_startdate")
            }
            onMouseLeave={rolloverTipEnd}
          >
            08/03/2022
          </Link>
        </Pair>

        <Pair>
          <div>Due:</div>
          <Link
            underline={false}
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "TA_lessonscreen_duedate")
            }
            onMouseLeave={rolloverTipEnd}
          >
            08/10/2022
          </Link>
        </Pair>

        <Spacer />

        <Link
          size="14px"
          bold
          underline={false}
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "TA_lessonsscreen_changestudentsbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Update Assigned Students
        </Link>
        <Link
          size="14px"
          bold
          underline={false}
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "TA_lessonsscreen_changestudentsbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          View Class Performance
        </Link>
      </AssignmentOptions>
      <Table>
        <HeaderRow>
          <NameHeader>STUDENT NAME</NameHeader>
          <AssignmentHeader colSpan={3}>Assignment 1</AssignmentHeader>
        </HeaderRow>
        <SubHeaderRow>
          <NamePlaceholder />
          <StatusHeader>STATUS</StatusHeader>
          <ScoreHeader>SCORE</ScoreHeader>
          {/* <PathHeader>PATH</PathHeader> */}
        </SubHeaderRow>
        <YellowRow><td colSpan={3} /></YellowRow>

        <tbody>{rows}</tbody>
      </Table>

      <Footer>
        <GreenCheckCircle />
        <span>Completed</span>
        <BlueUpArrowCircle />
        <span>Making Progress</span>
        <GreyXCircle />
        <span>Not Started</span>
      </Footer>
    </Root>
  );
};


export default LessonPage;
