import React from "react";

export const StandardsIcon = ({ color = "#056B90" }) => (
  <svg
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5392 1.86282C18.7207 1.68589 19.0112 1.68957 19.1881 1.87103L22.7768 5.55172C22.9537 5.73318 22.9501 6.0237 22.7686 6.20063L12.201 16.5041L7.23296 17.1172L7.97163 12.1663L18.5392 1.86282Z"
      stroke={color}
      strokeWidth="0.917774"
    />
    <path
      d="M18.2189 1.53426C18.5818 1.18041 19.1628 1.18776 19.5167 1.55068L23.1054 5.23137C23.4592 5.59429 23.4519 6.17534 23.0889 6.52919L21.7747 7.81059L16.9046 2.81565L18.2189 1.53426Z"
      fill={color}
    />
    <path
      d="M16.4121 3.29614L21.2822 8.29108L20.9536 8.61143L16.0835 3.61649L16.4121 3.29614Z"
      fill={color}
    />
    <path
      d="M18.1436 5.72974L10.2581 13.4181L9.69613 12.8418L17.5816 5.1534L18.1436 5.72974Z"
      fill={color}
    />
    <path
      d="M19.4551 7.07458L11.5696 14.763L11.0077 14.1866L18.8931 6.49825L19.4551 7.07458Z"
      fill={color}
    />
    <path
      d="M9.32348 17.2123L7.19043 15.0245L6.85386 17.4864L9.32348 17.2123Z"
      fill={color}
    />
    <path
      d="M18.0848 10.7341V18.4469C18.0848 19.0345 17.6085 19.5108 17.021 19.5108H5.31872C4.73118 19.5108 4.25488 19.0345 4.25488 18.4469V4.617C4.25488 4.02946 4.73118 3.55316 5.31872 3.55316H17.021"
      stroke={color}
      strokeWidth="1.27661"
    />
  </svg>
);
