import React, { useState } from "react";
import Link from "../../../components/Link";
import HeaderRow from "./HeaderRow";
import EntryRow from "./EntryRow";
import Add from "./Add";
import css from "./Rosters.module.css";

import studentData from "../../../data/students";
import StudentActivityPopup from "../Popup/StudentActivityPopup";
import ClassActivityPopup from "../Popup/ClassActivityPopup";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";
import DropDown from "../../../components/DropDown";
import { GroupDialog } from "./GroupDialog/GroupDialog";
import { useRouteMatch } from "react-router-dom";

const Rosters = (props) => {
  const { grade, subject } = useRouteMatch().params;
  const [support, setSupport] = useState(false);
  const [vocabulary, setVocabulary] = useState(false);
  // const [groups, setGroups] = useState(initialGroups);
  const { groups, setGroups } = props;
  const [currentGroup, setCurrentGroup] = useState(groups[0]);
  const [editGroup, setEditGroup] = useState(null);

  const [showViewClassActivity, setShowViewClassActivity] = useState(false);

  const onDeleteGroup = () => {
    setGroups(groups.filter((g) => g !== editGroup));
    setEditGroup(null);
    setCurrentGroup(groups[0]);
  };

  const addGroup = () => {
    setEditGroup({ name: "", students: [] });
  };

  const editCurrentGroup = () => setEditGroup(currentGroup);

  const onGroupChanged = (group) => {
    if (!editGroup) return;

    if (groups.indexOf(editGroup) === -1) {
      // Add the new one in
      setGroups([...groups, group]);
    } else {
      // Replace the edited group
      setGroups(groups.map((g) => (g === editGroup ? group : g)));
    }
    setCurrentGroup(group);
    setEditGroup(null);
  };

  const { students } = currentGroup;

  const selectGroup = (index) => setCurrentGroup(groups[index]);

  const [defaultClassActivityIndex, setDefaultClassActivityIndex] =
    useState(-1);
  const [selectedStudent, setSelectedStudent] = useState(-1);
  const [defaultStudentActivityIndex, setDefaultStudentActivityIndex] =
    useState(-1);

  const supportHandler = () => {
    setSupport(!support);
  };

  const vocabularyHandler = () => {
    setVocabulary(!vocabulary);
  };

  const viewClassActivityHandler = () => {
    setShowViewClassActivity(true);
    setDefaultClassActivityIndex(3);
    setDefaultStudentActivityIndex(0);
  };
  const closeClassPopupHandler = () => {
    setShowViewClassActivity(false);
  };
  const studentSelectedHandler = (index) => {
    if (index > 0) return;
    setShowViewClassActivity(false);
    setDefaultStudentActivityIndex(0);
    setSelectedStudent(index);
  };
  const closeStudentPopupHandler = () => {
    setShowViewClassActivity(false);
    setSelectedStudent(-1);
  };

  let popup;

  if (selectedStudent > -1) {
    const student = students[selectedStudent];
    popup = (
      <StudentActivityPopup
        index={defaultStudentActivityIndex}
        onClose={closeStudentPopupHandler}
        title={`${student.lastName}, ${student.firstName}`}
        data={student}
        subject={subject}
        grade={grade}
      />
    );
  } else if (showViewClassActivity) {
    popup = (
      <ClassActivityPopup
        index={props.defaultClassActivityIndex}
        onStudentSelected={studentSelectedHandler}
        onClose={closeClassPopupHandler}
        title={`Grade ${grade} - ${subject == "ela" ? "ELA" : "Math"}`}
        subject={subject}
        grade={grade}
      />
    );
  }

  const viewReportHandler = (p) => {
    if (p.index != 0) return;

    studentSelectedHandler(0);
  };

  const entries = students.map((x, index) => (
    <EntryRow
      key={index}
      index={index}
      name={x.name}
      id={x.id}
      onViewReport={viewReportHandler}
    />
  ));

  const name = subject == "ela" ? "ELA" : "Math";
  return (
    <div className={css.root}>
      <div className={css.title}>{`Grade ${grade} - ${name} : Roster`}</div>
      <div className={css.header}>
        {/* <Add
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "Home_roster_addstudentbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Add Student
        </Add> */}
        <Add onClick={addGroup}>Add New Group</Add>
        {currentGroup.readonly || (
          <span>
            |{" "}
            <Link onClick={editCurrentGroup} underline={false}>
              <b>Edit Group</b>
            </Link>
          </span>
        )}

        <div className={css.viewGroup}>View Group:</div>
        <div className={css.groupDropdownContainer}>
          <DropDown
            width="200px"
            selectedIndex={groups.indexOf(currentGroup)}
            selectIndex={selectGroup}
            data={groups.map((g) => ({ text: g.name, object: g }))}
            defaultText="Choose Program"
            content
          />
        </div>
      </div>
      <div className={css.print}>
        <div className={css.icon} />
        <div className={css.text}>Print Class' Login Details: </div>
        <Link
          style={{ fontSize: "16px", marginTop: "-2px" }}
          underline={false}
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "Home_roster_printtogetherbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          All together
        </Link>
        <div className={css.divider}>|</div>
        <Link
          style={{ fontSize: "16px", marginTop: "-2px" }}
          underline={false}
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "Home_roster_printseparatebutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Separate page for each student
        </Link>
      </div>
      <div className={css.content}>
        <HeaderRow
          support={support}
          vocabulary={vocabulary}
          onSupport={supportHandler}
          onVocabulary={vocabularyHandler}
        />
        {entries}
      </div>

      {popup}

      {
        //onGroupChanged,
        //onDelete

        editGroup && (
          <GroupDialog
            onDelete={onDeleteGroup}
            group={editGroup}
            allStudents={studentData}
            onGroupChanged={onGroupChanged}
            onCanceled={() => setEditGroup(null)}
          />
        )
      }
    </div>
  );
};

export default Rosters;
