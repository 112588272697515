import React from "react";
import css from "./Content.module.css";

import { components } from "@indieasy.software/indieasy-components";
const { CircleChart } = components;

const Content = (props) => {
  const legend = props.data.map((x, index) => {
    return (
      <div key={index} className={css.row}>
        <img className={css.icon} src={x.icon} />
        <div className={css.text}>{x.name}</div>
        <div className={css.line}>...................</div>
        <div className={css.value}>{x.value}</div>
      </div>
    );
  });

  return (
    <div className={css.root}>
      <div className={css.title}>
        <span>{props.title}</span>
      </div>
      <div className={css.chart}>
        <CircleChart
          dropshadow
          style={{
            marginLeft: props.marginLeft || "10px",
            marginTop: props.marginTop || "-45px",
          }}
          scale={props.scale || "0.7"}
          viewbox={props.viewbox}
          cx={props.cx || "80"}
          cy={props.cy || "90"}
          strokeWidth={props.strokeWidth || "12px"}
          base={{ stroke: "#BCDFE8" }}
          data={props.circleData}
        />
        {props.children}
      </div>
      <div className={css.legend}>{legend}</div>
    </div>
  );
};

export default Content;
