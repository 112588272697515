import studentData from "../../data/students";

export const initialGroups = [
  {
    name: "Select Group",
    students: studentData,
    readonly: true,
  },
  {
    name: "Group #1",
    students: studentData.slice(2, 6),
  },
];
