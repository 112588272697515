import { useCallback, useState } from "react";

export const useMoreInfoPopup = (lesson) => {
  const [popupData, setPopupData] = useState(null);
  const openPopupHandler = useCallback(() => {
    console.info("openPopupHandler");
    setPopupData(lesson);
  }, [lesson]);

  const closePopupHandler = useCallback(() => setPopupData(null), []);
  return { popupData, openPopupHandler, closePopupHandler };
};
