import React, { Fragment, useState } from "react";
import css from "./Leaderboard.module.css";

import Button from "./Button";

import GemEntry from "./GemEntry";

import studentData from "../../../../../data/students.json";

import PointEntry from "./PointEntry";
import { toolTips } from "@indieasy.software/indieasy-engine";
import rolloverTips from "../../../../../data/rollovertips";
import styled from "@emotion/styled";
import { LightColors } from "../../../../../light-theme";
import { InfoIcon } from "../../../FindContent2/by-strand/InfoIcon";
import { LinkButton } from "../../../../../components/BlueButton";
import { LeaderBoardInfo } from "./LeaderBoardInfo";

const ReportLink = styled.div({
  textAlign: "right",
  borderBottom: `1px solid ${LightColors.grey1}`,
  flexGrow: 1,
});

const TabButton = styled.button(({ selected }) => ({
  backgroundColor: selected ? LightColors.white : LightColors.grey2,
  color: LightColors.black,
  outline: "none",
  border: `1px solid ${LightColors.grey1}`,
  borderBottom: selected ? "none" : `1px solid ${LightColors.grey1}`,
  padding: 10,
  fontWeight: 700,
  fontSize: 16,
  "&:last-child": {
    borderLeft: "none",
  },
  "&:first-child": {
    borderLeft: `1px solid ${LightColors.grey1}`,
  },
}));

const TabContainer = styled.div({
  flexGrow: 0,
});

const Tabs = ({ selected, setTab, grade }) => {
  return (
    <TabContainer>
      {grade > 1 && (
        <TabButton selected={selected == 0} onClick={() => setTab(0)}>
          Gems
        </TabButton>
      )}
      <TabButton selected={selected == 1} onClick={() => setTab(1)}>
        Points
      </TabButton>
    </TabContainer>
  );
};

const LinkDivider = styled.div({
  display: "inline-block",
  verticalAlign: "middle",
  marginLeft: 5,
  marginRight: 5,
  marginTop: 3,
  borderLeft: `1px solid ${LightColors.grey2}`,
});

const Link = styled.a(({ selected }) => ({
  color: selected ? LightColors.black : LightColors.blue,

  verticalAlign: "middle",
  marginLeft: 5,
  marginRight: 5,
  display: "inline-block",
  fontSize: 16,
  fontFamily: "Open Sans",
  fontWeight: selected ? 700 : 400,
}));

const GemContainer = styled.div({
  border: `1px solid ${LightColors.grey1}`,
  borderTop: "none",
});

const GridDivider = styled.hr({
  border: "none",
  borderTop: `1px solid ${LightColors.grey1}`,
});

const Leaderboard = (props) => {
  const hasGems = props.grade > 2;
  const [tab, setTab] = useState(hasGems ? 0 : 1);
  const [dateIndex, setDateIndex] = useState(0);

  const dateHandler = (index) => {
    if (dateIndex != index) setDateIndex(index);
  };

  let entries;

  let dateType;

  switch (dateIndex) {
    case 0:
      dateType = "thisWeek";
      break;
    case 1:
      dateType = "lastWeek";
      break;
    case 2:
      dateType = "overall";
      break;
  }

  if (tab == 0) {
    const diamondData = studentData
      .filter((x) => x.score.diamond[dateType] > 0)
      .map((x) => {
        return { student: x.name, value: x.score.diamond[dateType] };
      });
    const saphireData = studentData
      .filter((x) => x.score.saphire[dateType] > 0)
      .map((x) => {
        return { student: x.name, value: x.score.saphire[dateType] };
      });
    const rubyData = studentData
      .filter((x) => x.score.ruby[dateType] > 0)
      .map((x) => {
        return { student: x.name, value: x.score.ruby[dateType] };
      });
    const emeraldData = studentData
      .filter((x) => x.score.emerald[dateType] > 0)
      .map((x) => {
        return { student: x.name, value: x.score.emerald[dateType] };
      });

    entries = (
      <Fragment>
        <GemEntry type="Diamond" data={diamondData} />
        <GridDivider />
        <GemEntry type="Sapphire" data={saphireData} />
        <GridDivider />
        <GemEntry type="Ruby" data={rubyData} />
        <GridDivider />
        <GemEntry type="Emerald" data={emeraldData} />
      </Fragment>
    );
  } else {
    const sortedData = [...studentData];
    sortedData.sort((x, y) => {
      const xScore = x.score.points[dateType];
      const yScore = y.score.points[dateType];

      if (xScore < yScore) return 1;
      else if (xScore > yScore) return -1;
      else return 0;
    });

    const pointsData = sortedData
      .filter((x) => x.score.points[dateType] > 0)
      .map((x) => {
        return { student: x.name, value: x.score.points[dateType] };
      });

    entries = pointsData.map((x, index) => (
      <PointEntry key={index} index={index} data={x} />
    ));
  }

  const createToolTip = (event, title, text) => {
    const rect = event.currentTarget.getBoundingClientRect();
    toolTips.manager.show(
      toolTips.createToolTip(
        rect.left + rect.width / 2,
        rect.top + rect.height / 2 + 10,
        {
          style: { width: "300px" },
          title: title,
          text: text,
        }
      )
    );
  };

  return (
    <div className={css.root}>
      <LeaderBoardInfo />

      <div className={css.header}>
        <Tabs selected={tab} setTab={setTab} grade={props.grade} />
        <ReportLink>
          <Link
            onMouseEnter={(ev) =>
              createToolTip(
                ev,
                rolloverTips[
                  tab == 0
                    ? "Home_Leaderboardgems_viewgemsreport"
                    : "Home_Leaderboardgems_viewpointsreport"
                ].title,
                rolloverTips[
                  tab == 0
                    ? "Home_Leaderboardgems_viewgemsreport"
                    : "Home_Leaderboardgems_viewpointsreport"
                ].text
              )
            }
            onMouseLeave={() => toolTips.manager.hide()}
          >
            {tab == 0 ? "View Gems Report" : "View Points Report"}
          </Link>
        </ReportLink>
      </div>
      <GemContainer>
        <div className={css.dates}>
          <Link
            size="12px"
            index={0}
            selected={dateIndex == 0}
            onClick={() => dateHandler(0)}
          >
            This Week
          </Link>
          <LinkDivider />
          <Link
            size="12px"
            index={1}
            selected={dateIndex == 1}
            onClick={() => dateHandler(1)}
          >
            Last Week
          </Link>
          <LinkDivider />
          <Link
            size="12px"
            index={2}
            selected={dateIndex == 2}
            onClick={() => dateHandler(2)}
          >
            Overall
          </Link>
        </div>
        <GridDivider />
        {entries}
      </GemContainer>
    </div>
  );
};

export default Leaderboard;
