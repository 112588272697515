import React, { Fragment, useState } from "react";
import css from "./GrowthMeasure.module.css";

import DropDown from "../../../../components/DropDown";
import ResultsBar from "../../../../components/ResultsBar";

import Report from "./Report";

import growthData from "../../../../data/growth.json";

const GrowthMeasureReport = (props) => {
  const [subjectIndex, setSubjectIndex] = useState(growthData.hasELA ? 0 : 1);
  const [gradeIndex, setGradeIndex] = useState(0);
  const [classIndex, setClassIndex] = useState(0);
  const [studentIndex, setStudentIndex] = useState(0);
  const [timeIndex, setTimeIndex] = useState(0);

  const [showReport, setShowReport] = useState(false);

  const subject = subjectIndex == 0 ? "ELA" : "Math";

  const gradesData = [{ text: "Grade 3" }, { text: "Grade 7" }];
  const classData = [{ text: `${gradesData[gradeIndex].text} - ${subject}` }];
  const groupData = [{ text: "Choose a Group" }];
  const studentData = [{ text: "All Students" }];
  const timeData = [
    { text: "Entire School Year" },
    { text: "Last 12 Weeks" },
    { text: "Last 9 Weeks" },
    { text: "Last 4.5 Weeks" },
    { text: "Last Week" },
    { text: "Custom" },
  ];

  const reportHandler = () => {
    setShowReport(true);
    //if (props.onReport) props.onReport();
  };

  const prevHandler = () => {
    setShowReport(false);
  };

  const mainContents = (
    <Fragment>
      <div className={css.header}>
        {/*<div className={css.icon}>
        <img src="https://practice.gogetwaggle.com/teacher/assets/images/Growth Measure_CTS Icon_Golden_RGB.png"/>
</div>*/}
        <div className={css.title}>HMH Growth Measure</div>
      </div>
      <div className={css.contents}>
        <div className={css.left}>
          <div className={css.top}>Create Your HMH Growth Measure Report</div>
          <div className={css.row}>
            <div className={css.entry}>
              <div style={{ height: "15px" }} className={css.text}></div>
              <div className={css.discipline}>
                <div
                  onMouseDown={
                    growthData.hasELA ? () => setSubjectIndex(0) : null
                  }
                  className={subjectIndex == 0 ? css.selected : ""}
                >
                  ELA
                </div>
                <div
                  onMouseDown={
                    growthData.hasMath ? () => setSubjectIndex(1) : null
                  }
                  className={subjectIndex == 1 ? css.selected : ""}
                >
                  Math
                </div>
              </div>
            </div>
            <div className={css.entry}>
              <div className={css.text}>GRADE</div>
              <div>
                <DropDown
                  width="200px"
                  selectedIndex={gradeIndex}
                  selectIndex={setGradeIndex}
                  data={gradesData}
                  growth
                  /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
                />
              </div>
            </div>
            <div className={css.entry}>
              <div className={css.text}>CLASS</div>
              <div>
                <DropDown
                  width="200px"
                  selectedIndex={classIndex}
                  selectIndex={setClassIndex}
                  data={classData}
                  growth
                  /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
                />
              </div>
            </div>
          </div>
          <div className={css.row}>
            <div className={css.entry + " " + css.disabled}>
              <div className={css.text}>GROUPS (optional):</div>
              <div>
                <DropDown
                  width="200px"
                  selectedIndex={0}
                  data={groupData}
                  growth
                  /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
                />
              </div>
            </div>
            <div className={css.entry}>
              <div className={css.text}>STUDENT</div>
              <div>
                <DropDown
                  width="200px"
                  selectedIndex={studentIndex}
                  selectIndex={setStudentIndex}
                  data={studentData}
                  growth
                  /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
                />
              </div>
            </div>
          </div>
          <div className={css.row}>
            <div className={css.entry}>
              <div className={css.text}></div>
              <div className={css.button} onMouseDown={reportHandler}>
                Create Reports
              </div>
            </div>
          </div>
        </div>
        <ResultsBar
          bold
          color="rgba(0, 0, 0, 0.3)"
          highlightColor="rgba(0, 0, 0, 0.3)"
          width="35px"
          background="rgba(255,255,255,.8)"
          fontSize="14px"
        >
          <span style={{ fontSize: "14px" }}>Results</span>
        </ResultsBar>
      </div>
    </Fragment>
  );

  const report = (
    <Report
      {...props}
      subject={subject}
      grade={gradeIndex === 0 ? 3 : 7}
      onPrev={prevHandler}
    />
  );

  const contents = showReport ? report : mainContents;

  return <div className={css.root}>{contents}</div>;
};

export default GrowthMeasureReport;
