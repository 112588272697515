import React from "react";
import css from "./ProficiencyTable.module.css";

const ProficiencyTable = (props) => {
  const colors = ["#282828", "#7AB946", "#F89D02", "#1886C7", "#D92128"];

  const names = ["Advanced", "Proficient", "Basic", "Partial", "Minimal"];

  const getThreshold = (value) => {
    if (value >= 90) return 0;
    else if (value >= 70) return 1;
    else if (value >= 55) return 2;
    else if (value >= 35) return 3;
    else return 4;
  };

  const getProficiencySpan = (value) => {
    return (
      <span style={{ fontWeight: "bold", color: colors[getThreshold(value)] }}>
        {names[getThreshold(value)]}
      </span>
    );
  };

  const getTime = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    if (hours == 0) return minutes + "min";
    else return hours + "hr " + minutes + "min";
  };

  const content = props.data.map((x, index) => {
    return (
      <div key={index} className={css.row}>
        <div className={css.content}>{x.name}</div>
        <div className={css.content}>
          <div style={{ width: "100%" }}>
            <span
              style={{ fontWeight: "bold" }}
            >{`${x.correct} / ${x.total} `}</span>
            <span>{`(${(x.correct / x.total) * 100}%)`}</span>
          </div>
        </div>
        <div className={css.content}>
          <div style={{ width: "100%" }}>
            {getProficiencySpan(x.proficiency)}
            <span>{` (${x.proficiency})`}</span>
          </div>
        </div>
        <div style={{ fontWeight: "bold" }} className={css.content}>
          {x.mindset}
        </div>
        <div style={{ fontWeight: "bold" }} className={css.content}>
          {getTime(x.time)}
        </div>
      </div>
    );
  });

  return (
    <div className={css.root} style={props.style}>
      <div className={css.row}>
        <div className={css.header}>{props.name}</div>
        <div className={css.header}>PROFICIENCY</div>
        <div className={css.header}>PRACTICE PROFICIENCY</div>
        <div className={css.header}>MINDSET</div>
        <div className={css.header}>{`TIME ON ${props.type}`}</div>
      </div>
      <div className={css.contents}>{content}</div>
    </div>
  );
};

export default ProficiencyTable;
