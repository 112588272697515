import icon from "./ShoutOut.png"
export const ShoutOutsIcon = ({ fill = "#71767A" }) => (
  <img style={{width:41,height:31}} src={icon} />
  // The svg didn't work from figma, weird.
//   <svg
//   width={40}
//   height={31}
//   viewBox="0 0 40 31"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
  
// >
//   <path
//     d="M17.0844 26.6996L26.7274 29.9415L26.8175 29.9718L26.9116 29.9846C27.0394 30.0019 27.1687 30.0046 27.2969 29.9927C27.6101 29.9816 27.9132 29.8757 28.1657 29.6885C28.3656 29.5435 28.5288 29.3538 28.6425 29.1346C28.7584 28.9111 28.8195 28.6632 28.8207 28.4115V28.4068L28.8207 2.58699L28.8206 2.58237C28.8195 2.33061 28.7584 2.08275 28.6425 1.85927C28.5273 1.63703 28.3611 1.44522 28.1576 1.2995C27.9549 1.15343 27.7206 1.05726 27.4737 1.01886C27.2254 0.980235 26.9715 1.00113 26.7329 1.07981L26.7298 1.08083L2.0836 9.29624L2.0836 9.29623L2.07984 9.29751C1.76466 9.40396 1.49091 9.60677 1.29728 9.87728C1.10397 10.1474 1.00028 10.4713 1.0008 10.8034M17.0844 26.6996L1.0008 10.8034M17.0844 26.6996V28.4068C17.0844 28.8277 16.9172 29.2313 16.6196 29.5289C16.322 29.8265 15.9184 29.9937 15.4975 29.9937H6.1085C5.68765 29.9937 5.28404 29.8265 4.98645 29.5289C4.68887 29.2313 4.52169 28.8277 4.52169 28.4068V22.5152L2.0922 21.7298L2.08117 21.7262L2.08119 21.7261C1.75276 21.6157 1.46948 21.4008 1.27464 21.1143C1.08588 20.8367 0.990168 20.5067 1.0008 20.1717M17.0844 26.6996L1.0008 20.1717M1.0008 10.8034V10.8024H2.00061L1.0008 10.805C1.0008 10.8044 1.0008 10.8039 1.0008 10.8034ZM1.0008 20.1717V20.1914H2.0008L1.00216 20.1393C1.00159 20.1501 1.00114 20.1609 1.0008 20.1717ZM13.9107 25.6364V26.8494H7.69532V23.5675L13.9107 25.6364Z"
//     stroke="black"
//     strokeWidth={2}
//   />
//   <path
//     d="M37.7965 14.9101H34.2756C34.12 14.9101 33.9707 14.972 33.8607 15.082C33.7506 15.192 33.6888 15.3413 33.6888 15.4969C33.6888 15.6526 33.7506 15.8018 33.8607 15.9119C33.9707 16.0219 34.12 16.0838 34.2756 16.0838H37.7965C37.9521 16.0838 38.1014 16.0219 38.2114 15.9119C38.3215 15.8018 38.3833 15.6526 38.3833 15.4969C38.3833 15.3413 38.3215 15.192 38.2114 15.082C38.1014 14.972 37.9521 14.9101 37.7965 14.9101Z"
//     stroke="black"
//     strokeWidth={2}
//   />
//   <path
//     d="M32.6677 8.88352C32.771 8.88245 32.8722 8.85411 32.9611 8.80137L35.7309 7.20523C35.7979 7.1667 35.8567 7.11534 35.9039 7.05409C35.9511 6.99283 35.9858 6.92288 36.0059 6.84823C36.0261 6.77358 36.0314 6.69568 36.0214 6.619C36.0115 6.54231 35.9865 6.46834 35.948 6.40129C35.9095 6.33425 35.8581 6.27545 35.7969 6.22826C35.7356 6.18106 35.6656 6.14639 35.591 6.12623C35.5163 6.10607 35.4384 6.10081 35.3618 6.11075C35.2851 6.12069 35.2111 6.14564 35.1441 6.18417L32.3743 7.78618C32.2612 7.85024 32.1727 7.95 32.1224 8.06983C32.0722 8.18966 32.0631 8.32277 32.0967 8.4483C32.1303 8.57382 32.2045 8.68466 32.3079 8.76342C32.4112 8.84219 32.5378 8.88443 32.6677 8.88352Z"
//     stroke="black"
//     strokeWidth={2}
//   />
//   <path
//     d="M32.3743 23.2077L35.1441 24.8097C35.2111 24.8482 35.2851 24.8732 35.3618 24.8831C35.4385 24.893 35.5164 24.8878 35.591 24.8676C35.6657 24.8475 35.7356 24.8128 35.7969 24.7656C35.8581 24.7184 35.9095 24.6596 35.948 24.5926C35.9866 24.5255 36.0115 24.4515 36.0214 24.3749C36.0314 24.2982 36.0261 24.2203 36.006 24.1456C35.9858 24.071 35.9511 24.001 35.9039 23.9398C35.8567 23.8785 35.7979 23.8272 35.7309 23.7886L32.9611 22.1925C32.8943 22.15 32.8197 22.1215 32.7416 22.1087C32.6635 22.0958 32.5836 22.0989 32.5067 22.1177C32.4298 22.1366 32.3576 22.1708 32.2943 22.2182C32.2309 22.2657 32.1779 22.3255 32.1383 22.394C32.0987 22.4626 32.0733 22.5384 32.0638 22.6169C32.0542 22.6955 32.0607 22.7752 32.0827 22.8512C32.1048 22.9272 32.142 22.998 32.1921 23.0592C32.2422 23.1205 32.3042 23.171 32.3743 23.2077V23.2077Z"
//     stroke="black"
//     strokeWidth={2}
//   />
// </svg>
);
