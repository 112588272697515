export default `IM Lesson Number	Where It Goes		Learning Goal	LG Popup	Skill Boost	Skill Popup	Waggle v.3.x Lesson	Lesson Popup	Games	Games Popup	Priority
	0	RF.K: Reading: Foundational Skills									
	1	RF.K.1: Demonstrate understanding of the organizatuon and basic features of print									
	2	RF.K.1.D: Recognize and name all upper- and lowercase letters of the alphabet.					Lowercase a				
							Lowercase b				
							Lowercase c				
							Lowercase d				
							Lowercase e				
							Lowercase f				
							Lowercase g				
							Lowercase h				
							Lowercase i				
							Lowercase j				
							Lowercase k				
							Lowercase l				
							Lowercase m				
							Lowercase n				
							Lowercase o				
							Lowercase p				
							Lowercase q				
							Lowercase r				
							Lowercase s				
							Lowercase t				
							Lowercase u				
							Lowercase v				
							Lowercase w				
							Lowercase x				
							Lowercase y				
							Lowercase z				
							Uppercase A				
							Uppercase B				
							Uppercase C				
							Uppercase D				
							Uppercase E				
							Uppercase F				
							Uppercase G				
							Uppercase H				
							Uppercase I				
							Uppercase J				
							Uppercase K				
							Uppercase L				
							UppercaseM				
							Uppercase N				
							Uppercase O				
							Uppercase P				
							Uppercase Q				
							Uppercase R				
							Uppercase S				
							Uppercase T				
							Uppercase U				
							Uppercase V				
							Uppercase W				
							Uppercase X				
							Uppercase Y				
							Uppercase Z				
	1	RF.K.2: Demonstrate understanding of spoken words, syllables, and sounds (phonemes).									
	2	RFK.2.A: Recognize and produce rhyming words.					Short E & I Rhymes: Pictorial				
							Short O & U Rhymes: Pictorial				
							Long A Rhymes: Pictorial				
							Long E & I Rhymes: Pictorial				
							Long O & U Rhymes: Pictorial				
							Multisyllabic Rhymes I: Pictorial				
							Multisyllabic Rhymes II: Pictorial				
							Short A & E Rhymes: Spelled Words				
							Short I, O, & U Rhymes: Spelled Words				
							Long A & E Rhymes: Spelled Words				
							Long I, O, & U Rhymes: Spelled Words				
							Differently spelled Rhymes: Spelled Words				
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
	2	RF.K.2.D: Isolate and pronounce the initial, medial vowel, and final sounds (phonemes) in three-phoneme (consonant-vowel-consonant, or CVC) words. 1 (This does not include CVSs ending with /l/, /r/, or /x/.)									
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
											
	1	RF.K.3: Know and apply grade-level phonics and word analysis skills in decoding words.					Digraphs: ch				
							Digraphs: sh				
	2	RF.K.3.B: Associate the long and short sounds with the common spellings (graphemes) for the five major vowels.					Uppercase M				
							Lowercase z				
							Uppercase F				
							Uppercase Q				
							Uppercase J				
							Uppercase N				
							Lowercase l				
							Lowercase s				
							Uppercase S				
							Lowercase x				
							Lowercase k				
							Lowercase p				
							Lowercase t				
							Lowercase d				
							Lowercase w				
							Lowercase g				
							Uppercase H				
							Uppercase P				
							Lowercase m				
							Uppercase Y				
							Uppercase V				
							Lowercase q				
							Lowercase f				
							Lowercase n				
							Uppercase L				
							Uppercase T				
							Uppercase K				
							Lowercase h				
							Uppercase X				
							Lowercase r				
							Lowercase v				
							Lowercase y				
							Lowercase b				
							Uppercase B				
							Uppercase W				
							Lowercase j				
							Uppercase Z				
							Uppercase G				`;
