import React from "react";

import styled from "@emotion/styled";
import backArrow from "./backArrow.png";
import { BackLink } from "../../FindContent2/by-strand/BackLink";
const BackContainer = styled.div({
  display: "flex",
});

const Icon = styled.div({
  marginTop: "-5px",
  marginRight: 5,
  color: "#056b90",
  fontSize: 12,
  background: `url(${backArrow})`,
  noRepeat: "left center",
  cursor: "pointer",
  backgroundSize: 25,
  width: 25,
  height: 25,
  position: "relative",
  top: 4,
});

const Text = styled.div({
  color: "#165B79",
  fontSize: 18,
  fontWeight: "bold",
  cursor: "pointer",
});

export const Back = (props) => {
  const clickHandler = () => {
    if (props.onClick) props.onClick();
  };


  return (
    <BackLink onClick={clickHandler} label="All Assignments" />
    // <BackContainer onMouseDown={clickHandler}>
    //   <Icon />
    //   <Text>Back to All Assignments</Text>
    // </BackContainer>
  );
};
