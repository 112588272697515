import * as React from "react"

function PointsIcon(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.803.43l3.268 6.868 7.19 1.145c.763.114.981 1.03.436 1.602l-5.12 5.38 1.198 7.556c.11.687-.653 1.26-1.307.916l-6.427-3.548-6.428 3.548c-.654.344-1.416-.229-1.307-.915l1.198-7.556-5.229-5.38C-.27 9.472.057 8.556.711 8.442L7.9 7.298 11.169.429c.436-.572 1.307-.572 1.634 0z"
                fill="#131313"
            />
        </svg>
    )
}

export default PointsIcon
