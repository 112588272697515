import React from "react";
import css from "./Row.module.css";

const Row = (props) => {
  return (
    <div className={css.root}>
      <div>{props.children[0]}</div>
      <div>{props.children[1]}</div>
      <div>{props.children[2]}</div>
      <div>{props.children[3]}</div>
      <div>{props.children[4]}</div>
      <div>{props.children[5]}</div>
      <div>{props.children[6]}</div>
    </div>
  );
};

export default Row;
