import React from "react";

export const BlockIcon = ({ color = "#056B90" }) => (
  <svg
    width="35"
    height="28"
    viewBox="0 0 35 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.843 12.448V6.41215L17.5001 8.93469V14.9706L11.843 12.448Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M23.8933 12.448V6.41215L18.2363 8.93469V14.9706L23.8933 12.448Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M17.8582 3.399L12.3838 5.8401L17.8778 8.28992L23.3521 5.84885L17.8582 3.399Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M4.47487 22.6993V16.6634L10.1319 19.186V25.2218L4.47487 22.6993Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M16.5251 22.6993V16.6634L10.8681 19.186V25.2218L16.5251 22.6993Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M10.49 13.6503L5.0156 16.0914L10.5096 18.5413L15.984 16.1002L10.49 13.6503Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M18.4749 22.6993V16.6634L24.1319 19.186V25.2218L18.4749 22.6993Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M30.5251 22.6993V16.6634L24.8681 19.186V25.2218L30.5251 22.6993Z"
      stroke={color}
      strokeWidth="0.736842"
    />
    <path
      d="M24.49 13.6503L19.0156 16.0914L24.5096 18.5413L29.984 16.1002L24.49 13.6503Z"
      stroke={color}
      strokeWidth="0.736842"
    />
  </svg>
);
