import * as React from "react"

export function InfoBox(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 6c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm3 12H9v-2h2v-4H9v-2h3c.6 0 1 .4 1 1v5h2v2z"
                fill="#1261BF"
            />
        </svg>
    )
}

export default InfoBox
