import React from "react";

export const PriorityStar = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#FFBE2E" />
    <path
      d="M7.99938 1.68414L9.41737 6.04825H14.0061L10.2937 8.74541L11.7117 13.1095L7.99938 10.4123L4.28706 13.1095L5.70504 8.74541L1.99271 6.04825H6.5814L7.99938 1.68414Z"
      fill="white"
    />
  </svg>
);
