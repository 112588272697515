import React, { Fragment, useState } from "react";
import { toolTips } from "@indieasy.software/indieasy-engine";
import css from "./WaggleGrowthReport.module.css";

import Bold from "../../../../../components/Bold";
import Link from "../../../../../components/Link";

import Trend from "./Trend";
import Tab from "../../../../../components/Tab";

import onGradeWaggleData from "../../../../../data/waggleGrowthReport.json";
import offGradeWaggleData from "../../../../../data/offGradeWaggleGrowthReport.json";
import ResultsBar from "../../../../../components/ResultsBar";
import PrevBar from "../../../../../components/PrevBar";

import HowTo from "./assets/waggle_how.png";
import { SmallBlueButton } from "../../../../../components/BlueButton";
import {
  Cell,
  Header,
  HeaderRow,
  Row,
  Table,
} from "../../../../../components/ReportTables";
import styled from "@emotion/styled";
import { WaggleGrowthDetail } from "./WaggleGrowthDetail";
import {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";

export const boldifyNumbers = (value) => {
  // Input data has things like "10 min"
  // we want "<b>10</b> min"
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: value.replace(/([0-9]+)/g, "<b>$1</b>"),
      }}
    />
  );
};

const waggleData = [onGradeWaggleData, offGradeWaggleData];
const TableHolder = styled.div({
  paddingTop: 20,
  borderTop: "1px solid #C3E0E5",
  borderLeft: "1px solid #C3E0E5",
  borderRight: "1px solid #C3E0E5",
  backgroundColor: "#E0F7FB",
  "> table": {
    position: "relative",
    left: -1,
  },
});
const StudentBlockCell = styled(Cell)(({ down }) => ({
  width: 150,
  paddingTop: down ? 0 : 15,
}));
const StudentHeaderCell = styled(Cell)({ backgroundColor: "#FBFCFD" });
export const LightHeaderRow = styled(HeaderRow)({
  backgroundColor: "#FBFCFD",
  fontSize: 13,
  textTransform: "uppercase",
  color: "#131313",
  "& th": {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 15,
    paddingRight: 15,
  },
});

const WaggleGrowthReport = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showHow, setShowHow] = useState(false);
  const [detail, setDetail] = useState(null);

  const contentName = (
    <div>
      <span>{`${props.subject}, Grade ${props.grade}, `}</span>
      <Bold>{`Grade ${props.grade} - ${props.subject}`}, </Bold>
      <span>9/12/2021 - 6/23/2022</span>
    </div>
  );

  const data =
    waggleData[selectedTab][props.subject.toLowerCase()][props.grade];

  if (detail) {
    return (
      <WaggleGrowthDetail
        classLabel={contentName}
        {...detail}
        onBack={() => setDetail(null)}
      />
    );
  }

  const levelRow = (filterFunc, row) =>
    data.columns.map((column, index) => {
      const students = column.students.filter(filterFunc);
      const count = students.length;

      if (count === 0) return <StudentBlockCell key={index}></StudentBlockCell>;
      else {
        const down = (row + index) % 5 === 0;
        const up = (row + index) % 3 === 1; // marginTop: "7.5%",;
        return (
          <StudentBlockCell
            key={index}
            down={down}
            onClick={() => setDetail({ column, row, students })}
          >
            <Trend key={index} row={row} value={count} up={up} down={down} />
          </StudentBlockCell>
        );
      }
    });

  return (
    <div className={css.root}>
      <div className={css.top}>
        <div className={css.title}>Waggle Proficiency Growth Report</div>
        <div className={css.updateLabel}>Last updated 8 min ago</div>
        <div className={css.spacer} />
        <SmallBlueButton>Export / Print</SmallBlueButton>
      </div>
      <div className={css.main}>
        <div className={css.content}>
          <div className={css.top}>{contentName}</div>
          <div className={css.selections}>
            <div className={css.tabs}>
              {!showHow ? (
                <Fragment>
                  <Tab
                    selected={selectedTab == 0}
                    onClick={() => setSelectedTab(0)}
                  >
                    ON GRADE LEVEL ASSIGNMENTS
                  </Tab>
                  <Tab
                    selected={selectedTab == 1}
                    onClick={() => setSelectedTab(1)}
                  >
                    OFF GRADE LEVEL ASSIGNMENTS
                  </Tab>
                </Fragment>
              ) : null}
            </div>
            {/* <div onMouseDown={() => setShowHow(true)}> */}
            <div
              onMouseEnter={(ev) => rolloverTipBegin(ev, "WaggleGrowthHowRead")}
              onMouseLeave={rolloverTipEnd}
              size="12px"
            >
              <Link underline={false} size="14px">
                How do I read this report?
              </Link>
            </div>
            {showHow ? (
              <div className={css.how}>
                <img src={HowTo} />
                <div
                  onMouseDown={() => setShowHow(false)}
                  className={css.close}
                />
              </div>
            ) : null}
          </div>
          <TableHolder>
            <Table width="" marginTop={0}>
              <thead>
                <LightHeaderRow>
                  <Header width={170}>&nbsp;</Header>
                  {data.columns.map((x, index) => (
                    <Header key={index}>{x.name}</Header>
                  ))}
                </LightHeaderRow>
              </thead>
              <tbody>
                {selectedTab === 0 && (
                  <React.Fragment>
                    <Row>
                      <Cell>% CONTENT ASSIGNED</Cell>
                      {data.columns.map((x, index) => (
                        <Cell key={index}>{x.assigned}</Cell>
                      ))}
                    </Row>

                    <Row>
                      <Cell>AVG. PROFICIENCY</Cell>
                      {data.columns.map((x, index) => (
                        <Cell key={index}>
                          {boldifyNumbers(x.avgProficiency)}
                        </Cell>
                      ))}
                    </Row>
                  </React.Fragment>
                )}
                <Row>
                  <Cell>AVG. TIME SPENT</Cell>
                  {data.columns.map((x, index) => (
                    <Cell key={index}>{boldifyNumbers(x.avgTimeSpent)}</Cell>
                  ))}
                </Row>

                <Row>
                  <StudentHeaderCell>
                    <div style={{ color: "#2F8718" }} className={css.name}>
                      Above Level
                    </div>
                    <div className={css.text}>81-100</div>
                  </StudentHeaderCell>
                  {levelRow((student) => student.value === 0, 0)}
                </Row>
                <Row>
                  <StudentHeaderCell>
                    <div style={{ color: "#DE6502" }} className={css.name}>
                      On Level
                    </div>
                    <div className={css.text}>60-80</div>
                  </StudentHeaderCell>
                  {levelRow(
                    (student) => student.value === 1 || student.value === 2,
                    1
                  )}
                </Row>
                <Row>
                  <StudentHeaderCell>
                    <div style={{ color: "#C92222" }} className={css.name}>
                      Below Level
                    </div>
                    <div className={css.text}>0-59</div>
                  </StudentHeaderCell>
                  {levelRow(
                    (student) => student.value === 3 || student.value === 4,
                    2
                  )}
                </Row>
              </tbody>
            </Table>
          </TableHolder>

          <div className={css.footer}>
            <div className={css.trend}>
              <img
                className={css.icon}
                src="https://practice.gogetwaggle.com/teacher/assets/images/growth-report-legend-up.png?v=3.0"
              />
              <div className={css.text}>
                <Bold>Positive</Bold> trend in proficiency
              </div>
            </div>
            <div className={css.trend}>
              <img
                className={css.icon}
                src="https://practice.gogetwaggle.com/teacher/assets/images/growth-report-legend-down.png?v=3.0"
              />
              <div className={css.text}>
                <Bold>Negative</Bold> trend in proficiency
              </div>
            </div>
            <div className={css.trend}>
              <img
                className={css.icon}
                src="https://practice.gogetwaggle.com/teacher/assets/images/growth-report-legend-neutral.png?v=3.0"
              />
              <div className={css.text}>
                <Bold>No significant</Bold> trend in proficiency
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaggleGrowthReport;
