import React from "react";
import css from "./Item.module.css";

const Item = (props) => {
  return (
    <div className={css.root}>
      <div className={css.value}>{props.value}</div>
      <div className={css.min}>min</div>
      <img className={css.icon} src={props.icon} />
      <div className={css.name}>{props.name}</div>
    </div>
  );
};

export default Item;
