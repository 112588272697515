import React from 'react';

import styled from '@emotion/styled';
import { ClassSummary } from "./ClassSummary"
import { ClassGrid } from "./ClassGrid"
import data from "../../../data/studentReport.json"
import { useToggle } from "../../../../../common/util/useToggle";
import StudentSummary from './StudentSummary';
import { StudentGrid } from './StudentGrid';
import { LightColors } from '../../../light-theme';
import SearchName from './SearchName';

import { SmallWhiteButton } from "../../../components/BlueButton.js"
import SkillSearch from './SkillSearch';

const StudentReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 957px;
  margin: 0 auto;
`;


const Link = styled.a({
    label: 'Link',
    display: 'block',
    textAlign: 'left',
    width: '100%',
    marginTop: 10,
    color: LightColors.blue, fontWeight: "bold", textDecoration: "none", cursor: "pointer"
});

const Name = styled.div({
    textAlign: 'left',
    //styleName: UI Text/Big 2 - Open Sans Bold 22pt;
    fontFamily: 'Open Sans',
    fontSize: 22,
    fontWeight: 700,

    letterSpacing: '0em',
    display: 'block',
    width: '100%',
    marginTop: 15


});

const SearchRow = styled.div({
    label: 'SearchRow',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '100%',
    alignItems: 'center',


    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,

    letterSpacing: '0em',
    textAlign: 'left',

});

export const StudentReport = ({
    subject, grade, detail
}) => {
    const studentData = data[subject][grade];
    const [detailOpen, toggleDetail] = useToggle(!!detail);
    return (
        <StudentReportContainer>
            {detailOpen && <>

                <Link onClick={toggleDetail}>
                    &lt; Back to Student Report</Link>

                <Name>Massey, Bernard</Name>
                <StudentSummary
                    assignmentsCompleted={studentData[0].assignmentsCompleted}
                    avgTime={studentData[0].avgTime}
                    avgPracTime={studentData[0].avgPracTime}
                    totalTime={studentData[0].totalTime}
                />
                <SearchRow>
                    <SkillSearch />
                    <SmallWhiteButton>EXPORT/PRINT</SmallWhiteButton>
                </SearchRow>
                <StudentGrid skills={studentData[0].skills} />
            </>}
            {detailOpen || <>
                {studentData.length > 0 && <ClassSummary grade={grade} />}
                <SearchRow>
                    <SearchName />
                    <span>Skill proficiency is based on performance in Practice assignments only.</span>
                    <SmallWhiteButton>EXPORT/PRINT</SmallWhiteButton>
                </SearchRow>
                <ClassGrid
                    onViewDetail={toggleDetail}
                    studentData={studentData}
                    subject={subject}
                    grade={grade} />
            </>}
        </StudentReportContainer>
    );
};

