// See findcontent-with-README.md for info about this hack
import data from "../../../../data/findcontent-with-blocks.json";
import oldData from "../../../../data/findcontent-with-blocks-old.json";

const oldDataLabels = [
  "into_lit_passage",
  "into_math_lesson",
  "into_reading_week",
  "go_math_lesson",
  "math_focus_lesson",
  "math_expressions_lesson",
];

export const unitDataByType = (unitType) => {
  return oldDataLabels.includes(unitType) ? oldData : data;
};
