import React from "react";
import CheckBox from "../../../../components/CheckBox";
import removeIcon from "../removeIcon.png";
// import Link from "../../../../components/Link";
import ProgressBar from "../../../../components/ProgressBar";

import moveDots from "../moveDots.png";
import { UpDown } from "./UpDown";
import styled from "@emotion/styled";

import css from "./EntryRow.module.css";
import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../data/rollovertips";
import { MoveDots } from "./MoveDots"
import { toolTips } from "@indieasy.software/indieasy-engine";
import { LightColors } from "../../../../light-theme";
import { CellNoBorder, Row, CellNoBorderCentered } from "../../../../components/ReportTables";
import TrashIcon from "./TrashIcon";
const RemoveIcon = styled.img({
  position: "relative",
  top: -4,
});
const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const types = {
  skill:
    "https://practice.gogetwaggle.com/teacher/assets/images/skill-boost-gray.svg?v=3.0",
  goal: "https://practice.gogetwaggle.com/teacher/assets/images/learning-goal-gray.svg?v=3.0",
  lesson:
    "https://practice.gogetwaggle.com/teacher/assets/images/lesson-gray.svg?v=3.0",
  game: "https://practice.gogetwaggle.com/teacher/assets/images/game-gray.svg?v=3.0",
};


const Link = styled.a({
  color: LightColors.blue,

  marginRight: 5,
  display: "inline-block",
  fontSize: 16,
  fontFamily: 'Open Sans',
  fontWeight: 700,
  cursor: "pointer",

})

const SubText = styled.div({
  color: LightColors.black,

  fontSize: 16,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 1.1,
})


const ActionsCellNoBorder = styled.td({
  width: 140,
  display: "flex",
  justifyContent: "space-evenly",
});



const EntryRow = (props) => {
  const selectHandler = () => {
    if (props.onSelect) props.onSelect(props);
  };

  const openHandler = () => {
    if (props.index != 0) return;

    rolloverTipEnd();
    if (props.onOpen) props.onOpen(props);
  };

  return (
    <Row selected={props.selected} className={css.hideyrow}>
      <CellNoBorder width={25}>
        <MoveDots className={css.hidehover} />
      </CellNoBorder>

      <CellNoBorder width={27}>
        <UpDown position={props.index + 1} className={css.hidehover} />
      </CellNoBorder>

      <CellNoBorder width={20}>
        <CheckBox onClick={selectHandler} selected={props.selected} />
      </CellNoBorder>

      <CellNoBorder width={310} justifyContent="flexStart" onMouseDown={openHandler}>
        <div>
          <Link
            onMouseEnter={(ev) => {
              if (props.index > 0) rolloverTipBegin(ev, "TA_assignment_link");
            }}
            onMouseLeave={rolloverTipEnd}
          >
            {props.data.assignment}
          </Link>
          <br />
          <SubText>
            {capitalize(props.data.type)}
            | {
              capitalize(props.data.type) === 'Skill Quiz' ? "Manually Assign" : "Auto Assign"
            }
          </SubText>
        </div>
      </CellNoBorder>



      <CellNoBorderCentered width={110} justifyContent="flex-start">{props.data.students}</CellNoBorderCentered>
      <CellNoBorderCentered >
        {
          Math.round(props.data.students * props.data.status[0].value)
        }

      </CellNoBorderCentered>
      <CellNoBorderCentered >
        {
          Math.round(props.data.students * props.data.status[1].value)
        }
      </CellNoBorderCentered>
      <CellNoBorderCentered >
        {
          Math.round(props.data.students * (props.data.status[3].value + props.data.status[2].value))
        }
      </CellNoBorderCentered>
      <CellNoBorder width={40}>

        <Link
          underline={false}
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "TA_editbutton", "150px", -15)
          }
          onMouseLeave={rolloverTipEnd}
        >
          Edit
        </Link>
      </CellNoBorder>
      <CellNoBorder width={40}>
        <div
          className={css.delete}
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "TA_trashbutton", "150px", -70)
          }
          onMouseLeave={rolloverTipEnd}
        >

          <TrashIcon />
        </div>
      </CellNoBorder>
    </Row>
  );
};

export default EntryRow;
