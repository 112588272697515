import React from "react";
import styled from "@emotion/styled";

import moveUp from "../moveUp.png";
import moveDown from "../moveDown.png";
import MoveUp from "./MoveUp";
import MoveDown from "./MoveDown";

const UpDownContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 5
});


const PositionInput = styled.input({
  width: 27,
  height: 22,
  textAlign: "center",
  border: "1px solid #ACAFAF",
  boxSizing: "border-box",
});

export const UpDown = ({ position, className }) => {
  return (
    <UpDownContainer>
      {/* <MoveUp className={className} /> */}

      <PositionInput readOnly={true} value={position} />
      {/* <MoveDown className={className} /> */}
    </UpDownContainer>
  );
};
