import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSelectLesson } from "../use-select-lesson";
import { Unit } from "./Unit";
import { UnitIndex } from "./UnitIndex";

export const ContentByUnit = ({ classGrade, grade, groups, languageIndex }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:unitIndex`}>
        <Unit
          languageIndex={languageIndex}
          classGrade={classGrade}
          grade={grade}
          groups={groups}
        />
      </Route>
      <Route>
        <UnitIndex classGrade={classGrade} grade={grade} />
      </Route>
    </Switch>
  );
};
