export const SkillGroupsIcon = ({ fill = "#71767A" }) => (
  <svg
  width={30}
  height={31}
  viewBox="0 0 30 31"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  
>
  <path
    d="M4.633 8.599a.467.467 0 00.326.135h1.915l3.81 3.814 2.063-1.676L8.74 6.867V4.952c0-.122-.05-.24-.135-.326L5.099 1.138a.467.467 0 00-.663 0l-3.3 3.305a.467.467 0 000 .659l3.497 3.497zM20.81 15.21l-5.019 6.185 6.452 6.457a3.964 3.964 0 005.603-5.602l-7.036-7.04zm3.866 9.467a.466.466 0 01-.663 0l-3.735-3.735a.469.469 0 11.663-.663l3.735 3.735a.466.466 0 010 .663v0z"
    stroke="#000"
    strokeWidth={2}
  />
  <path
    d="M28.6 5.289l-3.221 3.22a.467.467 0 01-.659 0L21.5 5.29a.466.466 0 010-.663l3.216-3.217a6.536 6.536 0 00-8.777 6.13c.003.595.086 1.187.247 1.76L2.965 20.037a5.014 5.014 0 00-.7 7.297v0a5.019 5.019 0 007.605-.145L20.7 13.832a6.536 6.536 0 007.9-8.543zM6.602 24.813a1.4 1.4 0 110-2.801 1.4 1.4 0 010 2.8z"
    stroke="#000"
    strokeWidth={2}
  />
</svg>
);
