import * as React from "react";

function HelpIcon(props) {
  return (
    <svg
      width={53}
      height={41}
      viewBox="0 0 53 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={53} height={41} rx={4} fill="#FBFCFD" />
      <path
        d="M26.5 2c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm.9 15.3h-1.8v-1.8h1.8v1.8zm1.863-6.975l-.81.828c-.648.657-1.053 1.197-1.053 2.547h-1.8v-.45c0-.99.405-1.89 1.053-2.547l1.116-1.134A1.76 1.76 0 0028.3 8.3c0-.99-.81-1.8-1.8-1.8s-1.8.81-1.8 1.8h-1.8c0-1.989 1.611-3.6 3.6-3.6s3.6 1.611 3.6 3.6c0 .792-.324 1.512-.837 2.025zM20.828 36h-1.81v-3.697h-3.393V36h-1.816v-8.566h1.816v3.357h3.393v-3.357h1.81V36zm4.729-6.674c.605 0 1.127.117 1.564.352.438.23.776.566 1.014 1.008.238.441.357.98.357 1.617v.867h-4.224c.02.504.17.9.45 1.19.286.285.68.427 1.184.427.418 0 .801-.043 1.149-.129a5.838 5.838 0 001.072-.386v1.382c-.324.16-.664.278-1.02.352a6.32 6.32 0 01-1.283.111c-.656 0-1.238-.12-1.746-.363a2.712 2.712 0 01-1.19-1.107c-.284-.497-.427-1.122-.427-1.875 0-.766.129-1.403.387-1.91.261-.512.625-.895 1.09-1.15.464-.257 1.005-.386 1.623-.386zm.011 1.272c-.347 0-.636.111-.867.334-.226.222-.357.572-.392 1.049h2.507a1.764 1.764 0 00-.146-.71 1.1 1.1 0 00-.41-.492c-.18-.12-.41-.181-.692-.181zM31.732 36h-1.787v-9.117h1.787V36zm5.526-6.674c.738 0 1.334.287 1.787.861.457.575.685 1.416.685 2.526 0 .742-.107 1.367-.322 1.875-.215.504-.511.885-.89 1.142a2.267 2.267 0 01-1.307.387c-.316 0-.588-.039-.814-.117a2.093 2.093 0 01-.58-.31 2.766 2.766 0 01-.417-.41h-.093a4.744 4.744 0 01.093.96v2.643h-1.787v-9.434h1.453l.252.85h.082a2.5 2.5 0 01.428-.486c.168-.149.37-.266.604-.352.238-.09.513-.135.826-.135zm-.574 1.43c-.313 0-.561.064-.744.193-.184.13-.319.322-.405.58-.082.258-.127.584-.135.979v.193c0 .422.04.78.118 1.072.082.293.216.516.404.668.191.153.453.229.785.229.273 0 .498-.076.674-.229.176-.152.306-.375.392-.668.09-.296.135-.658.135-1.084 0-.64-.1-1.123-.299-1.447-.199-.324-.507-.486-.925-.486z"
        fill="#131313"
      />
    </svg>
  );
}

export default HelpIcon;
