export const SummaryIcon = ({ fill = "#71767A" }) => (
  <svg
  width={30}
  height={30}
  viewBox="0 0 30 30"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M28.097 1H1.903A.903.903 0 001 1.903v26.194a.903.903 0 00.903.903h26.194a.904.904 0 00.903-.903V1.903A.904.904 0 0028.097 1v0zm-5.871 20.774H7.774a.452.452 0 010-.903h14.452a.451.451 0 110 .903zm0-6.322H7.774a.452.452 0 010-.904h14.452a.451.451 0 110 .904zm0-6.323H7.774a.452.452 0 010-.903h14.452a.451.451 0 110 .903z"
    stroke="#131313"
    strokeWidth={2}
  />
</svg>
);
