import React, { Fragment, useState } from "react";
import css from "./WaggleGrowth.module.css";

import DropDown from "../../../../components/DropDown";
import ResultsBar from "../../../../components/ResultsBar";
import WaggleGrowthReport from "./WaggleGrowthReport/WaggleGrowthReport";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

import growthData from "../../../../data/growth.json";

const WaggleGrowth = (props) => {
  const [subjectIndex, setSubjectIndex] = useState(
    props.subject == "ela" && growthData.hasELA ? 0 : 1
  );
  const [gradeIndex, setGradeIndex] = useState(props.grade == "3" ? 0 : 1);
  const [classIndex, setClassIndex] = useState(0);
  const [studentIndex, setStudentIndex] = useState(0);
  const [timeIndex, setTimeIndex] = useState(0);

  const [showReport, setShowReport] = useState(true || props.showReport);

  const subject = subjectIndex == 0 ? "ELA" : "Math";

  const gradesData = [{ text: "Grade 3" }, { text: "Grade 7" }];
  const classData = [{ text: `${gradesData[gradeIndex].text} - ${subject}` }];
  const studentData = [{ text: "All Students" }];
  const timeData = [
    { text: "Entire School Year" },
    { text: "Last 12 Weeks" },
    { text: "Last 9 Weeks" },
    { text: "Last 4.5 Weeks" },
    { text: "Last Week" },
    { text: "Custom" },
  ];

  const reportHandler = () => {
    setShowReport(true);
    //if (props.onReport) props.onReport();
  };

  const prevHandler = () => {
    setShowReport(false);
  };

  const mainContents = (
    <Fragment>
      <div className={css.header}>
        <div className={css.title}>Waggle's Proficiency Growth Report</div>
      </div>
      <div className={css.contents}>
        <div className={css.left}>
          <div className={css.top}>Choose Growth Report Options</div>
          <div className={css.row}>
            <div className={css.entry}>
              <div className={css.text}>1. DISCIPLINE:</div>
              <div className={css.discipline}>
                <div
                  onMouseDown={
                    growthData.hasELA ? () => setSubjectIndex(0) : null
                  }
                  className={subjectIndex == 0 ? css.selected : ""}
                >
                  ELA
                </div>
                <div
                  onMouseDown={
                    growthData.hasMath ? () => setSubjectIndex(1) : null
                  }
                  className={subjectIndex == 1 ? css.selected : ""}
                >
                  Math
                </div>
              </div>
            </div>
            <div className={css.entry}>
              <div className={css.text}>2. GRADES:</div>
              <div>
                <DropDown
                  width="200px"
                  selectedIndex={gradeIndex}
                  selectIndex={setGradeIndex}
                  data={gradesData}
                  growth
                  /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
                />
              </div>
            </div>
            <div className={css.entry}>
              <div className={css.text}>3. CLASSES:</div>
              <div>
                <DropDown
                  width="200px"
                  selectedIndex={classIndex}
                  selectIndex={setClassIndex}
                  data={classData}
                  growth
                  /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
                />
              </div>
            </div>
            <div className={css.entry}>
              <div className={css.text}>4. STUDENTS:</div>
              <div>
                <DropDown
                  width="200px"
                  selectedIndex={studentIndex}
                  selectIndex={setStudentIndex}
                  data={studentData}
                  growth
                  /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
                />
              </div>
            </div>
          </div>
          <div className={css.row}>
            <div className={css.entry}>
              <div className={css.text}>5. TIME:</div>

              <div>
                <DropDown
                  width="200px"
                  selectedIndex={timeIndex}
                  selectIndex={setTimeIndex}
                  data={timeData}
                  growth
                  rolloverTipTitle={
                    rolloverTips["WaggleGrowth_Timedropdown"].title
                  }
                  rolloverTip={rolloverTips["WaggleGrowth_Timedropdown"].text}
                />
              </div>
            </div>
          </div>
          <div className={css.row}>
            <div className={css.entry}>
              <div className={css.text}>6. LOAD RESULTS:</div>
              <div className={css.button} onMouseDown={reportHandler}>
                See Reports
              </div>
            </div>
          </div>
        </div>
        {/* <ResultsBar
          bold
          color="black"
          highlightColor="#449dac"
          width="35px"
          background="rgba(255,255,255,.8)"
          fontSize="14px"
        >
          <span style={{ fontSize: "14px" }}>CHANGE REPORT OPTIONS</span>
        </ResultsBar> */}
      </div>
    </Fragment>
  );

  const report = (
    <WaggleGrowthReport
      {...props}
      subject={subject}
      grade={gradeIndex === 0 ? 3 : 7}
      onPrev={prevHandler}
    />
  );

  // Right now, the report options aren't displayed anymore. Leaving it in the code for now
  // in case that's not right, but can probably remove
  const contents = showReport ? report : mainContents;

  return <div className={css.root}>{contents}</div>;
};

export default WaggleGrowth;
