import React, { useCallback, useState } from "react";
import students from "../../../data/students.json";
import styled from "@emotion/styled";
import { components } from "@indieasy.software/indieasy-components";
import StudentActivityPopup from "../Popup/StudentActivityPopup";
import ClassActivityPopup from "../Popup/ClassActivityPopup";
import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../data/rollovertips";
import MindsetReport from "../Home/Right/ClassInsights/MindsetReport";
import { StandardsReportIcon } from "./StandardsReportIcon";
import { SkillsStatusReportIcon } from "./SkillsStatusReportIcon";
import { MindsetReportIcon } from "./MindsetReportIcon";
import { UsageReportIcon } from "./UsageReportIcon";

const { Markup } = components;

const OtherReportsContainer = styled.div({
  label: "OtherReportsContainer",
  padding: 20,
  paddingBottom: 200,
});
const Title = styled.div({
  label: "Title",
  fontWeight: "bold",
  marginTop: 15,
  fontSize: 22,
  "> small": {
    paddingLeft: 30,
    color: "#333",
    fontWeight: "normal",
    fontSize: 12,
  },
});
const Columns = styled.div({
  label: "Columns",
  display: "flex",
  gap: 100,
});
const Column = styled.div({
  label: "Column",
  marginTop: 20,
  minWidth: 400,
});
const ColumnHeader = styled.div({
  label: "ColumnHeader",
  fontSize: 18,
  marginTop: 20,
  marginBottom: 30,
  fontWeight: "bold",
});

const ReportTitle = styled.div({
  cursor: "pointer",
  label: "ReportTitle",
  fontWeight: "bold",
  textDecoration: "underline",
  color: "#056B90",
  fontSize: 18,
});

const StudentTitle = styled(ReportTitle)({
  marginTop: 20,
});
const Box = styled.div({
  display: "flex",
  alignItems: "center",
  label: "Box",
  backgroundColor: "#E0F7FB",
  border: "1px solid #B8D4D9",
  padding: 20,
  boxSizing: "border-box",
  width: 380,
  marginTop: 20,
  position: "relative",
});

const Row = styled.div({
  label: "Row",
  display: "flex",
});
const Image = styled.div({
  label: "Image",
  width: 35,
  marginRight: 25,
  marginTop: 5,
});
const Contents = styled.div({
  label: "Contents",

  "div:nth-child(1)": {
    marginBottom: 5,
  },
});

export const OtherReports = ({ subject, grade }) => {
  const standardStatusHandler = () => true;
  const [popup, setPopup] = useState(-1);
  const [student, setStudent] = useState(null);
  const [popupTabIndex, setPopupTabIndex] = useState(0);
  const closePopup = useCallback(() => setPopup(-1), []);

  const openStandardStatusReport = useCallback(() => {
    setPopupTabIndex(2);
    setPopup(2);
  }, []);

  const openSkillStatusReport = useCallback(() => {
    setPopupTabIndex(1);
    setPopup(2);
  }, []);

  const openUsageReport = useCallback(() => {
    setPopupTabIndex(3);
    setPopup(2);
  }, []);

  const openMindsetReport = useCallback(() => {
    setPopup(3);
  }, []);

  const openStudentReport = useCallback((studentIndex) => {
    if (studentIndex !== 0) return;
    setStudent(students[studentIndex]);
    setPopupTabIndex(0);
    setPopup(1);
  }, []);

  return (
    <OtherReportsContainer>
      {student && popup === 1 && (
        <StudentActivityPopup
          index={popupTabIndex}
          onClose={closePopup}
          title={`${student.lastName}, ${student.firstName}`}
          data={student}
          subject={subject}
          grade={grade}
        />
      )}

      {popup === 2 && (
        <ClassActivityPopup
          index={popupTabIndex}
          onStudentSelected={openStudentReport}
          onClose={closePopup}
          title={`Grade ${grade} - ${subject == "ela" ? "ELA" : "Math"}`}
          subject={subject}
          grade={grade}
        />
      )}

      {popup === 3 && (
        <MindsetReport onClose={closePopup} subject={subject} grade={grade} />
      )}

      <Title>
        View All Reports for {`Grade ${grade} - ${subject.toUpperCase()}`}
        <small>Last Updated 8 min ago</small>
      </Title>
      <Columns>
        <Column>
          <ColumnHeader>Class Reports</ColumnHeader>

          <Box onClick={openStandardStatusReport}>
            <Row>
              <Image>
                <StandardsReportIcon />
              </Image>
              <Contents>
                <div onMouseDown={standardStatusHandler}>
                  <ReportTitle>Standard Status Report</ReportTitle>
                </div>
                <div>View student status on standards.</div>
              </Contents>
            </Row>
          </Box>

          <Box onClick={openSkillStatusReport}>
            <Row>
              <Image>
                <SkillsStatusReportIcon />
              </Image>
              <Contents>
                <div onMouseDown={standardStatusHandler}>
                  <ReportTitle>Skills Status Report</ReportTitle>
                </div>
                <div>View student status on skills.</div>
              </Contents>
            </Row>
          </Box>

          <Box onClick={openMindsetReport}>
            <Row>
              <Image>
                <MindsetReportIcon />
              </Image>
              <Contents>
                <div>
                  <ReportTitle>Mindset Report</ReportTitle>
                </div>
                <div>
                  View which students are exerting a high level of effort.
                </div>
              </Contents>
            </Row>
          </Box>

          <Box onClick={openUsageReport}>
            <Row>
              <Image>
                <UsageReportIcon />
              </Image>
              <Contents>
                <div onMouseDown={standardStatusHandler}>
                  <ReportTitle>Usage Report</ReportTitle>
                </div>
                <div>View student status on skills.</div>
              </Contents>
            </Row>
          </Box>
        </Column>
        <Column>
          <ColumnHeader>Individual Student Reports</ColumnHeader>

          {students.map((s, index) => (
            <StudentTitle
              key={index}
              onClick={() => openStudentReport(index)}
              onMouseEnter={(ev) => {
                if (index != 0)
                  rolloverTipBegin(ev, "Home_roster_viewreportbutton");
              }}
              onMouseLeave={rolloverTipEnd}
            >
              {s.name}
            </StudentTitle>
          ))}
        </Column>
      </Columns>
    </OtherReportsContainer>
  );
};
