export const LeaderboardIcon = ({ fill = "#71767A" }) => (
  <svg
  width={36}
  height={36}
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.517 3.207H2.552A1.552 1.552 0 001 4.759v5.515a7.62 7.62 0 006.339 7.537 11.482 11.482 0 007.926 6.915 6.802 6.802 0 01-1.513 3.309h-3.476a1.552 1.552 0 00-1.552 1.551v3.862A1.552 1.552 0 0010.276 35h15.448c.412 0 .806-.163 1.097-.454l-.707-.708.707.708c.291-.291.455-.686.455-1.098v-3.862c0-.411-.164-.806-.455-1.097l-.707.707.707-.707a1.552 1.552 0 00-1.097-.454h-3.476a6.802 6.802 0 01-1.513-3.31 11.481 11.481 0 007.926-6.914A7.621 7.621 0 0035 10.275V4.759a1.552 1.552 0 00-1.552-1.552h-3.965v-.655c0-.412-.164-.806-.455-1.098l-.707.708.707-.708A1.552 1.552 0 0027.931 1H8.069a1.552 1.552 0 00-1.552 1.552v.655zm.022 11.072a4.516 4.516 0 01-2.436-4.004V6.31h2.414v7.276c0 .232.007.462.022.692zm22.944-.693V6.31h2.414v3.965a4.517 4.517 0 01-2.436 4.003c.015-.23.022-.46.022-.691v0z"
    stroke="#000"
    strokeWidth={2}
  />
</svg>
);
