import React, { useState } from "react";
import css from "./Domain.module.css";

import waggleData from "../../../../../../data/hmhGrowthReportDomain.json";

import Trend from "../Trend";
import DropDown from "../../../../../../components/DropDown";

import Popup from "./Popup";

const Domain = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  const data = waggleData[props.subject.toLowerCase()][props.grade].students;

  const headers = [];
  const boxes = [];
  const aboveContent = [];
  const onContent = [];
  const belowContent = [];

  const openPopupHandler = () => {
    setShowPopup(true);
  };

  const closePopupHandler = () => {
    setShowPopup(false);
  };

  let popupStudents;

  if (props.subject == "ELA") {
    const style = { width: "Calc(50% - 74px)" };

    headers.push(
      <div key={0} style={style} className={css.header}>
        Language
      </div>
    );
    headers.push(
      <div key={1} style={style} className={css.header}>
        Reading Comprehension
      </div>
    );

    boxes.push(<div key={0} style={style} className={css.box}></div>);
    boxes.push(<div key={1} style={style} className={css.box}></div>);

    const aboveLanguageCount = data.filter(
      (student) => student.language == 1
    ).length;
    const aboveReadingCount = data.filter(
      (student) => student.readingComprehension == 1
    ).length;
    const onLanguageCount = data.filter(
      (student) => student.language == 2
    ).length;
    const onReadingCount = data.filter(
      (student) => student.readingComprehension == 2
    ).length;
    const belowLanguageCount = data.filter(
      (student) => student.language == 3
    ).length;
    const belowReadingCount = data.filter(
      (student) => student.readingComprehension == 3
    ).length;

    if (aboveLanguageCount === 0)
      aboveContent.push(<div style={style} key={0} className={css.box} />);
    else {
      aboveContent.push(
        <div style={style} key={0} className={css.box}>
          <Trend
            index={0}
            value={aboveLanguageCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (aboveReadingCount === 0)
      aboveContent.push(<div style={style} key={1} className={css.box} />);
    else {
      aboveContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={0}
            value={aboveReadingCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (onLanguageCount === 0)
      onContent.push(<div style={style} key={0} className={css.box} />);
    else {
      onContent.push(
        <div style={style} key={0} className={css.box}>
          <Trend onClick={openPopupHandler} index={1} value={onLanguageCount} />
        </div>
      );
    }

    if (onReadingCount === 0)
      onContent.push(<div style={style} key={1} className={css.box} />);
    else {
      onContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend index={1} value={onReadingCount} rollover="HMHGrowth_domain" />
        </div>
      );
    }

    if (belowLanguageCount === 0)
      belowContent.push(<div style={style} key={0} className={css.box} />);
    else {
      belowContent.push(
        <div style={style} key={0} className={css.box}>
          <Trend
            index={2}
            value={belowLanguageCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (belowReadingCount === 0)
      belowContent.push(<div style={style} key={1} className={css.box} />);
    else {
      belowContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={2}
            value={belowReadingCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    popupStudents = data.filter((student) => student.language == 2);
  } else {
    const offset = 148 / 5;
    const style = { width: "Calc(20% - " + offset + "px)" };

    headers.push(
      <div style={style} key={0} className={css.header}>
        Fractions, Ratios, and Proportions
      </div>
    );
    headers.push(
      <div style={style} key={1} className={css.header}>
        Geometry
      </div>
    );
    headers.push(
      <div style={style} key={1} className={css.header}>
        Measurement, Data, and Probability
      </div>
    );
    headers.push(
      <div style={style} key={1} className={css.header}>
        Numbers
      </div>
    );
    headers.push(
      <div style={style} key={1} className={css.header}>
        Operations and Algebra
      </div>
    );

    boxes.push(<div style={style} key={0} className={css.box}></div>);
    boxes.push(<div style={style} key={1} className={css.box}></div>);
    boxes.push(<div style={style} key={1} className={css.box}></div>);
    boxes.push(<div style={style} key={1} className={css.box}></div>);
    boxes.push(<div style={style} key={1} className={css.box}></div>);

    const aboveFractionsCount = data.filter(
      (student) => student.fractions == 1
    ).length;
    const aboveGeometryCount = data.filter(
      (student) => student.geometry == 1
    ).length;
    const aboveMeasurementsCount = data.filter(
      (student) => student.measurements == 1
    ).length;
    const aboveNumbersCount = data.filter(
      (student) => student.numbers == 1
    ).length;
    const aboveOperationsCount = data.filter(
      (student) => student.operations == 1
    ).length;

    const onFractionsCount = data.filter(
      (student) => student.fractions == 2
    ).length;
    const onGeometryCount = data.filter(
      (student) => student.geometry == 2
    ).length;
    const onMeasurementsCount = data.filter(
      (student) => student.measurements == 2
    ).length;
    const onNumbersCount = data.filter(
      (student) => student.numbers == 2
    ).length;
    const onOperationsCount = data.filter(
      (student) => student.operations == 2
    ).length;

    const belowFractionsCount = data.filter(
      (student) => student.fractions == 3
    ).length;
    const belowGeometryCount = data.filter(
      (student) => student.geometry == 3
    ).length;
    const belowMeasurementsCount = data.filter(
      (student) => student.measurements == 3
    ).length;
    const belowNumbersCount = data.filter(
      (student) => student.numbers == 3
    ).length;
    const belowOperationsCount = data.filter(
      (student) => student.operations == 3
    ).length;

    if (aboveFractionsCount === 0)
      aboveContent.push(<div style={style} key={0} className={css.box} />);
    else {
      aboveContent.push(
        <div style={style} key={0} className={css.box}>
          <Trend
            index={0}
            value={aboveFractionsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (aboveGeometryCount === 0)
      aboveContent.push(<div style={style} key={1} className={css.box} />);
    else {
      aboveContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={0}
            value={aboveGeometryCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (aboveMeasurementsCount === 0)
      aboveContent.push(<div style={style} key={1} className={css.box} />);
    else {
      aboveContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={0}
            value={aboveMeasurementsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (aboveNumbersCount === 0)
      aboveContent.push(<div style={style} key={1} className={css.box} />);
    else {
      aboveContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={0}
            value={aboveNumbersCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (aboveOperationsCount === 0)
      aboveContent.push(<div style={style} key={1} className={css.box} />);
    else {
      aboveContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={0}
            value={aboveOperationsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (onFractionsCount === 0)
      onContent.push(<div style={style} key={0} className={css.box} />);
    else {
      onContent.push(
        <div style={style} key={0} className={css.box}>
          <Trend
            onClick={openPopupHandler}
            index={1}
            value={onFractionsCount}
          />
        </div>
      );
    }

    if (onGeometryCount === 0)
      onContent.push(<div style={style} key={1} className={css.box} />);
    else {
      onContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={1}
            value={onGeometryCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (onMeasurementsCount === 0)
      onContent.push(<div style={style} key={1} className={css.box} />);
    else {
      onContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={1}
            value={onMeasurementsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (onNumbersCount === 0)
      onContent.push(<div style={style} key={1} className={css.box} />);
    else {
      onContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend index={1} value={onNumbersCount} rollover="HMHGrowth_domain" />
        </div>
      );
    }

    if (onOperationsCount === 0)
      onContent.push(<div style={style} key={1} className={css.box} />);
    else {
      onContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={1}
            value={onOperationsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (belowFractionsCount === 0)
      belowContent.push(<div style={style} key={0} className={css.box} />);
    else {
      belowContent.push(
        <div style={style} key={0} className={css.box}>
          <Trend
            index={2}
            value={belowFractionsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (belowGeometryCount === 0)
      belowContent.push(<div style={style} key={1} className={css.box} />);
    else {
      belowContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={2}
            value={belowGeometryCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (belowMeasurementsCount === 0)
      belowContent.push(<div style={style} key={1} className={css.box} />);
    else {
      belowContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={2}
            value={belowMeasurementsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (belowNumbersCount === 0)
      belowContent.push(<div style={style} key={1} className={css.box} />);
    else {
      belowContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={2}
            value={belowNumbersCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    if (belowOperationsCount === 0)
      belowContent.push(<div style={style} key={1} className={css.box} />);
    else {
      belowContent.push(
        <div style={style} key={1} className={css.box}>
          <Trend
            index={2}
            value={belowOperationsCount}
            rollover="HMHGrowth_domain"
          />
        </div>
      );
    }

    popupStudents = data.filter((student) => student.fractions == 2);
  }

  const popup = showPopup ? (
    <Popup
      onClose={closePopupHandler}
      {...props}
      report="hmhdomainreport"
      category="On-Level"
      color="#7AB946"
      data={popupStudents}
    />
  ) : null;

  return (
    <div className={css.root}>
      <div className={css.headers}>
        <div className={css.header + " " + css.first}>
          <DropDown
            width="135px"
            selectedIndex={0}
            data={[
              { text: "Assessment 1" },
              { text: "Assessment 2" },
              { text: "Assessment 3" },
            ]}
            domain
            /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
          />
        </div>
        {headers}
      </div>
      <div className={css.row + " " + css.first}>
        <div className={css.box}>10 Students Reporting</div>
        {boxes}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div style={{ color: "#683995" }} className={css.name}>
            Above-Grade
          </div>
        </div>
        {aboveContent}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div style={{ color: "#7AB946" }} className={css.name}>
            On-Level
          </div>
        </div>
        {onContent}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div style={{ color: "#F89D52" }} className={css.name}>
            Below-Level
          </div>
        </div>
        {belowContent}
      </div>
      {popup}
    </div>
  );
};

export default Domain;
