import React from "react";
import css from "./CheckBox.module.css";

const CheckBox = (props) => {
  const clickHandler = () => {
    if (props.onClick) props.onClick();
  };
  return (
    <div className={css.root} onMouseDown={clickHandler}>
      {props.selected ? <div className={css.check} /> : null}
    </div>
  );
};

export default CheckBox;
