import { tutorialTips } from "@indieasy.software/indieasy-engine";

const data = `										
*										
*	Note: rows beginning with * will not be parsed									
*	Define type "popup" or "tip"									
*	Create a unique ID; suggesting for tips that a 2 letter prefix for the section or area followed by a short contextual word and, if a sequence, a number									
*	Headline: A short phrase signaling the content									
*	Body Copy: We haven't established character count limits and can aim for the tip to expand as needed, but the copy below seems about right.									
*	Next ID: For tips if there is a next tip in the sequence. If blank it will not have a next/continue button.									
*	Button Name: Text to embed in the button in the element (whether popup or tip)									
*										
Type	ID	Headline	Body Copy	Prev ID	Next ID	Button Name				Notes
tip	student-practice	Student Practice	For the purposes of this demo, the "View More" link is only functional in the first row of entries for each category. 			Close				Continue with the NEXT prompt
popup	home	Home Screen	The home screen ...		home_2					
tip	home_2	Slide Out Tip	This is a slide out tip. A sequence of tips can be as long as you want.	home						`;

const lines = data.split("\n");

for (var i = 3; i < lines.length; i++) {
  const line = lines[i];
  const tokens = line.split("\t");

  if (!tokens[1]) continue;

  //console.log(tokens[1]);
  // console.log(`id: ${tokens[1]}, prev: ${tokens[4]}, next: ${tokens[5]}`);

  tutorialTips.manager.addTip(
    new tutorialTips.TutorialTip(tokens[1], tokens[2], tokens[3], {
      modal: tokens[0].toUpperCase() === "POPUP",
      type: tokens[0],
      prev: tokens[4] ? tokens[4] : null,
      next: tokens[5] ? tokens[5] : null,
      hasCloseButton: true,
    })
  );
}
