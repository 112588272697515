import React from "react";
import styled from "@emotion/styled";
import classes from "../../data/classes.json";
import downArrow from "./chevron-down.png";

const Down = styled.img({ position: "relative", top: 3, left: -10 });
const Select = styled.select({
  appearance: "none",
  backgroundColor: "transparent",
  border: "none",
  padding: "0 1em 0 0",
  margin: "0",
  outline: "none",

  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,

  cursor: "pointer",
  width: 170,

  backgroundImage: `url('${downArrow}')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right .6em top 50%",

  "& option": {
    padding: 10,
  },
});

export const ClassPicker = (props) => {
  return (
    <React.Fragment>
      <Select id="class-picker" onChange={props.onChange} value={props.value}>
        {classes.data.map((c) => (
          <option key={c.name} value={`${c.subject}-${c.grade}`}>
            {c.name}
          </option>
        ))}
      </Select>
    </React.Fragment>
  );
};
