import * as React from "react"

function TrashCan(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8553_39822)" fill="#056B90">
        <path d="M15.333 2.667h-4v-2c0-.4-.266-.667-.666-.667H5.333c-.4 0-.666.267-.666.667v2h-4c-.4 0-.667.266-.667.666v1.334c0 .4.267.666.667.666h14.666c.4 0 .667-.266.667-.666V3.333c0-.4-.267-.666-.667-.666zM6 1.333h4v1.334H6V1.333zM14 6.667H2v8.666c0 .4.267.667.667.667h10.666c.4 0 .667-.267.667-.667V6.667zm-8 6.666H4.667v-4H6v4zm2.667 0H7.333v-4h1.334v4zm2.666 0H10v-4h1.333v4z" />
      </g>
      <defs>
        <clipPath id="clip0_8553_39822">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TrashCan
