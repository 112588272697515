import styled from "@emotion/styled";
import { LightColors } from "../light-theme";

export const LinkButton = styled.button({
  padding: "0px 0px",
  background: "none",
  textAlign: "right",
  color: LightColors.blue,
  border: "none",
  outline: "none",
  cursor: "pointer",
});

export const BlueButton = styled.button({
  height: 50,
  padding: "0px 25px",
  backgroundColor: LightColors.white,
  border: `2px solid ${LightColors.blue}`,
  outline: "none",
  margin: 5,
  borderRadius: 4,
  color: LightColors.blue,
  fontWeight: "bold",
  fontSize: 20,
  lineHeight: "24px",
  verticalAlign: "center",
  cursor: "pointer",
  svg: {
    position: "relative",
    top: 2,
  },
  ":active": {
    position: "relative",
    top: 2,
    left: 1,
  },
});

export const SmallWhiteButton = styled.button(
  ({ color = LightColors.blue }) => ({
    label: "SmallWhiteButton",
    padding: "5px 10px",
    backgroundColor: LightColors.white,
    border: `1px solid ${color}`,
    outline: "none",
    margin: 5,
    borderRadius: 8,
    whiteSpace: "nowrap",
    color: color,
    fontWeight: "bold",
    fontSize: 18,
    cursor: "pointer",
    ":active": {
      position: "relative",
      top: 2,
      left: 1,
    },
  })
);

export const SmallBlueButton = styled.button({
  padding: "5px 10px",
  backgroundColor: LightColors.blue,
  border: "none",
  outline: "none",
  margin: 5,
  borderRadius: 8,
  whiteSpace: "nowrap",
  color: "#fff",
  fontWeight: "bold",
  fontSize: 18,
  cursor: "pointer",
  ":active": {
    position: "relative",
    top: 2,
    left: 1,
  },
});

export const SmallPurpleButton = styled.button({
  padding: "5px 10px",
  backgroundColor: LightColors.purple,
  border: `1px solid ${LightColors.white}`,
  outline: "none",
  margin: 5,
  borderRadius: 8,
  whiteSpace: "nowrap",
  color: "#fff",
  fontWeight: "bold",
  fontSize: 18,
  cursor: "pointer",
  ":active": {
    position: "relative",
    top: 2,
    left: 1,
  },
});

export const TinyBlueButton = styled(SmallBlueButton)({
  fontSize: 14,
  height: 25,
  verticalAlign: "top",
  lineHeight: "18px",
  display: "flex",
  alignItems: "center",
});

export const ButtonBar = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 20,
});
