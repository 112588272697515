import React from 'react';
import { toolTips } from "@indieasy.software/indieasy-engine";
import rolloverTips from "../../../data/rollovertips";

import styled from '@emotion/styled';
import { Cell, Header, HeaderRowNoBorder, LightRowNoBorder, Table, YellowRow } from '../../../components/ReportTables';
import { LightColors } from '../../../light-theme';

const Link = styled.a({ color: LightColors.blue, fontWeight: "bold", textDecoration: "none", cursor: "pointer" });

const createToolTip = (event, title, text, offset) => {
    const rect = event.currentTarget.getBoundingClientRect();
    toolTips.manager.show(
        toolTips.createToolTip(
            rect.left + rect.width / 2,
            rect.top + rect.height / 2 + 10 + (offset || 0),
            {
                style: { width: "300px" },
                title: title,
                text: text,
            }
        )
    );
};



export const ClassGrid = ({ studentData, onViewDetail }) => {
    return <Table>
        <thead>
            <HeaderRowNoBorder>
                <Header>STUDENT NAME</Header>
                <Header>SKILLS ASSIGNED</Header>
                <Header>SKILLS PROFICIENT</Header>
                <Header>SKILLS NOT YET PROFICIENT</Header>
                <Header>LAST LOGIN</Header>
            </HeaderRowNoBorder>
            <YellowRow><td colSpan={5} /></YellowRow>
        </thead>
        <tbody>
            {studentData.length === 0 && <Cell style={{ padding: 100, textAlign: 'center' }} colSpan={5}><b>No Data To Display</b></Cell>}
            {studentData.map((student, index) => (
                <LightRowNoBorder>
                    <Cell>
                        {index === 0 &&
                            <Link
                                onClick={onViewDetail}               >
                                {student.lastName}, {student.firstName}
                            </Link>}
                        {index === 0 ||
                            <Link
                                onMouseEnter={(ev) =>
                                    createToolTip(
                                        ev,
                                        rolloverTips["SR_Detail"].title,
                                        rolloverTips["SR_Detail"].text
                                    )
                                }
                                onMouseLeave={() => toolTips.manager.hide()}>
                                {student.lastName}, {student.firstName}
                            </Link>
                        }
                    </Cell>
                    <Cell>{student.skillsAssigned}</Cell>
                    <Cell>{student.skillsProficient}</Cell>
                    <Cell>{
                        student.skillsProficient === "--" ? "--" :
                            student.skillsAssigned - student.skillsProficient}</Cell>
                    <Cell>{student.lastLogin}</Cell>
                </LightRowNoBorder>
            ))}


        </tbody>
    </Table>
}