import React from "react";
import css from "./Summary.module.css";

import Button from "../../Button";
import Content from "./Content";

import data from "../../../../../data/studentInsightSummary.json";

import DotBlue from "./assets/dot_blue.png";
import DotGreen from "./assets/dot_green.png";
import DotGrey from "./assets/dot_grey.png";
import DotOrange from "./assets/dot_orange.png";
import DotPink from "./assets/dot_pink.png";
import DotPurple from "./assets/dot_purple.png";
import DotRed from "./assets/dot_red.png";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const Summary = (props) => {
  const studentData = data[props.subject][props.grade];

  const assignmentData = [
    {
      icon: DotGrey,
      name: "Not Started",
      value: studentData.assignments.not_started,
    },
    {
      icon: DotRed,
      name: "Needs Help",
      value: studentData.assignments.needs_help,
    },
    {
      icon: DotBlue,
      name: "Making Progress",
      value: studentData.assignments.making_progress,
    },
    {
      icon: DotGreen,
      name: "Completed",
      value: studentData.assignments.completed,
    },
  ];

  const skillsData = [
    {
      icon: DotRed,
      name: "Below Level",
      value: studentData.skills.minimal + studentData.skills.basic,
    },
    {
      icon: DotOrange,
      name: "On Level",
      value: studentData.skills.partial,
    },
    {
      icon: DotGreen,
      name: "Above Level",
      value: studentData.skills.advanced + studentData.skills.proficient,
    },
  ];

  const activityCircleData =
    props.grade === "1"
      ? [
          { percent: 11 / 38, stroke: "#F89D02" },
          { percent: 27 / 38, stroke: "#FFC9E4" },
        ]
      : [
          { percent: 38 / 73, stroke: "#7BBA46" },
          { percent: 15 / 73, stroke: "#0288C5" },
          { percent: 11 / 73, stroke: "#F89D02" },
          { percent: 9 / 73, stroke: "#77368B" },
          { percent: 27 / 73, stroke: "#FFC9E4" },
        ];

  const activityData =
    props.grade === "1"
      ? [
          {
            icon: DotOrange,
            name: "Skill Quiz",
            value: studentData.activity.skill_boosts,
          },

          {
            icon: DotPink,
            name: "Learn",
            value: studentData.activity.lessons,
          },
        ]
      : [
          {
            icon: DotGreen,
            name: "Practice",
            value: studentData.activity.learning_goals,
          },
          {
            icon: DotBlue,
            name: "Skill Gap & Remediation",
            value: studentData.activity.skill_gap,
          },
          {
            icon: DotOrange,
            name: "Skill Quiz",
            value: studentData.activity.skill_boosts,
          },
          {
            icon: DotPurple,
            name: "Play",
            value: studentData.activity.games,
          },
          {
            icon: DotPink,
            name: "Learn",
            value: studentData.activity.lessons,
          },
        ];

  const skillsTotal =
    studentData.skills.minimal +
    studentData.skills.partial +
    studentData.skills.basic +
    studentData.skills.proficient +
    studentData.skills.advanced;

  return (
    <div className={css.root}>
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "skillstatus_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>
      </div>
      <div className={css.split}>
        <Content
          title="Assignments"
          data={assignmentData}
          circleData={[
            {
              percent: studentData.assignments.not_started / 9,
              stroke: "#6A6A6A",
            },
            {
              percent: studentData.assignments.needs_help / 9,
              stroke: "#ED1F24",
            },
            {
              percent: studentData.assignments.making_progress / 9,
              stroke: "#0288C5",
            },
            {
              percent: studentData.assignments.completed / 9,
              stroke: "#75B857",
            },
          ]}
        >
          <div style={{ position: "absolute", top: "20px", left: "73px" }}>
            <div
              style={{
                fontSize: "36px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {studentData.assignments.completed}
            </div>
            <div style={{ marginTop: "-5px", fontSize: "10px" }}>COMPLETED</div>
          </div>
        </Content>
        <div className={css.divider} />
        <Content
          marginLeft="-15px"
          marginTop="-65px"
          cx="150"
          cy="150"
          viewbox="0 0 300 300"
          strokeWidth="125px"
          scale="0.4"
          title="Skills Proficiency"
          data={skillsData}
          circleData={[
            {
              percent:
                (studentData.skills.minimal + studentData.skills.basic) /
                skillsTotal,
              stroke: "#c92222",
            },
            {
              percent: studentData.skills.partial / skillsTotal,
              stroke: "#de6502",
            },
            {
              percent:
                (studentData.skills.proficient + studentData.skills.advanced) /
                skillsTotal,
              stroke: "#2f8718",
            },
          ]}
        />
        <div className={css.divider} />
        <Content
          title="Activity"
          data={activityData}
          circleData={activityCircleData}
        >
          <div style={{ position: "absolute", top: "25px", left: "85px" }}>
            <div>
              <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                {Math.floor(204 / 60)}
              </span>
              <span style={{ fontSize: "10px", fontWeight: "bold" }}>HR</span>
            </div>
            <div>
              <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                {204 % 60}
              </span>
              <span style={{ fontSize: "10px", fontWeight: "bold" }}>MIN</span>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
};

export default Summary;
