import React from "react";
import { useHistory, useParams } from "react-router-dom";
import data from "../../../../data/findcontent-with-blocks.json";
import styled from "@emotion/styled";
import { BlockIcon } from "../BlockIcon";
import { StandardsIcon } from "../StandardsIcon";
import { SkillsIcon } from "../SkillsIcon";
import { Container, StrandBlock, Title } from "../CommonFindContent";
import { gradeToIndex } from "../../../../routing-util";
import { LightColors } from "../../../../light-theme";

const StrandName = styled.div({
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "19px",
  letterSpacing: 0.4000000059604645,
  textAlign: "left",
  flexGrow: 1,
});

const StrandOption = styled.div({
  flexGrow: 0,
  fontFamily: "Open Sans",
  cursor: "pointer",
  fontSize: 14,
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  margin: 12,
  letterSpacing: 0.4000000059604645,
  textAlign: "left",
  color: LightColors.blue,
});

const Strand = ({ strand, onBlocks, onStandards, onSkills }) => (
  <StrandBlock>
    <StrandName>{strand.name}</StrandName>
    <StrandOption onClick={onBlocks}>
      <BlockIcon color={LightColors.blue} /> Blocks
    </StrandOption>
    <StrandOption onClick={onStandards}>
      <StandardsIcon color={LightColors.blue} /> Standards
    </StrandOption>
    <StrandOption onClick={onSkills}>
      <SkillsIcon color={LightColors.blue} /> Skills
    </StrandOption>
  </StrandBlock>
);

const filterELA1 = (subject, grade) => (strand) => {
  
  if (subject === "ela" && grade === "1") { 
    // Special rule for ELA 1st grade - Request on 3/9/2024   
    return strand.name.startsWith("RF.1") && strand.practice.length > 0
  }
  return true;
};

export const StrandIndex = ({ classGrade, grade, label }) => {
  const { subject } = useParams();
  const strands = data[subject][gradeToIndex(grade)].strand;
  const history = useHistory();

  //http://localhost:3000/findcontent/math/6/strand/strandid/blocks
  const onStrandSelected = (contentType) => (strandId) => (event) =>
    history.push(
      `/findcontent/${subject}/${classGrade}/${grade}/strand/${strandId}/${contentType}`
    );

  const onBlocks = onStrandSelected("blocks");
  const onStandards = onStrandSelected("standards");
  const onSkills = onStrandSelected("skills");

  return (
    <Container>
      <Title>Select a {label}</Title>
      {strands.filter(filterELA1(subject, grade)).map((s) => (
        <Strand
          key={s.id}
          strand={s}
          onBlocks={onBlocks(s.id)}
          onStandards={onStandards(s.id)}
          onSkills={onSkills(s.id)}
        />
      ))}
    </Container>
  );
};
