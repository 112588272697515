import React from "react";
import css from "./StudentActivityPopup.module.css";

import StudentPopup from "../StudentPopup";
import Summary from "./Summary";
import Standards from "./Standards";
import Students from "./Activity";
import Skills from "./Skills";
import Writing from "./Writing";

const StudentActivityPopup = (props) => {
  const showWriting = props.subject.toLowerCase() == "ela";

  const tabs = ["SUMMARY", "SKILLS", "STANDARDS", "ACTIVITY"];
  if (showWriting) tabs.push("WRITING");

  return (
    <StudentPopup
      {...props}
      width={700}
      hideGrowth={props.grade < 3}
      index={props.index}
      tabs={tabs}
    >
      <Summary {...props} />
      <Skills {...props} />
      <Standards {...props} />
      <Students {...props} />
      {showWriting ? <Writing {...props} /> : null}
    </StudentPopup>
  );
};

export default StudentActivityPopup;
