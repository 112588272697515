import React, { useState } from "react";
import css from "./Outter.module.css";

import TopNav from "../../TopNav";
import Contents from "../../Contents";

import Message from "../../Contents/Message";

const Outter = (props) => {
  const [showMessage, setShowMessage] = useState(false);

  const handleShowMessage = (val) => {
    setShowMessage(val);
  };

  const closeMessageHandler = () => {
    setShowMessage(false);
  };

  return (
    <div className={css.root}>
      <TopNav setShowMessage={handleShowMessage} />
      <div className={css.contents}>
        <Contents />
        {showMessage ? <Message onClose={closeMessageHandler} /> : null}
      </div>
    </div>
  );
};

export default Outter;
