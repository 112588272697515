import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useToggle } from "../../../../../common/util/useToggle";
import { LightColors } from "../../../light-theme";

const languages = ["en", "es"];

const contentTypeToFilterTypes = {
  cms_lessons: "learn",
  practicepack: "practice",
  game: "play",
  waggle5: "skill",
};

export const useFilterOptions = (
  languageIndex,
  grade,
  subject,
  initialLearn = false,
  initialSkill = false,
  initialPlay = false,
  initialPractice = false
) => {
  const [learn, toggleLearn] = useToggle(initialLearn);
  const [skill, toggleSkill] = useToggle(initialSkill);
  const [play, togglePlay] = useToggle(initialPlay);
  const [practice, togglePractice] = useToggle(initialPractice);

  // 8/19/2022 - Got a request to hide everything except:
  //     math 1-2 learn & skill quiz
  //     math k learn
  //     ela k learn & practice
  //     ela 1-2 learn & skill quiz
  //     math 3+ everything
  //     ela 3+ everything
  const filterAvailability = useMemo(
    () => ({
      learn: true,
      practice: grade > 2 || (grade === 0 && subject === "ela"),
      play: grade > 2,
      skill:
        grade > 2 ||
        (grade > 0 && subject === "math") ||
        (subject === "ela" && grade > 0),
    }),
    [grade, subject]
  );
  console.info("filterAvailability", filterAvailability);

  const contentRule = useCallback(
    (lessonType) => {
      // if (subject === "math" && grade <= 2) {
      //   return lessonType === "cms_lessons" || lessonType === "waggle5";
      // }

      // if (subject === "ela" && grade === 0) {
      //   return lessonType === "cms_lessons" || lessonType === "practicepack";
      // }

      // if (subject === "ela" && grade <= 2) {
      //   return lessonType === "cms_lessons" || lessonType === "waggle5";
      // }

      try {
        return filterAvailability[contentTypeToFilterTypes[lessonType]];
      } catch (e) {
        console.error("Got an error in contentRule", e);
      }
    },
    [filterAvailability]
  );

  const filter = useCallback(
    (lesson) => {
      const t = lesson.type;
      if (languages.indexOf(lesson.language) !== languageIndex) {
        return false;
      }
      if (!contentRule(t)) {
        return false;
      }
      if (learn && t === "cms_lessons") return true;
      if (practice && t === "practicepack") return true;
      if (play && t === "game") return true;
      if (skill && t === "waggle5") return true;
      if (learn || skill || play || practice) return false; // filter set, but not matched before now
      return true; // no filter set
    },
    [languageIndex, contentRule, learn, practice, play, skill]
  );

  return {
    learn,
    toggleLearn,
    practice,
    togglePractice,
    skill,
    toggleSkill,
    play,
    togglePlay,
    filter,
    filterAvailability,
    anyFiltered: learn || practice || play || skill,
  };
};

const FilterOptionContainer = styled.div({
  display: "flex",
  gap: 5,
});

const Option = styled.div(({ active }) => ({
  color: active ? LightColors.blue : "#fff",
  backgroundColor: active ? "none" : LightColors.blue,
  borderRadius: 12,
  border: `1px solid ${LightColors.blue}`,
  cursor: "pointer",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 2,
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 700,

  letterSpacing: 0.4000000059604645,
  textAlign: "left",

  "&:active": {
    position: "relative",
    top: 1,
    left: 1,
  },
}));

export const FilterOptions = ({
  learn,
  toggleLearn,
  practice,
  togglePractice,
  skill,
  toggleSkill,
  play,
  togglePlay,
  filterAvailability,
}) => (
  <FilterOptionContainer>
    Filters:
    {filterAvailability.learn && (
      <Option onClick={toggleLearn} active={!learn}>
        Learn
      </Option>
    )}
    {filterAvailability.practice && (
      <Option onClick={togglePractice} active={!practice}>
        Practice
      </Option>
    )}
    {filterAvailability.skill && (
      <Option onClick={toggleSkill} active={!skill}>
        Skill Quiz
      </Option>
    )}
    {/* {filterAvailability.play && (
      <Option onClick={togglePlay} active={!play}>
        Play
      </Option>
    )} */}
  </FilterOptionContainer>
);
