import React, { useState } from "react";
import { useParams } from "react-router-dom";
import data from "../../../../data/findcontent-with-blocks.json";
import styled from "@emotion/styled";
import { BackLink } from "./BackLink";
import {
  Container,
  StrandBlock,
  LessonTable,
  Title,
} from "../CommonFindContent";
import { PriorityStar } from "../PriorityStar";
import { ToggleIcon } from "./ToggleIcon";
import { lessonTypes } from "../lesson-types";
import { InfoIcon } from "./InfoIcon";
import { StrandDetails } from "./StrandDetail";

const DetailCode = styled.div({
  fontFamily: "Open Sans",
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,
  textAlign: "left",
});
const DetailName = styled.div({
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 400,

  letterSpacing: 0.4000000059604645,
  textAlign: "left",
});

const StandardName = ({ detail }) => (
  <React.Fragment>
    <DetailCode>
      {detail.category} {detail.star && <PriorityStar />}
    </DetailCode>
    <DetailName>{detail.name}</DetailName>
  </React.Fragment>
);

export const StrandStandards = ({
  classGrade,
  grade,
  label,
  groups,
  languageIndex,
}) => {
  return (
    <StrandDetails
      languageIndex={languageIndex}
      groups={groups}
      strandType="standard"
      classGrade={classGrade}
      grade={grade}
      label={label}
      mapToDetail={(strand) => strand.standard}
      renderDetailName={StandardName}
      yellowBar={false}
    />
  );
};
