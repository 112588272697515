import React, { Fragment, useState } from "react";
import css from "./Report.module.css";

import Bold from "../../../../../components/Bold";
import Italic from "../../../../../components/Italic";
import Link from "../../../../../components/Link";

import Trend from "./Trend";
import Overview from "./Overview";
import Domain from "./Domain";
import PrevBar from "../../../../../components/PrevBar";

import HowTo from "./assets/hmh_how.png";

const WaggleGrowthReport = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showHow, setShowHow] = useState(false);

  const contentName = (
    <div>
      <span>{`${props.subject}, Grade ${props.grade}, `}</span>
      <Bold>{`Grade ${props.grade} - ${props.subject}`}, </Bold>
      <span>9/12/2021 - 6/23/2022</span>
    </div>
  );

  const tabHandler = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        {/*<div className={css.icon}>
          <img src="https://practice.gogetwaggle.com/teacher/assets/images/Growth%20Measure_CTS%20Icon_Reading_RGB.png"/>
  </div>*/}
        <div className={css.title}>HMH Growth Measure</div>
      </div>
      <div className={css.main}>
        <PrevBar black name="Change Report Criteria" onClick={props.onPrev} />
        <div className={css.content}>
          <div className={css.top}>
            <div>{contentName}</div>
            <div className={css.autoassign}>
              <div>Auto Assignment</div>
              <div>OFF</div>
            </div>
            <div>
              <Italic>10 students in class</Italic>
            </div>
          </div>
          <div className={css.inst} onMouseDown={() => setShowHow(true)}>
            <Link underline={false} size="14px">
              How do I read this report?
            </Link>
          </div>
          <div className={css.selections}>
            <div className={css.tabs}>
              {!showHow ? (
                <Fragment>
                  <div
                    className={
                      css.button + (selectedTab == 0 ? " " + css.selected : "")
                    }
                    onMouseDown={() => tabHandler(0)}
                  >
                    OVERVIEW
                  </div>
                  <div
                    className={
                      css.button + (selectedTab == 1 ? " " + css.selected : "")
                    }
                    onMouseDown={() => tabHandler(1)}
                  >
                    DOMAIN PROFICIENCY
                  </div>
                  {/*<div
                className={
                  css.button + (selectedTab == 2 ? ' ' + css.selected : '')
                }
                style={{background:"#99bec6"}}
                //onMouseDown={() => tabHandler(1)}
              >
                STANDARDS PROFICIENCY
              </div>*/}
                </Fragment>
              ) : null}
            </div>
            {showHow ? (
              <div className={css.how}>
                <img src={HowTo} />
                <div
                  onMouseDown={() => setShowHow(false)}
                  className={css.close}
                />
              </div>
            ) : null}
          </div>
          <div className={css.space} />
          {selectedTab == 0 ? <Overview {...props} /> : <Domain {...props} />}
        </div>
      </div>
    </div>
  );
};

export default WaggleGrowthReport;
