import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import css from "./Popup.module.css";

import headers from "../../../../../../../data/growthdomainpopup.json";

import data from "../../../../../../../data/students.json";
import Trend from "../../Trend";
import Bold from "../../../../../../../components/Bold";

const Popup = (props) => {
  const history = useHistory();
  const [selected, setSelected] = useState(props.data.map((x) => false));

  const findContentHandler = () => {
    const url = `findcontent-${props.subject.toLowerCase()}-${props.grade}-${
      props.report
    }-${props.report}`;
    history.push(url);
  };

  const selectAllHandler = () => {
    if (selected.filter((x) => !x).length > 0) {
      setSelected(props.data.map((x) => true));
    } else {
      setSelected(props.data.map((x) => false));
    }
  };

  const selectEntryHandler = (index) => {
    let d = [...selected];

    d[index] = !d[index];

    setSelected(d);
  };

  const title = headers[props.subject.toLowerCase()][props.grade].title;
  const category = headers[props.subject.toLowerCase()][props.grade].category;

  const students = props.data.map((x, index) => (
    <div key={index} className={css.row}>
      <div className={css.box}>
        <div className={css.item}>
          <input
            type="checkbox"
            checked={selected[index]}
            onChange={() => selectEntryHandler(index)}
          />
        </div>
        <div className={css.item}>
          <div className={css.text}>
            {data.find((student) => student.id == x.id).name}
          </div>
        </div>
      </div>
      <div className={css.box}>{x.score}</div>
      <div
        className={css.box}
        style={{ color: x.proficiency == "Partial" ? "#5390B2" : "#87B457" }}
      >
        {x.proficiency}
      </div>
      <div className={css.box}>{x.time}</div>
    </div>
  ));

  const closeHandler = () => {
    props.onClose();
  };

  return (
    <div className={css.root}>
      <div className={css.popup}>
        <div className={css.close} onMouseDown={closeHandler} />
        <div className={css.top}>
          <div style={{ color: props.color }} className={css.type}>
            {props.category}
          </div>
          <div className={css.students}>
            <Trend color={props.color} index={2} value={students.length} />
            <div className={css.assessment}>Assessment 1</div>
          </div>
          <div className={css.title}>{title}</div>
        </div>
        <div className={css.rows}>
          <div className={css.row}>
            <div style={{ visibility: "hidden" }}></div>
            <div style={{ visibility: "hidden" }}></div>
            <div className={css.header} style={{ height: "10px" }}>
              <div className={css.item}>
                <div className={css.text}>WAGGLE PROFICIENCY</div>
              </div>
            </div>
            <div style={{ visibility: "hidden" }}></div>
          </div>
          <div className={css.row}>
            <div className={css.header}>
              <div className={css.item}>
                <input
                  type="checkbox"
                  checked={selected.filter((x) => !x).length == 0}
                  onChange={selectAllHandler}
                />
              </div>
              <div className={css.item}>
                <div className={css.text}>STUDENT NAME</div>
              </div>
            </div>
            <div className={css.header}>
              <div className={css.item}>
                <div className={css.text}>SCALED SCORE</div>
              </div>
            </div>
            <div className={css.header}>
              <div className={css.item}>
                <div className={css.text}>
                  <Bold>
                    <div>{category}</div>
                  </Bold>
                  <div style={{ fontWeight: "normal" }}>{title}</div>
                </div>
              </div>
            </div>
            <div className={css.header}>
              <div className={css.item}>
                <div className={css.text}>TIME ON DOMAIN</div>
              </div>
            </div>
          </div>
          {students}
        </div>
        {selected.filter((x) => x).length > 0 ? (
          <div className={css.footer}>
            <div className={css.button} onMouseDown={findContentHandler}>
              Find Content
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Popup;
