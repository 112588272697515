import React, { useState } from "react";

import css from "./GroupPrompt.module.css";

export const GroupNamePrompt = ({ name, onCanceled, onChange }) => {
  const [editName, setEditName] = useState(name);
  return (
    <div className={css.groupPrompt}>
      <div className={css.header}>
        Create Group
        <button className={css.closeButton} onClick={onCanceled} />
      </div>
      <div className={css.body}>
        Groups allow you to assign and report in on students together.
        <input
          type="text"
          value={editName}
          onChange={(e) => setEditName(e.target.value)}
        />
      </div>
      <div className={css.footer}>
        <button className={css.cancelButton} onClick={onCanceled}>
          Cancel
        </button>
        <button
          disabled={editName.length < 2}
          className={css.continueButton}
          onClick={() => onChange(editName)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};
