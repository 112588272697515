import React, { Fragment, useState } from "react";
import css from "./Standards.module.css";

import Button from "../../Button";
import Box from "../../../Home/Box";
import Bold from "../../../../../components/Bold";

import data from "../../../../../data/classInsightsStandard.json";
import selectedData from "../../../../../data/classPopupStandard";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

import ProficiencyTable from "../../ProficiencyTable";
import BackArrow from "../../BackArrow";
import {
  findStandardByCategory,
  findStandardByName,
} from "../../../../../data/findcontent";

const Standards = (props) => {
  const [selected, setSelected] = useState(false);

  const selectedHandler = (value) => {
    const standard =
      findStandardByCategory(props.subject, value.standard) || {};
    setSelected({ domain: standard.heading, standard: value.standard });
  };

  const rows = data[props.subject][props.grade].map((x, index) => {
    return (
      <div key={index} className={css.row}>
        <Box
          style={{
            textIndent: "20px",
            textAlign: "left",
            color: "#056B90",
            fontWeight: "bold",
          }}
          borderRight
          borderBottom
          width="300px"
        >
          <span
            style={{ cursor: "pointer" }}
            onMouseDown={() => {
              if (index == 0) selectedHandler(x);
            }}
            onMouseEnter={(ev) => {
              if (index > 0)
                rolloverTipBegin(ev, "classactivity_standardstab_standards");
            }}
            onMouseLeave={rolloverTipEnd}
          >
            {x.standard}
          </span>
        </Box>
        <Box borderRight borderBottom width="100px">
          {x.assigned}
        </Box>
        <Box borderRight borderBottom width="100px">
          {x.notStarted}
        </Box>
        <Box borderRight borderBottom width="100px">
          {x.inProgress}
        </Box>
        <Box borderBottom width="100px">
          {x.proficientOrAbove}
        </Box>
      </div>
    );
  });

  const contents = selected ? (
    <Fragment>
      <ProficiencyTable
        style={{ height: "220px" }}
        data={selectedData}
        name="STUDENT NAME"
        type="STANDARD"
      />
    </Fragment>
  ) : (
    <Fragment>
      <Box
        style={{
          marginLeft: "300px",
          textAlign: "center",
          height: "20px",
          color: "#5A5A5A",
          fontSize: "13px",
          lineHeight: "20px",
        }}
        width="398px"
        background="#BBDFE8"
      >
        Number of Students
      </Box>
      <div className={css.row}>
        <Box borderRight borderBottom width="300px" background="#EDF6F9">
          STANDARD
        </Box>
        <Box borderRight borderBottom width="100px" background="#EDF6F9">
          ASSIGNED
        </Box>
        <Box borderRight borderBottom width="100px" background="#EDF6F9">
          NOT STARTED
        </Box>
        <Box borderRight borderBottom width="100px" background="#EDF6F9">
          IN PROGRESS
        </Box>
        <Box borderBottom width="100px" background="#EDF6F9">
          <div style={{ marginTop: "4px", lineHeight: "18px" }}>PROFICIENT</div>
          <div style={{ lineHeight: "18px" }}>OR ABOVE</div>
        </Box>
      </div>
      <div className={css.students}>{rows}</div>
    </Fragment>
  );

  return (
    <div className={css.root}>
      {selected ? (
        <div className={css.back}>
          <BackArrow onClick={() => setSelected(false)} />
        </div>
      ) : null}
      <div className={css.top}>
        <Button
          width="115px"
          background="#309acf"
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "classactivity_exportbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Export
        </Button>
        {selected ? (
          <Fragment>
            <div style={{ width: "10px" }} />
            <Button
              width="155px"
              background="#309acf"
              onMouseEnter={(ev) =>
                rolloverTipBegin(ev, "classactivity_changeselectionsbutton")
              }
              onMouseLeave={rolloverTipEnd}
            >
              Change Selections
            </Button>
          </Fragment>
        ) : null}
      </div>
      <div className={css.details}>
        <div>
          <Bold>Total Students : </Bold>10
        </div>
        {selected ? (
          <Fragment>
            <div>
              <Bold>Domain/Strand: {selected.domain} </Bold>
            </div>
            <div>
              <Bold>Standard: {selected.standard} </Bold>
            </div>
          </Fragment>
        ) : null}
      </div>

      {contents}
    </div>
  );
};

export default Standards;
