import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { useToggle } from "../../../../../common/util/useToggle";
import { AssignmentWindow } from "./AssignmentWindow";
import { LightColors } from "../../../light-theme";

const Banner = styled.div({
  position: "fixed",
  bottom: 0,
  left: 0,
  backgroundColor: LightColors.purple,
  right: 0,
  height: 70,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  padding: 10,
  boxSizing: "border-box",
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 400,

  letterSpacing: 0.4000000059604645,
  textAlign: "right",
  gap: 15,
  color: "#FBFCFD",
  alignItems: "center",
});
const AssignButton = styled.button({
  background: "#FBFCFD",
  borderRadius: 8,
  fontFamily: "Open Sans",
  color: LightColors.purple,
  fontSize: 14,
  outline: "none",
  border: "none",
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,
  textAlign: "center",
  padding: 10,
  cursor: "pointer",
  "&:active": {
    position: "relative",
    top: 1,
    left: 1,
  },
});

export const AssignmentBanner = ({
  selectedLessons,
  resetLessonSelection,
  groups,
}) => {
  const [assignmentWindow, toggleAssignmentWindow] = useToggle();

  const closeAssignmentWindow = useCallback(() => {
    toggleAssignmentWindow();
    resetLessonSelection();
  }, [resetLessonSelection, toggleAssignmentWindow]);

  if (selectedLessons.length === 0) return null;

  return (
    <React.Fragment>
      {assignmentWindow && (
        <AssignmentWindow
          groups={groups}
          selectedLessons={selectedLessons}
          onClose={closeAssignmentWindow}
        />
      )}
      <Banner>
        <span>
          <b>({selectedLessons.length})</b> assignments selected
        </span>
        <AssignButton onClick={toggleAssignmentWindow}>
          ASSIGN STUDENTS &amp; DATES
        </AssignButton>
      </Banner>
    </React.Fragment>
  );
};
