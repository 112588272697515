import React, { useState } from 'react';
import { Cell, Header, HeaderRowNoBorder, LightRowNoBorder, Table, YellowRow } from '../../../components/ReportTables';
import data from "../../../data/skillReport.json";
import { toolTips } from "@indieasy.software/indieasy-engine";
import rolloverTips from "../../../data/rollovertips";

import { useToggle } from "../../../../../common/util/useToggle";
import styled from '@emotion/styled';
import { LightColors } from '../../../light-theme';
import SearchName from '../StudentReport/SearchName';
import { SmallWhiteButton } from '../../../components/BlueButton';
import SkillSearch from '../StudentReport/SkillSearch';

const Link = styled.a({ color: LightColors.blue, fontWeight: "bold", textDecoration: "none", cursor: "pointer" });

export const SkillReport = ({ subject, grade }) => {
    const [detailOpen, toggleDetail] = useToggle();
    const [skill, setSkill] = useState("");
    const [domain, setDomain] = useState("");

    return detailOpen ? <Detail skill={skill} domain={domain} toggleDetail={toggleDetail} grade={grade} /> : <Summary
        subject={subject}
        grade={grade}
        setSkill={setSkill}
        setDomain={setDomain}
        toggleDetail={toggleDetail}
    />
}

const createToolTip = (event, title, text, offset) => {
    const rect = event.currentTarget.getBoundingClientRect();
    toolTips.manager.show(
        toolTips.createToolTip(
            rect.right,
            rect.top + rect.height / 2 + 10 + (offset || 0),
            {
                style: { width: "300px" },
                title: title,
                text: text,
            }
        )
    );
};

const H1 = styled.h1({

    fontFamily: 'Open Sans',
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',


});
const H2 = styled.h2({
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 400,

    letterSpacing: '0em',
    textAlign: 'left',

});
const Detail = ({ skill, domain, toggleDetail, grade }) => {
    const k2 = grade === "K" || grade === "1" || grade === "2";

    return <>
        <Link style={{ marginTop: 20, display: "block" }} onClick={toggleDetail}>&lt; Back to Skill Report</Link>
        <H2>{domain}</H2>
        <H1>{skill}</H1>
        <Table>
            <thead>
                <HeaderRowNoBorder>
                    <Header>STUDENT NAME</Header>
                    <Header>GUIDED LESSON SCORE</Header>
                    {k2 || <>
                        <Header>INDEPENDENT PRACTICE PROFICIENCY</Header>
                        <Header>PROFICIENCY DATE</Header>
                    </>}
                    <Header>TIME ON SKILL</Header>
                </HeaderRowNoBorder>
                <YellowRow><td colSpan={k2 ? 3 : 5} /></YellowRow>
            </thead>
            <tbody>

                <LightRowNoBorder>
                    <Cell>
                        Masey, Bernard
                    </Cell>
                    <Cell>
                        {k2 ? "3/3 (100%)" : "5/5 (100%)"}

                    </Cell>
                    {k2 || <>
                        <Cell>Proficient (85%)</Cell>
                        <Cell>9/17/22</Cell>

                    </>}

                    <Cell>22 min</Cell>
                </LightRowNoBorder>
                <LightRowNoBorder>
                    <Cell>
                        Cruz, Neil
                    </Cell>
                    <Cell>
                        {k2 ? "2/3 (67%)" : "3/5 (60%)"}

                    </Cell>
                    {k2 || <>
                        <Cell>Not Proficient (44%)</Cell>
                        <Cell>-</Cell>

                    </>}
                    <Cell>14 min</Cell>
                </LightRowNoBorder>

                <LightRowNoBorder>
                    <Cell>
                        Drass, Anita
                    </Cell>
                    <Cell>-</Cell>
                    {k2 || <>
                        <Cell>Not Started</Cell>
                        <Cell>-</Cell>

                    </>}
                    <Cell>-</Cell>
                </LightRowNoBorder>



            </tbody>
        </Table>
    </>
}


const SearchRow = styled.div({
    label: 'SearchRow',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '100%',
    alignItems: 'center',


    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,

    letterSpacing: '0em',
    textAlign: 'left',

});

const Summary = ({ subject, grade, setSkill, setDomain, toggleDetail }) => {
    const skillData = data[subject][grade];
    const onClick = (skill) => () => {
        setSkill(skill.standard);
        setDomain(skill.domain);
        toggleDetail();
    }
    return <>
        <SearchRow>
            <SkillSearch />
            <span>Skill proficiency is based on performance in Practice assignments only.</span>
            <SmallWhiteButton>EXPORT/PRINT</SmallWhiteButton>
        </SearchRow>
        <Table>
            <thead>
                <HeaderRowNoBorder>
                    <Header>SKILL NAME</Header>
                    <Header>STUDENTS ASSIGNED</Header>
                    <Header>STUDENTS PROFICIENT</Header>
                    <Header>STUDENTS NOT YET PROFICIENT</Header>
                </HeaderRowNoBorder>
                <YellowRow><td colSpan={5} /></YellowRow>
            </thead>
            <tbody>
                {skillData.length === 0 && <Cell style={{ padding: 100, textAlign: 'center' }} colSpan={5}><b>No Data To Display</b></Cell>}
                {skillData.map((skill, index) => (
                    <LightRowNoBorder>
                        <Cell>

                            <Link
                                onClick={onClick(skill)}               >
                                {skill.standard}
                            </Link>
                            {/* {index === 0 ||
                            <Link
                                onMouseEnter={(ev) =>
                                    createToolTip(
                                        ev,
                                        rolloverTips["SR2_Detail"].title,
                                        rolloverTips["SR2_Detail"].text
                                    )
                                }
                                onMouseLeave={() => toolTips.manager.hide()}>
                                {skill.standard}
                            </Link> */
                            }
                        </Cell>
                        <Cell>{skill.assigned}</Cell>
                        <Cell>{skill.proficientOrAbove}</Cell>
                        <Cell>{skill.proficientOrAbove === "--" ? "--" : skill.assigned - skill.proficientOrAbove}</Cell>

                    </LightRowNoBorder>
                ))}


            </tbody>
        </Table>
    </>
}
