import React from "react";
import css from "./PrevBar.module.css";

import Link from "../Link";

const PrevBar = (props) => {
  const name =
    props.name ||
    (props.category
      ? props.data.category + " " + props.data.name
      : props.data.name);

  console.log(props.children);
  const contents = (
    <div className={css.link}>
      {props.child ? props.children : <Link>{name}</Link>}
    </div>
  );

  return (
    <div
      className={css.root + (props.black ? " " + css.black : "")}
      onMouseDown={props.onClick}
    >
      <div className={css.button} />
      {contents}
    </div>
  );
};

export default PrevBar;
