import * as React from "react"

function PracticeIcon
  (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.92 13.38a1 1 0 00-.22-1.09l-3-3a.996.996 0 10-1.41 1.41l1.29 1.29h-2.59c-1.55 0-2.95.57-4 1.5V4.41l2.29 2.29c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 000-1.41l-3.99-4a1 1 0 00-1.42 0l-4 4A.996.996 0 108.7 6.7l2.29-2.29v9.09c-1.05-.94-2.45-1.5-4-1.5H4.4l1.29-1.29A.996.996 0 104.28 9.3l-2.99 2.99a1 1 0 000 1.42l3 3c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 000-1.41l-1.29-1.29h2.59c2.24 0 4 1.76 4 4v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-2.24 1.76-4 4-4h2.59l-1.29 1.29a.996.996 0 00.71 1.7c.26 0 .51-.1.71-.29l3-3c.09-.09.17-.2.22-.33h-.03z"
        fill="#131313"
      />
    </svg>
  )
}

export default PracticeIcon

