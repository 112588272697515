import React, { useState } from "react";
import styled from "@emotion/styled";
import { BlueButton } from "../../../components/BlueButton";

import Leaderboard from "./Left/Leaderboard";
import ShoutOuts from "./Left/ShoutOuts/ShoutOuts";
import { SkillGroups } from "./Left/SkillGroups";
import { Summary } from "./Left/Summary";
import { AssignmentSettings } from "./AssignmentSettings";
import { useToggle } from "../../../../../common/util/useToggle";

import { useGradeSubject } from "../../../routing-util";
import { SummaryIcon } from "./SummaryIcon";
import { SkillGroupsIcon } from "./SkillGroupsIcon";
import { ShoutOutsIcon } from "./ShoutOutsIcon";
import { LeaderboardIcon } from "./LeaderboardIcon";
import { AutoAssignIcon } from "./AutoAssignIcon";
import { LightColors } from "../../../light-theme";
import { rolloverTipBegin, rolloverTipEnd } from "../../../data/rollovertips";
import { HomeTooltip } from "./HomeTooltip";

const HomeContainer = styled.div({
  display: "flex",
  flexDirection: "column",

  padding: 20,
});

const Spacer = styled.div({ flexGrow: 1 });
const Header = styled.div({
  display: "flex",
  "& span": {
    marginRight: 20,
    //styleName: UI Text/Big 2 - Open Sans Bold 22pt;
    fontFamily: "Open Sans",
    fontSize: 22,
    fontWeight: 700,
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& small": {
    paddingTop: 10,
  },
});

const GroupButton = styled.div(({ active }) => ({
  width: 250,
  height: 120,
  boxSizing: "border-box",
  borderTop: active
    ? `9px solid ${LightColors.blue}`
    : `9px solid ${LightColors.white}`,
  //border: active ? "none" : "3px solid #C7E1E6",
  borderRadius: 8,
  backgroundColor: active ? LightColors.grey2 : LightColors.white,

  padding: 10,
  cursor: "pointer",
  position: "relative",
  marginBottom: 10,

  boxShadow: "0px 2px 8px 0px #00000040",

  fontFamily: "Open Sans",
  fontSize: 18,
  fontWeight: 700,
  lineHeight: "25px",
  letterSpacing: "0em",
  textAlign: "center",

  "& div": {
    display: active ? "block" : "none",
  },
  "& svg": {
    display: "block",
    marginLeft: 100,
    marginTop: 15,
    marginBottom: 15,
  },
  "& img": {
    display: "block",
    marginLeft: 100,
    marginTop: 15,
    marginBottom: 15,
  },
}));

const ButtonGroup = styled.div({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: 30,
});

const Arrow = styled.div({
  position: "absolute",
  top: 115,
  left: 100,
  width: 0,
  height: 0,
  borderLeft: "20px solid transparent",
  borderRight: "20px solid transparent",
  borderTop: "20px solid #FBFCFD",
});

const Content = styled.div({
  margin: 20,
});

export const Home = () => {
  const [tab, setTab] = useState(0);
  const [settingsOpen, toggleSettings] = useToggle();
  const { grade, subject } = useGradeSubject();

  return (
    <HomeContainer>
      <Header>
        <span>Home</span>
        <small>Last Updated: 2 min ago</small>
        <Spacer />

        <BlueButton
          style={{ height: 32 }}
          onMouseEnter={(ev) => rolloverTipBegin(ev, "ReviewSetup")}
          onMouseLeave={rolloverTipEnd}
        >
          Review Guided Setup
        </BlueButton>
      </Header>

      <ButtonGroup>
        <HomeTooltip tooltip="The Class Summary gives an overview of student's current work in content blocks.">
          <GroupButton
            data-tooltip-id="class-summary-button"
            active={tab == 0}
            onClick={() => setTab(0)}
          >
            <SummaryIcon fill={LightColors.black} />
            Class Summary
            <Arrow />
          </GroupButton>
        </HomeTooltip>
        <HomeTooltip tooltip="Skill Groups help teachers identify students who need help working with a specific skill.">
          <GroupButton active={tab == 1} onClick={() => setTab(1)}>
            <SkillGroupsIcon fill={LightColors.black} />
            Skill Groups
            <Arrow />
          </GroupButton>
        </HomeTooltip>
        <HomeTooltip tooltip="Shout-outs help teachers call out student achievements in Waggle.">
          <GroupButton active={tab == 2} onClick={() => setTab(2)}>
            <ShoutOutsIcon fill={LightColors.black} />
            Shout-outs
            <Arrow />
          </GroupButton>
        </HomeTooltip>
        <HomeTooltip tooltip="The Leaderboard shows how many gems and points students have earned.">
          <GroupButton active={tab == 3} onClick={() => setTab(3)}>
            <LeaderboardIcon fill={LightColors.black} />
            Leaderboard
            <Arrow />
          </GroupButton>
        </HomeTooltip>
      </ButtonGroup>
      <Content>
        {tab === 0 && <Summary subject={subject} grade={grade} />}
        {tab === 1 && <SkillGroups subject={subject} grade={grade} />}
        {tab === 2 && <ShoutOuts subject={subject} grade={grade} />}
        {tab === 3 && <Leaderboard grade={grade} />}
      </Content>
      {settingsOpen && (
        <AssignmentSettings
          grade={grade}
          subject={subject}
          onClose={toggleSettings}
        />
      )}
    </HomeContainer>
  );
};
