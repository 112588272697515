import { string } from "prop-types";
import ela_domain_k from "./ela_k";
import math_domain_k from "./math_k";
import skills_k from "./skills";

const delimiter = ":";
const popupDelimiter = "||";

const parseDomainTSV = (rawText) => {
  const output = [];
  const lines = rawText.split("\n");

  let currentDomain;
  let currentStandard;
  let currentSubStandard;

  for (var i = 1; i < lines.length; i++) {
    const line = lines[i];
    const tokens = line.split("\t");

    const lineType = tokens[1];

    if (lineType == undefined || lineType.length == 0) {
    }
    //domain
    else if (lineType == 0) {
      currentDomain = {
        name: tokens[2],
        standard: [],
        skill: [],
        lesson: [],
      };
      output.push(currentDomain);
    }
    //standard
    else if (lineType == 1) {
      const standardTokens = tokens[2].split(delimiter);
      currentStandard = {
        category: standardTokens[0],
        name: standardTokens[1],
        star: standardTokens[11] == 1,
        practice: {
          skills: [],
          goals: [],
          lessons: [],
          games: [],
        },
        sub: [],
      };
      currentDomain.standard.push(currentStandard);
    }
    //substandard
    else if (lineType == 2) {
      const subStandardTokens = tokens[2].split(delimiter);
      currentSubStandard = {
        category: subStandardTokens[0],
        name: subStandardTokens[1],
        star: subStandardTokens[11] == 1,
      };
      currentStandard.sub.push(currentSubStandard);
    }

    const goal = tokens[3];
    const goalPopup = tokens[4];
    const skill = tokens[5];
    const skillPopup = tokens[6];
    const lesson = tokens[7];
    const lessonPopup = tokens[8];
    const games = tokens[9];
    const gamesPopup = tokens[10];

    const createPopupObject = (popup) => {
      if (!popup) return null;

      const o = {
        entries: [],
        footer: [],
      };

      const popupTokens = popup.split(popupDelimiter);

      for (var i = 0; i < popupTokens.length; i += 2) {
        var title = popupTokens[i];
        var description = popupTokens[i + 1];

        o.entries.push({
          title: title,
          description: description,
        });
      }

      return o;
    };

    const createPracticeObject = (title, popup) => {
      return {
        id: "",
        title: title,
        links: {
          moreInfo: true,
          viewReport: false,
        },
        assigned: false,
        reassign: false,
        priority: 0,
        popup: createPopupObject(popup),
      };
    };

    if (goal) {
      currentStandard.practice.goals.push(
        createPracticeObject(goal, goalPopup)
      );
    }
    if (skill) {
      currentStandard.practice.skills.push(
        createPracticeObject(skill, skillPopup)
      );
    }
    if (lesson) {
      currentStandard.practice.lessons.push(
        createPracticeObject(lesson, lessonPopup)
      );
    }
    if (games) {
      currentStandard.practice.games.push(
        createPracticeObject(games, gamesPopup)
      );
    }
  }

  return output;
};

const parseLessonTSV = (rawText) => {
  const output = [];
  const lines = rawText.split("\n");

  let currentDomain;
  let currentModule;
  let currentLesson;

  for (var i = 1; i < lines.length; i++) {
    const line = lines[i];
    const tokens = line.split("\t");

    const lineType = tokens[1];

    if (lineType == undefined || lineType.length == 0) {
    }
    //domain
    else if (lineType == 0) {
      currentDomain = {
        name: `${tokens[2]}`,
        modules: [],
      };
      output.push(currentDomain);
    }
    //standard
    else if (lineType == 1) {
      currentModule = {
        name: `${tokens[2]}: ${tokens[3]}`,
        lessons: [],
      };
      currentDomain.modules.push(currentModule);
    }
    //substandard
    else if (lineType == 2) {
      currentLesson = {
        name: `${tokens[2]}: ${tokens[3]}`,
        practice: {
          skills: [],
          goals: [],
          lessons: [],
          games: [],
        },
      };
      currentModule.lessons.push(currentLesson);
    }

    const goal = tokens[4];
    const goalPopup = tokens[5];
    const skill = tokens[6];
    const skillPopup = tokens[7];
    const lesson = tokens[8];
    const lessonPopup = tokens[9];
    const games = tokens[10];
    const gamesPopup = tokens[11];

    const createPopupObject = (popup) => {
      if (!popup) return null;

      const o = {
        entries: [],
        footer: [],
      };

      const popupTokens = popup.split(delimiter);

      for (var i = 0; i < popupTokens.length; i += 2) {
        var title = popupTokens[i];
        var description = popupTokens[i + 1];

        o.entries.push({
          title: title,
          description: description,
        });
      }

      return o;
    };

    const createPracticeObject = (title, popup) => {
      return {
        id: "",
        title: title,
        links: {
          moreInfo: true,
          viewReport: false,
        },
        assigned: false,
        reassign: false,
        priority: 0,
        popup: createPopupObject(popup),
      };
    };

    if (goal) {
      // console.log(currentModule);
      // console.log(currentLesson);
      // console.log(lineType + ", " + goal);
      currentLesson.practice.goals.push(createPracticeObject(goal, goalPopup));
    }
    if (skill) {
      currentLesson.practice.skills.push(
        createPracticeObject(skill, skillPopup)
      );
    }
    if (lesson) {
      currentLesson.practice.lessons.push(
        createPracticeObject(lesson, lessonPopup)
      );
    }
    if (games) {
      currentLesson.practice.games.push(
        createPracticeObject(games, gamesPopup)
      );
    }
  }

  return output;
};

const parseSkills = (column) => {
  const lines = skills_k.split("\n");

  const skills = [];

  for (var i = 1; i < lines.length; i++) {
    const line = lines[i];
    const tokens = line.split("\t");

    const category = tokens[column + 1];
    const name = tokens[column];

    if (category && name) skills.push({ category: category, name: name });
  }

  return skills;
};

//console.log("ela_domain_k");
const elaDomainK = parseDomainTSV(ela_domain_k);
elaDomainK[0].skill = parseSkills(0);
//console.log(JSON.stringify(elaDomainK));

//console.log("math_domain_k");
const mathDomainK = parseDomainTSV(math_domain_k);
mathDomainK[0].skill = parseSkills(2);
//console.log(JSON.stringify(mathDomainK));
