import styled from "@emotion/styled";
import { LightColors } from "../light-theme";

export const Table = styled.table(({ marginTop = 20, width = "100%" }) => ({
  label: "Table",
  marginTop,
  marginBottom: 20,
  border: "1px solid #BFC0C0",
  borderCollapse: "collapse",
  width,
}));

export const BorderlessRow = styled.tr({
  label: "BordelessRow",
  border: "none",
  "& td": {
    borderTop: "none",
    borderBottom: "none",
    borderRight: "none",
    borderLeft: "none",
  },
});
export const Row = styled.tr({
  label: "Row",
  border: `1px solid ${LightColors.grey1}`,
});
export const LightRow = styled(Row)({
  label: "LightRow",
  backgroundColor: LightColors.white,
});
export const LightRowNoBorder = styled(Row)({
  label: "LightRowNoBorder",
  fontFamily: "Open Sans",
  backgroundColor: LightColors.white,
  "& td": {
    borderRight: "none",
    borderLeft: "none",
  },
});

export const HeaderRow = styled.tr({
  label: "HeaderRow",
  backgroundColor: LightColors.white,
});

export const HeaderRowNoBorder = styled(HeaderRow)({
  label: "HeaderRowNoBorder",
  "& th": {
    borderRight: "none",
    borderLeft: "none",
  },
});

export const CompactHeaderRowNoBorder = styled(HeaderRow)({
  label: "HeaderRowNoBorder",
  "& th": {
    borderRight: "none",
    borderLeft: "none",
    paddingTop: 5,
    paddingBottom: 5,
  },
});

export const Header = styled.th(({ width = undefined }) => ({
  label: "Header",
  border: `1px solid ${LightColors.grey1}`,
  width,
  paddingLeft: 15,
  paddingTop: 18,
  paddingBottom: 18,
  fontFamily: "Roboto",
  fontSize: 16,
  color: "#131313",
  fontWeight: 700,
  lineHeight: "14px",
  letterSpacing: 0.20000000298023224,
  textAlign: "left",
}));

export const HeaderLeftAlign = styled(Header)({
  label: "HeaderLeftAlign",
  paddingLeft: 10,
  textAlign: "left",
  position: "relative",
});

export const HeaderCenterAlign = styled(Header)({
  label: "HeaderCenterAlign",
  textAlign: "center !important",
  borderBottom: "none",
});

export const SubHeader = styled.th({
  label: "SubHeader",
  textAlign: "center !important",
  color: "#000",

  /* UI Text/Small 1 - Open Sans Regular 12pt */
  fontSize: 12,
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

export const HeaderNoBorderLeft = styled(Header)({
  label: "HeaderNoBorderLeft",
  borderLeft: "none",
});
export const HeaderNoBorderRight = styled(Header)({
  label: "HeaderNoBorderRight",
  borderRight: "none",
});

export const SubHeaderRow = styled.tr({
  label: "SubHeaderRow",
});

export const Cell = styled.td(({ width = undefined }) => ({
  label: "Cell",
  position: "relative",
  border: `1px solid ${LightColors.grey1}`,
  padding: 12,
  width,
  fontSize: 14,
  fontWeight: 400,
}));

export const CellNoBorderRight = styled(Cell)({
  label: "CellNoBorderRight",
  borderRight: "none",
});
export const CellNoBorderLeft = styled(Cell)({
  label: "CellNoBorderLeft",
  borderLeft: "none",
});
export const CellNoBorder = styled(Cell)({
  label: "CellNoBorder",
  border: "none",
});

export const CellNoBorderCentered = styled(CellNoBorder)({
  label: "CellNoBorderCentered",
  textAlign: "center",
});

export const YellowRow = styled(Row)({
  label: "YellowRow",
  backgroundColor: LightColors.yellow,
  height: 4,
  minHeight: 4,
  maxHeight: 4,
  flexGrow: 0,
  flexShrink: 0,
  boxSizing: "border-box",
  padding: 0,
  lineHeight: "1px",
  td: {
    maxHeight: 1,
    height: 1,
    padding: "0px !important",
    margin: 0,
  },
});
