import React from "react";
import ReactDOM from "react-dom";

import App from "./containers/App";

import { store, toolTips } from "@indieasy.software/indieasy-engine";
import { addReducers } from "./store";

import "./fonts/Soleil.otf";
import "../../common/fonts/Proxima Nova Regular.ttf";
import "../../common/fonts/Proxima Nova Bold.ttf";
import "./fonts/OpenSans-Regular.ttf";
import "./fonts/ZillaSlab-Regular.ttf";
import "./global.css";
import "./data/tips";

import "./data/findcontent_tsv/findcontent";

toolTips.system.createManager("disabled");

addReducers();
store.createStore();

console.log(store.getStore().getState());

ReactDOM.render(<App />, document.getElementById("root"));
