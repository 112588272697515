import React from "react";
import css from "./Hexagon.module.css";

const Hexagon = (props) => {
  let height = props.height;

  const style = {};
  if (props.up) {
    style.marginTop = "-10px";
  } else if (props.down) {
    style.marginTop = "3px";
  } else {
    style.marginTop = "5px";
  }

  const halfWidth = props.width / 2;
  const halfHeight = height / 3;

  return (
    <div style={style} className={css.root}>
      {props.up ? (
        <div
          style={{
            borderBottom: `${halfHeight}px solid ${props.color || "#eeeeee"}`,
            borderLeft: `${halfWidth}px solid transparent`,
            borderRight: `${halfWidth}px solid transparent`,
          }}
          className={css.hextop}
        />
      ) : null}
      <div
        style={{
          background: props.color,
          width: `${props.width}px`,
          height: `${height}px`,
        }}
        className={css.hex}
      >
        {props.children}
      </div>
      {props.down ? (
        <div
          style={{
            borderTop: `${halfHeight}px solid ${props.color || "#eeeeee"}`,
            borderLeft: `${halfWidth}px solid transparent`,
            borderRight: `${halfWidth}px solid transparent`,
          }}
          className={css.hexbottom}
        />
      ) : null}
    </div>
  );
};

export default Hexagon;
