import React from "react";
import css from "./Status.module.css";
import styled from "@emotion/styled";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";
import { LightColors } from "../../../../../../../light-theme";

const Link = styled.a({
  color: LightColors.blue,
  fontWeight: "bold",
});

const StatusLine = styled.div({
  width: 180,
  marginBottom: 3,
});

const Dot = styled.div(({ color }) => ({
  marginTop: 6,
  width: 10,
  height: 10,
  marginRight: 8,
  backgroundColor: color,
}));

const Status = (props) => {
  return (
    <div className={css.root}>
      <Dot color={props.color} />
      <StatusLine>
        <Link
          onMouseEnter={(ev) => rolloverTipBegin(ev, props.id)}
          onMouseLeave={rolloverTipEnd}
        >
          {props.status}
        </Link>
      </StatusLine>
      <div className={css.value}>{props.value}</div>
    </div>
  );
};

export default Status;
