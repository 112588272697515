import React from "react";

export const ToggleIcon = ({ open }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform={`rotate(${open ? 0 : 270} 10 10)`}>
      <path
        d="M10.3295 16.5635C10.239 16.6305 10.1292 16.6667 10.0164 16.6667C9.90356 16.6667 9.79375 16.6305 9.70329 16.5635L0.138665 6.99608C0.0950078 6.95671 0.0601033 6.90868 0.036229 6.85514C0.0123567 6.80159 8.67494e-07 6.74371 8.72614e-07 6.68515C8.77733e-07 6.62659 0.0123567 6.56861 0.0362291 6.51507C0.0601034 6.46152 0.0950078 6.41358 0.138665 6.37422L3.09537 3.47339C3.13128 3.42964 3.17655 3.39436 3.22788 3.37014C3.27921 3.34592 3.33534 3.33335 3.39217 3.33335C3.44901 3.33335 3.50514 3.34592 3.55647 3.37014C3.60781 3.39436 3.65308 3.42964 3.68898 3.47339L9.98379 9.86299L16.3832 3.50802C16.4734 3.44036 16.5833 3.40378 16.6963 3.40378C16.8093 3.40378 16.9193 3.44036 17.0094 3.50802L19.9305 6.44347C19.9358 6.4853 19.9476 6.52602 19.9654 6.56427C19.9882 6.61296 20 6.66607 20 6.71978C20 6.77349 19.9882 6.8265 19.9654 6.8752C19.9501 6.91951 19.9264 6.96057 19.8957 6.99608L10.3295 16.5635Z"
        fill="#131313"
      />
    </g>
  </svg>
);
