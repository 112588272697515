import * as React from "react"

function PlusCircle(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={11} cy={11} r={10} fill="#FBFCFD" stroke="#BFC0C0" />
      <rect x={6} y={10} width={10} height={2} fill="#1261BF" />
      <rect x={10} y={6} width={2} height={10} fill="#1261BF" />
    </svg>
  )
}

export default PlusCircle
