import React from "react";

import css from "./GroupPrompt.module.css";

import { pluralize } from "../../../../../../common/util/pluralize";
export const DeleteConfirmation = ({
  onCanceled,
  onDelete,
  groupName,
  studentCount,
}) => {
  return (
    <div className={css.groupPrompt}>
      <div className={css.header}>
        Confirm group deletion
        <button className={css.closeButton} onClick={onCanceled} />
      </div>
      <div className={css.body}>
        Are you sure you want to delete the group, {groupName} ? It contains{" "}
        {studentCount}{" "}
        {pluralize(
          {
            1: "student.",
            n: "students.",
          },
          studentCount
        )}
      </div>
      <div className={css.footer}>
        <button className={css.cancelButton} onClick={onCanceled}>
          Cancel
        </button>
        <button className={css.continueButton} onClick={onDelete}>
          Delete Group
        </button>
      </div>
    </div>
  );
};
