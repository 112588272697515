import React, { useState } from "react";
import css from "./Overview.module.css";

import waggleData from "../../../../../../data/hmhGrowthReport.json";

import Trend from "../Trend";

import Popup from "./Popup";

const Overview = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopupHandler = () => {
    setShowPopup(true);
  };

  const closePopupHandler = () => {
    setShowPopup(false);
  };

  let students;

  const data = waggleData[props.subject.toLowerCase()][props.grade];

  let gradeTrends = [];
  const colors = ["#683995", "#7AB946", "#F89D52"];

  for (var i = 0; i < 10; i++) {
    // eslint-disable-next-line no-loop-func
    gradeTrends[i] = data.assignments.map((x, index) => {
      if (index == 0)
        students = x.students.filter((student) => student.value === 1);
      const count = x.students.filter((student) => student.grade === i).length;

      let color;

      if (i < props.grade) color = colors[2];
      else if (i == props.grade) color = colors[1];
      else color = colors[0];

      if (count === 0) return <div key={index} className={css.box} />;
      else {
        return (
          <div key={index} className={css.box}>
            <Trend
              onClick={
                index == 0 && i == data.live.grade ? openPopupHandler : null
              }
              rolloverTitle={
                index > 0 || i != data.live.grade ? "Students" : null
              }
              rolloverText={
                index > 0 || i != data.live.grade
                  ? `For purposes of this demo, click the ${data.live.name} students.`
                  : null
              }
              index={0}
              color={color}
              value={count}
            />
          </div>
        );
      }
    });
  }

  const popup = showPopup ? (
    <Popup
      onClose={closePopupHandler}
      {...props}
      report="hideCategories"
      category={data.live.name}
      popupGrade={data.live.grade}
      color="#683995"
      data={students}
    />
  ) : null;

  return (
    <div className={css.root}>
      <div className={css.headers}>
        <div className={css.header}></div>
        <div className={css.header}>ASSESSMENT 1</div>
        <div className={css.header}>ASSESSMENT 2</div>
        <div className={css.header}>ASSESSMENT 3</div>
      </div>
      <div className={css.row + " " + css.first}>
        <div className={css.box}></div>
        <div className={css.box}>10 Students Reporting</div>
        <div className={css.box}>10 Students Reporting</div>
        <div className={css.box}>0 Students Reporting</div>
      </div>
      <div className={css.category + " " + css.first}>
        <div className={css.type}>
          <div className={css.name}>Highschool</div>
        </div>
        {gradeTrends[9]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>8th Grade</div>
        </div>
        {gradeTrends[8]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>7th Grade</div>
        </div>
        {gradeTrends[7]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>6th Grade</div>
        </div>
        {gradeTrends[6]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>5th Grade</div>
        </div>
        {gradeTrends[5]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>4th Grade</div>
        </div>
        {gradeTrends[4]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>3rd Grade</div>
        </div>
        {gradeTrends[3]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>2nd Grade</div>
        </div>
        {gradeTrends[2]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>1st Grade</div>
        </div>
        {gradeTrends[1]}
      </div>
      <div className={css.category}>
        <div className={css.type}>
          <div className={css.name}>Kindergarten</div>
        </div>
        {gradeTrends[0]}
      </div>
      {popup}
    </div>
  );
};

export default Overview;
