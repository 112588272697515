import React from 'react';
import styled from '@emotion/styled';
import InfoBox from "./InfoBox"
import {
    Cell,
    Header,
    HeaderRow,
    LightRowNoBorder,
    Row,
    HeaderRowNoBorder,
    YellowRow,
    Table,
} from "../../../components/ReportTables";

import reportData from "./standards-report-data.json"
import { SmallWhiteButton } from '../../../components/BlueButton';
import SkillSearch from '../StudentReport/SkillSearch';
import { useToggle } from '../../../../../common/util/useToggle';
import { LightColors } from '../../../light-theme';

const ComingSoon = styled.h1({
    marginTop: 100,
    textAlign: 'center',
})



export const NationalStandardReport = ({
    subject,
    grade
}) => {
    const [currentSelection, setCurrentSelection] = React.useState(null)
    const data = reportData[subject][grade]
    return currentSelection ? <StandardReportDetail {...currentSelection} grade={grade} onClose={() => setCurrentSelection(null)} /> : <StandardReportSummary data={data} setCurrentSelection={setCurrentSelection} />
}

const SearchRow = styled.div({
    label: 'SearchRow',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '100%',
    alignItems: 'center',


    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,

    letterSpacing: '0em',
    textAlign: 'left',

});


const PopupContainer = styled.div({ position: 'relative' })
const Popup = styled.div({
    position: 'absolute',
    top: 35,
    left: -13,
    width: 400,
    backgroundColor: LightColors.white,
    padding: 15,
    zIndex: 100,
    borderRadius: 8,
    boxShadow: "0px 4px 16px 0px #00000066",
    borderTop: `6px solid ${LightColors.yellow}`,

    //styleName: UI Text/Medium 1 - Open Sans Regular 16pt;
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 400,

    letterSpacing: '0em',
    textAlign: 'left',

});

const Name = styled.div({
    fontFamily: 'Open Sans',
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: '0em',
});

const Close = styled.div({
    position: 'absolute',
    top: 10,
    right: 15,
    fontWeight: 700
})

const Link = styled.a({
    label: 'Link',
    display: 'block',
    textAlign: 'left',
    width: '100%',
    color: LightColors.blue, fontWeight: "bold", textDecoration: "none", cursor: "pointer"
});

const TriangleUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${LightColors.yellow};
  position: absolute;
  top: -15px;
  left: 15px;
`;


const StandardPopup = ({ standard, description }) => {
    const [open, toggleOpen] = useToggle();
    return <PopupContainer>
        <InfoBox onClick={toggleOpen} />
        {open && <Popup onClick={toggleOpen}>
            <TriangleUp />
            <Name>{standard}</Name>
            <Close>⨉</Close>
            <hr />
            {description}
        </Popup>}
    </PopupContainer>
}

const StandardReportSummary = ({
    data,
    setCurrentSelection
}) => {
    return <>
        <SearchRow>
            <SkillSearch />
            <span>Skill proficiency is based on performance in Practice assignments only.</span>
            <SmallWhiteButton>EXPORT/PRINT</SmallWhiteButton>
        </SearchRow>
        <Table>
            <thead>
                <HeaderRowNoBorder>
                    <Header>STANDARD</Header>
                    <Header>STANDARD DESCRIPTION</Header>
                    <Header>STUDENTS ASSIGNED</Header>
                    <Header>STUDENTS PROFICIENT</Header>
                    <Header>STUDENTS NOT YET PROFICIENT</Header>
                </HeaderRowNoBorder>
                <YellowRow><td colSpan={5} /></YellowRow>
            </thead>
            <tbody>
                {/* "standard": "3.NBT.1",
                "domain": "3.NBT Number and Operations in Base Ten",
                "description": "Use place value understanding to round whole numbers to the nearest 10 or 100.",
                "assigned": 4,
                "proficient": 3  */}
                {data.map((row) => (
                    <LightRowNoBorder>
                        <Cell>
                            <Link onClick={() => setCurrentSelection(row)}>{row.standard}</Link></Cell>
                        <Cell>
                            <StandardPopup standard={row.standard} description={row.description} />
                        </Cell>
                        <Cell>{row.assigned}</Cell>
                        <Cell>{row.proficient}</Cell>
                        <Cell>{row.proficient === "--" ? "--" : (row.assigned - row.proficient)}</Cell>
                    </LightRowNoBorder>
                ))}

            </tbody>
        </Table>
    </>
}

const DomainLine = styled.div({
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: '0em',
    textAlign: 'left',

})

const StandardLine = styled.div({
    //styleName: UI Text/Big 2 - Open Sans Bold 22pt;
    fontFamily: 'Open Sans',
    fontSize: 22,
    fontWeight: 700,
    lineHeight: "30px",
    letterSpacing: '0em',
    textAlign: 'left',
    margin: "20px 0px"
})

const StandardReportDetail = ({
    onClose,
    standard,
    domain,
    grade,
    description,
    assigned }) => {
    const grade3Plus = grade > 1;
    return <>
        <Link style={{ margin: "20px 0px" }} onClick={onClose}>&lt; Back to Standard Report</Link>
        <DomainLine>{domain}</DomainLine>
        <StandardLine>{standard} {description}</StandardLine>
        <Table>
            <thead>
                <HeaderRowNoBorder>
                    <Header>STUDENT NAME</Header>
                    <Header>GUIDED LESSON SCORE</Header>
                    <Header>INDEPENDENT PRACTICE PROFICIENCY</Header>
                    <Header>PROFICIENCY DATE</Header>
                    <Header>TIME ON STANDARD</Header>
                </HeaderRowNoBorder>
                <YellowRow><td colSpan={5} /></YellowRow>
            </thead>
            <tbody>


                <LightRowNoBorder>
                    <Cell>
                        <b>Masey, Bernard</b>
                    </Cell>
                    <Cell>30/30 (100%)</Cell>
                    <Cell>
                        {grade3Plus ? "Proficient (85%)" : "--"}
                    </Cell>
                    <Cell>
                        {grade3Plus ? "9/17/22" : "-"}
                    </Cell>
                    <Cell>2h 56min</Cell>
                </LightRowNoBorder>
                <LightRowNoBorder>
                    <Cell>
                        <b>Cruz, Neil</b>
                    </Cell>
                    <Cell>20/30 (66%)</Cell>
                    <Cell>
                        {grade3Plus ? "Proficient (47%)" : "--"}


                    </Cell>
                    <Cell>-</Cell>
                    <Cell>2h 48min</Cell>
                </LightRowNoBorder>

                <LightRowNoBorder>
                    <Cell>
                        <b>Drass, Anita</b>
                    </Cell>
                    <Cell>-</Cell>
                    <Cell>
                        {grade3Plus ? "Not Started" : "--"}
                    </Cell>
                    <Cell>-</Cell>
                    <Cell>0min</Cell>
                </LightRowNoBorder>

                {assigned > 3 && <LightRowNoBorder>
                    <Cell>
                        <b>Guzman, Franklin</b>
                    </Cell>
                    <Cell>
                        {grade3Plus ? "26/35 (74%)" : "15/21 (71%)"}
                    </Cell>
                    <Cell>
                        {grade3Plus ? "Proficient (88%)" : "--"}
                    </Cell>
                    <Cell>
                        {grade3Plus ? "9/27/22" : "-"}


                    </Cell>
                    <Cell>2h 56min</Cell>
                </LightRowNoBorder>
                }
                {assigned > 4 &&
                    <LightRowNoBorder>
                        <Cell>
                            <b>Vargas, Angela</b>
                        </Cell>
                        <Cell>
                            {grade3Plus ? "19/25 (76%)" : "14/18 (77%)"}

                        </Cell>
                        <Cell>
                            {grade3Plus ? "Proficient (82%)" : "--"}
                        </Cell>
                        <Cell>
                            {grade3Plus ? "9/28/22" : "-"}


                        </Cell>
                        <Cell>3h 56min</Cell>
                    </LightRowNoBorder>
                }


            </tbody>
        </Table>
    </>
}