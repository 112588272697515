export const UsageReportIcon = () => (
  <svg
    width="32"
    height="34"
    viewBox="0 0 32 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9742 27.8271C19.3813 25.9182 13.7851 17.6424 13.1508 16.7057C13.0814 16.602 12.981 16.5228 12.864 16.4795C12.7469 16.4362 12.6192 16.4309 12.499 16.4644C12.3787 16.4979 12.2722 16.5685 12.1944 16.6661C12.1167 16.7637 12.0717 16.8834 12.0659 17.0081C12.0007 18.1641 11.4731 28.3428 12.0659 30.2636C12.2171 30.7946 12.4728 31.2901 12.8179 31.7211C13.1631 32.1521 13.5908 32.5099 14.076 32.7735C14.5611 33.0371 15.094 33.2012 15.6435 33.2563C16.1929 33.3113 16.7477 33.2561 17.2756 33.094C17.8034 32.9319 18.2935 32.666 18.7173 32.3121C19.1411 31.9581 19.49 31.5231 19.7435 31.0326C19.997 30.5421 20.1501 30.0059 20.1938 29.4555C20.2375 28.905 20.1709 28.3514 19.9979 27.8271H19.9742Z"
      fill="#449DAC"
    />
    <path
      d="M13.2746 0.213417C10.5392 0.63599 7.93344 1.6671 5.64954 3.23074C3.36565 4.79438 1.46181 6.85067 0.0783759 9.24802C0.00268177 9.38021 -0.0192249 9.53646 0.0171998 9.68437C0.0536245 9.83227 0.145585 9.96048 0.274007 10.0424L6.24372 13.9195C6.31227 13.9639 6.38925 13.9936 6.46984 14.0069C6.55043 14.0202 6.63289 14.0166 6.71205 13.9965C6.79251 13.9764 6.86785 13.9396 6.9332 13.8886C6.99855 13.8375 7.05247 13.7733 7.09146 13.7001C7.80367 12.3408 8.8169 11.1621 10.0539 10.2539C11.2908 9.34574 12.7188 8.73202 14.229 8.45957L13.2746 0.213417Z"
      fill="#449DAC"
    />
    <path
      d="M31.9379 9.24802C30.3258 6.44608 28.0057 4.11692 25.2101 2.49385C22.4145 0.870787 19.2414 0.0107891 16.0088 0C15.4871 0 14.9655 0.0296411 14.4497 0.0770668L15.416 8.2995H16.0088C17.8421 8.30578 19.6389 8.81199 21.2058 9.76362C22.7727 10.7153 24.0503 12.0763 24.9012 13.7001C24.9395 13.7727 24.9925 13.8365 25.0568 13.8875C25.1211 13.9385 25.1953 13.9757 25.2746 13.9965C25.3239 14.0022 25.3736 14.0022 25.4228 13.9965C25.5391 13.9956 25.6524 13.9606 25.7489 13.8957L31.7186 10.0187C31.845 9.94097 31.9376 9.8187 31.9782 9.67602C32.0188 9.53334 32.0044 9.38063 31.9379 9.24802Z"
      fill="#449DAC"
    />
  </svg>
);
