export const SkillsStatusReportIcon = () => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.63256 7.59856C3.71952 7.68481 3.83689 7.73344 3.95937 7.73395H5.8735L9.6831 11.5482L11.7466 9.87218L7.74095 5.8665V3.95237C7.74044 3.82989 7.69181 3.71252 7.60556 3.62556L4.09942 0.138097C4.05602 0.0943389 4.00439 0.059607 3.9475 0.035905C3.8906 0.012203 3.82958 0 3.76795 0C3.70632 0 3.6453 0.012203 3.58841 0.035905C3.53152 0.059607 3.47988 0.0943389 3.43648 0.138097L0.13576 3.44349C0.0488066 3.53096 0 3.64929 0 3.77262C0 3.89596 0.0488066 4.01429 0.13576 4.10176L3.63256 7.59856Z"
      fill="#449DAC"
    />
    <path
      d="M19.81 14.2093L14.7913 20.3953L21.2433 26.852C21.9943 27.5545 22.9889 27.9376 24.0171 27.9205C25.0453 27.9033 26.0266 27.4873 26.7538 26.7601C27.4809 26.033 27.897 25.0517 27.9142 24.0234C27.9313 22.9952 27.5482 22.0006 26.8457 21.2496L19.81 14.2093ZM23.6757 23.6773C23.6323 23.7211 23.5806 23.7558 23.5237 23.7795C23.4668 23.8032 23.4058 23.8154 23.3442 23.8154C23.2826 23.8154 23.2215 23.8032 23.1646 23.7795C23.1077 23.7558 23.0561 23.7211 23.0127 23.6773L19.2778 19.9424C19.1899 19.8545 19.1405 19.7353 19.1405 19.6109C19.1405 19.4866 19.1899 19.3674 19.2778 19.2795C19.3657 19.1916 19.485 19.1422 19.6093 19.1422C19.7336 19.1422 19.8528 19.1916 19.9408 19.2795L23.6757 23.0144C23.7194 23.0578 23.7541 23.1094 23.7778 23.1663C23.8016 23.2232 23.8138 23.2842 23.8138 23.3458C23.8138 23.4075 23.8016 23.4685 23.7778 23.5254C23.7541 23.5823 23.7194 23.6339 23.6757 23.6773Z"
      fill="#449DAC"
    />
    <path
      d="M27.6002 4.28853L24.3788 7.50988C24.2913 7.59683 24.173 7.64564 24.0497 7.64564C23.9263 7.64564 23.808 7.59683 23.7205 7.50988L20.4992 4.28853C20.4554 4.24513 20.4207 4.19349 20.397 4.1366C20.3733 4.07971 20.3611 4.01869 20.3611 3.95705C20.3611 3.89542 20.3733 3.8344 20.397 3.77751C20.4207 3.72062 20.4554 3.66898 20.4992 3.62558L23.7159 0.408898C22.7291 0.0487617 21.6701 -0.0682579 20.6286 0.0677506C19.587 0.203759 18.5936 0.588789 17.7325 1.19023C16.8713 1.79167 16.1678 2.59179 15.6815 3.52284C15.1952 4.45388 14.9404 5.48841 14.9388 6.5388C14.9418 7.13395 15.0251 7.72598 15.1863 8.29888L1.96472 19.0367C1.41965 19.4557 0.966565 19.9823 0.633613 20.5838C0.300661 21.1852 0.0949559 21.8488 0.02928 22.5331C-0.0363958 23.2175 0.0393603 23.908 0.251842 24.5618C0.464323 25.2156 0.808989 25.8188 1.26443 26.3338C1.7459 26.8719 2.33784 27.2999 2.99982 27.5883C3.6618 27.8768 4.37824 28.019 5.10021 28.0053C5.82218 27.9915 6.53268 27.8221 7.18321 27.5087C7.83374 27.1953 8.40896 26.7451 8.86962 26.1891L19.7008 12.8321C20.8595 13.1566 22.0852 13.1549 23.243 12.8273C24.4008 12.4997 25.4457 11.8589 26.2625 10.9754C27.0794 10.0919 27.6365 9.0001 27.8725 7.82023C28.1086 6.64037 28.0143 5.41825 27.6002 4.28853ZM5.60158 23.8127C5.32457 23.8127 5.05378 23.7306 4.82345 23.5767C4.59313 23.4228 4.41361 23.204 4.30761 22.9481C4.2016 22.6922 4.17386 22.4106 4.2279 22.1389C4.28195 21.8672 4.41534 21.6176 4.61121 21.4218C4.80709 21.2259 5.05665 21.0925 5.32834 21.0385C5.60003 20.9844 5.88164 21.0122 6.13756 21.1182C6.39349 21.2242 6.61223 21.4037 6.76613 21.634C6.92002 21.8643 7.00217 22.1351 7.00217 22.4121C7.00217 22.7836 6.85461 23.1398 6.59195 23.4025C6.32928 23.6652 5.97304 23.8127 5.60158 23.8127Z"
      fill="#449DAC"
    />
  </svg>
);
