import React, { useState } from "react";

import DropDown from "../../../components/DropDown";

import EntryRow from "./EntryRow";

import data from "../../../data/assignments.json";
import removeIcon from "./removeIcon.png";
import CreateAssignmentsBar from "../../../components/CreateAssignmentsBar";


import LessonPage from "./Pages/LessonPage";
import GoalPage from "./Pages/GoalPage";
import styled from "@emotion/styled";
import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../data/rollovertips";
import { HeaderRow } from "./HeaderRow";

import TrackAssignmentDropdown from "./TrackAssignmentDropdown.module.css";
import { BlueButton } from "../../../components/BlueButton";
import { Table, YellowRow } from "../../../components/ReportTables";

import StudentFilter from "./StudentFilter";
import ContentFilter from "./ContentFilter";
import AssignmentFilter from "./AssignmentFilter";


import SearchBox from "./SearchBox";
const TrackAssignmentsRoot = styled.div({
  label: "TrackAssignmentsRoot",
  width: "100%",
  paddingBottom: 100,
});

const RemoveIcon = styled.img({
  label: "RemoveIcon",
  position: "relative",
  top: -5,
  marginRight: 2,
});
const Footer = styled.div({
  label: "Footer",
  margin: 15,
  display: "flex",
});
const Page = styled.div({
  label: "Page",
  flexGrow: 1,
  paddingTop: 10,
  fontSize: 20,
  fontWeight: "bold",
  textAlign: "center",
});

const Left = styled.div({
  label: "Left",
  width: "100%",
  marginRight: 0,
  marginTop: 20,
  "table": {
    marginTop: 0,
    marginBottom: 0
  }
});

const ReportBody = styled.div({
  label: "ReportBody",
  display: "flex",
});

const ReportOptions = styled.div({
  label: "ReportOptions",
  marginLeft: 10,
  display: "flex",
  marginTop: 17,
  gap: 20,
  paddingLeft: 11,
  justifyContent: "flex-start",

});
const Legend = styled.div({
  label: "Legend",
  display: "flex",
  flexDirection: "row",
  gap: 25,
  margin: 13,
});
const LegendOption = styled.div({
  display: "flex",
});
const LegendCircle = styled.div(({ color }) => ({
  marginTop: 5,
  marginRight: 5,
  height: 12,
  width: 12,
  borderRadius: 12,
  backgroundColor: color,
}));



const TrackAssignments = (props) => {
  const [assignmentIndex, setAssignmentIndex] = useState(0);
  const [contentIndex, setContentIndex] = useState(0);

  const [selected, setSelected] = useState([]);
  const [pageData, setPageData] = useState(null);

  const selectAllHandler = () => {
    if (selected.length < data[props.subject][props.grade].length) {
      setSelected(data[props.subject][props.grade].map((x, index) => index));
    } else {
      setSelected([]);
    }
  };

  const selectEntryHandler = (p) => {
    let d = [...selected];

    if (d.find((x) => x == p.index) != null) d = d.filter((x) => x != p.index);
    else d.push(p.index);

    setSelected(d);
  };

  const openPageHandler = (p) => {
    setPageData(p.data);
  };

  const closePageHandler = () => {
    setPageData(null);
  };

  const entries = data[props.subject][props.grade].map((x, index) => (
    <EntryRow
      key={index}
      index={index}
      onOpen={openPageHandler}
      onSelect={selectEntryHandler}
      selected={selected.find((x) => x == index) != null}
      data={x}

    />
  ));

  let page = (
    <div>
      <ReportOptions>
        <SearchBox
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "TA_vertical SearchAssignments", "150px", 0)
          }
          onMouseLeave={rolloverTipEnd} />

        <StudentFilter onMouseEnter={(ev) =>
          rolloverTipBegin(ev, "TA_studentsdropdown", "150px", 0)
        }
          onMouseLeave={rolloverTipEnd} />
        <ContentFilter onMouseEnter={(ev) =>
          rolloverTipBegin(ev, "TA_contenttypedropdown", "150px", 0)
        }
          onMouseLeave={rolloverTipEnd} />
        <AssignmentFilter onMouseEnter={(ev) =>
          rolloverTipBegin(ev, "TA_statusdropdown", "150px", 0)
        }
          onMouseLeave={rolloverTipEnd} />


      </ReportOptions>
      {/* <Legend>
        LEGEND:
        <LegendOption>
          <LegendCircle color="#ED1F24" /> NEEDS HELP
        </LegendOption>
        <LegendOption>
          <LegendCircle color="#0288C5" /> MAKING PROGRESS
        </LegendOption>
        <LegendOption>
          <LegendCircle color="#75B857" /> COMPLETED
        </LegendOption>
        <LegendOption>
          <LegendCircle color="#5F5F5F" /> NOT STARTED
        </LegendOption>
        <LegendOption>
          <RemoveIcon src={removeIcon} />
          REMOVE
        </LegendOption>
      </Legend> */}

      <ReportBody>
        <Left>
          <Table>
            <HeaderRow
              selected={
                data[props.subject][props.grade].length == selected.length
              }
              onSelect={selectAllHandler}
            />
            <tbody>
              <YellowRow><td colSPan={10} /></YellowRow>
              {entries}
            </tbody>
          </Table>

        </Left>


        {/* <div className={css.unassign}>
              <Garbage />
              <div className={css.text}>UNASSIGN</div>
            </div> */}

        <CreateAssignmentsBar
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "TA_vertical EditAssignments", "150px", -85)
          }
          onMouseLeave={rolloverTipEnd}
        >
          Edit Assignments
        </CreateAssignmentsBar>
      </ReportBody>
      <Footer>
        <BlueButton>SAVE CHANGES</BlueButton>
        <Page>Page 1 of 2 &gt;</Page>
      </Footer>
    </div>
  );

  if (pageData) {
    switch (pageData.type) {

      case "Practice":
      case "goal":
        page = (
          <GoalPage
            {...props}
            onClose={closePageHandler}
            title={pageData.assignment}
          />
        );
        break;
      case "Skill Quiz":
      case "skill":
      case "learn":
      case "lesson":
        page = (
          <LessonPage
            {...props}
            onClose={closePageHandler}
            title={pageData.assignment}
          />
        );
        break;
    }
  }

  return <TrackAssignmentsRoot>{page}</TrackAssignmentsRoot>;
};

export default TrackAssignments;
