import React, { useState } from "react";
import styled from "@emotion/styled";
import { LightColors } from "../../light-theme";

const Tabs = styled.div({
  display: "flex",
  marginTop: 15,
  marginLeft: 20,
  marginRight: 10,
  flexDirection: "row",
  borderBottom: `2px solid ${LightColors.blue2}`,
});

const Tab = styled.div(({ selected }) => ({
  textTransform: "uppercase",
  width: 225,
  height: 60,
  position: "relative",
  color: selected ? LightColors.white : LightColors.black,
  fontWeight: "bold",
  padding: "0px 32px",
  boxSizing: "border-box",
  textAlign: "center",
  top: 2,
  backgroundColor: selected ? LightColors.blue2 : LightColors.white,
  // borderLeft: "2px solid #96BFC7",
  // borderTop: "2px solid #96BFC7",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  borderBottom: `2px solid ${LightColors.blue2}`,
  borderRadius: "5px 5px 0px 0px",
}));

const tabs = {
  1: ["Track Assignments", "Standard Report"], //"Student Report"], //, "Skill Report", ];
  3: ["Track Assignments", "Standard Report"], //"Student Report"], //, "Skill Report", ],
  7: ["Track Assignments", "Standard Report"], //"Student Report"], //, "Skill Report", ],
};

const keys = {
  "Track Assignments": "track",
  "Student Report": "student",
  "Skill Report": "skill",
  "Standard Report": "national",
  //"Growth Report": "growth",
  //"Other Reports": "other",
};

export const ReportTabs = ({ onChange, value, grade }) => {
  const pickTab = (key) => (event) => {
    onChange && onChange(key);
  };

  return (
    <Tabs>
      {tabs[grade].map((tabText) => {
        const key = keys[tabText];
        return (
          <Tab key={key} onClick={pickTab(key)} selected={key === value}>
            <span>{tabText}</span>
          </Tab>
        );
      })}
    </Tabs>
  );
};
