import React from "react";

export const SkillsIcon = ({ color = "#056B90" }) => (
  <svg
    width="31"
    height="26"
    viewBox="0 0 31 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4833 3.37629C21.68 3.29972 21.8969 3.42499 21.9289 3.63358L22.8576 9.68463C22.8996 9.95813 22.6064 10.1586 22.3668 10.0203L15.7334 6.19048C15.4937 6.05213 15.5208 5.69796 15.7786 5.59756L21.4833 3.37629Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1785 7.07455C14.9173 6.92372 14.5884 6.9685 14.3827 7.18912C13.6836 7.93892 12.1164 9.68375 11.2995 11.0985C10.4827 12.5133 9.75527 14.7429 9.45549 15.7233C9.36728 16.0118 9.49295 16.319 9.75419 16.4698L16.4544 20.3382C16.7157 20.489 17.0446 20.4442 17.2503 20.2236C17.9494 19.4738 19.5166 17.729 20.3334 16.3142C21.1503 14.8995 21.8777 12.6698 22.1775 11.6894C22.2657 11.401 22.14 11.0938 21.8788 10.9429L15.1785 7.07455ZM15.1907 14.7905C16.0887 15.309 17.2371 15.0013 17.7556 14.1032C18.2741 13.2051 17.9664 12.0568 17.0683 11.5383C16.1702 11.0198 15.0219 11.3275 14.5034 12.2255C13.9849 13.1236 14.2926 14.272 15.1907 14.7905Z"
      fill={color}
    />
    <path
      d="M13.3739 21.6916C13.0252 22.2957 11.7019 22.8886 10.8729 23.212C10.5073 23.3546 10.1148 23.128 10.0555 22.7401C9.92115 21.8605 9.77295 20.4181 10.1217 19.814C10.6402 18.9159 11.7886 18.6082 12.6866 19.1267C13.5847 19.6452 13.8924 20.7936 13.3739 21.6916Z"
      fill={color}
    />
    <path
      d="M21.2671 15.801C21.4415 15.4435 22.1032 15.6404 22.0367 16.0325L20.953 22.422C20.9145 22.6486 20.662 22.7674 20.4629 22.6525L17.6215 21.0119C17.6215 21.0119 19.063 19.4418 19.8395 18.3251C20.3378 17.6085 20.8352 16.6862 21.2671 15.801Z"
      fill={color}
    />
    <path
      d="M11.2773 10.0335C11.4996 9.70374 10.9983 9.22905 10.6919 9.48273L5.70036 13.616C5.52335 13.7626 5.54677 14.0407 5.7458 14.1556L8.58727 15.7962C8.58727 15.7962 9.22631 13.7627 9.80514 12.5318C10.1766 11.742 10.7266 10.8501 11.2773 10.0335Z"
      fill={color}
    />
  </svg>
);
