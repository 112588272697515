import React from "react";
import styled from "@emotion/styled";
import { StrandDetails } from "./StrandDetail";
import { LightColors } from "../../../../light-theme";

const Name = styled.div({
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,
  textAlign: "left",
  display: "flex",
  gap: 20,
  marginLeft: -21,
});
const SkillName = ({ detail, toggleLessons }) => (
  <Name>
    <input
      type="checkbox"
      onChange={toggleLessons(detail.practice.map((p) => p.id))}
    />
    {detail.title}
  </Name>
);

const mapToBlocks = (strand) => {
  const order = {};
  const byBlock = strand.practice.reduce((acc, curr) => {
    if (!curr.contentBlockName) {
      return acc;
    }
    const thisBlock = [...(acc[curr.contentBlockName] || []), curr];
    order[curr.contentBlockName] = curr.contentBlockOrder;
    return {
      ...acc,
      [curr.contentBlockName]: thisBlock,
    };
  }, {});

  return Object.keys(byBlock)
    .map((key) => ({
      title: key,
      practice: byBlock[key],
    }))
    .sort((a, b) => order[a.title] - order[b.title]);
};

export const StrandBlocks = ({
  classGrade,
  grade,
  label,
  groups,
  languageIndex,
}) => {
  return (
    <StrandDetails
      languageIndex={languageIndex}
      groups={groups}
      strandType="block"
      expanding={false}
      classGrade={classGrade}
      grade={grade}
      label={label}
      headerBg={LightColors.white}
      mapToDetail={mapToBlocks}
      renderDetailName={SkillName}
      yellowBar={true}
    />
  );
};
