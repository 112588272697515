import * as React from "react"

export function MoveDots(props) {
    return (
        <svg
            width={16}
            height={24}
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={4.5} cy={5.5} r={1.5} fill="#6A6D6D" />
            <circle cx={4.5} cy={12.5} r={1.5} fill="#6A6D6D" />
            <circle cx={11.5} cy={5.5} r={1.5} fill="#6A6D6D" />
            <circle cx={11.5} cy={12.5} r={1.5} fill="#6A6D6D" />
            <circle cx={4.5} cy={19.5} r={1.5} fill="#6A6D6D" />
            <circle cx={11.5} cy={19.5} r={1.5} fill="#6A6D6D" />
        </svg>
    )
}

export default MoveDots
