import React from "react";
import css from "./Box.module.css";

const Box = (props) => {
  const style = { ...props.style };
  style.background = props.background;
  style.width = props.width;
  style.minWidth = props.width;

  if (props.borderTop) style.borderTop = "1px solid #D9D9D9";

  if (props.borderLeft) style.borderLeft = "1px solid #D9D9D9";

  if (props.borderRight) style.borderRight = "1px solid #D9D9D9";

  if (props.borderBottom) style.borderBottom = "1px solid #D9D9D9";

  return (
    <div style={style} className={css.root}>
      {props.children}
    </div>
  );
};

export default Box;
