import * as React from "react"

function GreyXCircle(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={10} cy={10} r={10} fill="#6A6D6D" />
            <path
                d="M11.435 10l3.271 3.304-1.402 1.402L10 11.436l-3.304 3.27-1.402-1.402L8.565 10l-3.27-3.304 1.401-1.402L10 8.564l3.304-3.27 1.402 1.402L11.436 10z"
                fill="#FBFCFD"
            />
        </svg>
    )
}

export default GreyXCircle
