import React from "react";
import css from "./Popup.module.css";

import Bold from "../../../../../../components/Bold";
import Link from "../../../../../../components/Link";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

const Popup = (props) => {
  //const popupOffset = offset(props.data.ref);

  //console.log(popupOffset);

  let title;
  let contents = (
    <div className={css.header}>
      <div className={css.row}>
        <div className={css.text}>SKILLS COVERED</div>
        <div
          className={css.preview}
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "FC_Student Practice_previewbutton")
          }
          onMouseLeave={rolloverTipEnd}
        >
          Preview
        </div>
      </div>
      <div className={css.line} />
    </div>
  );

  switch (props.data.props.type) {
    case "skills":
      title = "About This Skill Boost";
      break;
    case "goals":
      title = "About This Learning Goal";
      contents = null;
      break;

    case "games":
      title = "About This Game";
      break;
    case "lessons":
    default:
      title = "About This Lesson";
      break;
  }

  const style = {};

  const closeHandler = () => {
    if (props.onClose) props.onClose();
  };

  const entries = props.data.props.data.popup.entries.map((entry, index) => (
    <div key={index}>
      <div>
        <Bold>{entry.title}</Bold>
      </div>
      <div>{entry.description}</div>
      {props.data.props.type == "goals" ? (
        <div
          className={css.preview}
          onMouseEnter={(ev) => rolloverTipBegin(ev, "FC_Preview_item")}
          onMouseLeave={rolloverTipEnd}
        >
          Preview Item
        </div>
      ) : null}
      {index + 1 < props.data.props.data.popup.entries.length ? (
        <div className={css.line} />
      ) : null}
    </div>
  ));

  const bottomStyle = {};
  if (props.data.props.type != "goals") bottomStyle.paddingBottom = "50px";

  return (
    <div className={css.root}>
      <div className={css.popup} style={style}>
        <div className={css.top}>
          <div className={css.header}>
            <div className={css.title}>{title}</div>
            <img
              onMouseDown={closeHandler}
              className={css.close}
              src="https://practice.gogetwaggle.com/teacher/assets/images/icon-close-white.png?v=3.0"
            />
          </div>
          {props.data.props.type == "goals" ? (
            <div className={css.goals}>
              <Link style={{ width: "90px" }} size="14px" div color="white">
                Goals To Assign Before
              </Link>
              <div className={css.skills}>Skills Covered In This Goal</div>
              <Link style={{ width: "90px" }} size="14px" div color="white">
                Goals To Assign After
              </Link>
              <img
                className={css.left}
                src="https://practice.gogetwaggle.com/teacher/assets/images/content-more-info-beforehand.png?v=3.0"
              />
              <img
                className={css.right}
                src="https://practice.gogetwaggle.com/teacher/assets/images/content-more-info-afterward.png?v=3.0"
              />
            </div>
          ) : null}
        </div>
        <div style={bottomStyle} className={css.bottom}>
          {contents}
          <div className={css.entries}>{entries}</div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
