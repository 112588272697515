import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { StrandBlocks } from "./StrandBlocks";
import { StrandIndex } from "./StrandIndex";
import { StrandSkills } from "./StrandSkills";
import { StrandStandards } from "./StrandStandards";

export const ContentByStrand = ({
  classGrade,
  grade,
  label,
  groups,
  languageIndex,
}) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}/:strandId/standards/:substandard/:childstandard`}>
        <StrandStandards
          classGrade={classGrade}
          grade={grade}
          label={label}
          groups={groups}
          languageIndex={languageIndex}
        />
      </Route> */}
      <Route path={`${path}/:strandId/standards/:substandard`}>
        <StrandStandards
          classGrade={classGrade}
          grade={grade}
          label={label}
          groups={groups}
          languageIndex={languageIndex}
        />
      </Route>
      <Route path={`${path}/:strandId/standards`}>
        <StrandStandards
          classGrade={classGrade}
          grade={grade}
          label={label}
          groups={groups}
          languageIndex={languageIndex}
        />
      </Route>
      <Route path={`${path}/:strandId/skills`}>
        <StrandSkills
          classGrade={classGrade}
          grade={grade}
          label={label}
          groups={groups}
          languageIndex={languageIndex}
        />
      </Route>
      <Route path={`${path}/:strandId/blocks`}>
        <StrandBlocks
          classGrade={classGrade}
          grade={grade}
          label={label}
          groups={groups}
          languageIndex={languageIndex}
        />
      </Route>
      <Route>
        <StrandIndex
          classGrade={classGrade}
          grade={grade}
          label={label}
          groups={groups}
        />
      </Route>
    </Switch>
  );
};
