import React from "react";
import css from "./Trend.module.css";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipCustomBegin,
  rolloverTipEnd,
} from "../../../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const Trend = (props) => {
  const colors = ["#683995", "#7AB946", "#F89D02", "#1886C7", "#D92128"];

  const mouseDownHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div className={css.root}>
      <div
        onMouseDown={mouseDownHandler}
        onMouseEnter={(ev) => {
          if (props.rollover) rolloverTipBegin(ev, props.rollover);
          else if (props.rolloverTitle)
            rolloverTipCustomBegin(ev, props.rolloverTitle, props.rolloverText);
        }}
        onMouseLeave={rolloverTipEnd}
        style={{ background: props.color || colors[props.index] }}
        className={css.icon}
      />
      <div className={css.text}>{props.value}</div>
      <div className={css.student}>{`STUDENT${
        props.value > 1 ? "S" : ""
      }`}</div>
    </div>
  );
};

export default Trend;
