import styled from "@emotion/styled";
import { InfoIcon } from "../../../FindContent2/by-strand/InfoIcon";
import { LinkButton } from "../../../../../components/BlueButton";
import { useToggle } from "../../../../../../../common/util/useToggle";

import tiersImage from "./gemtiers.png";

const Close = styled.div`
  background-color: #fcb900;
  margin: -10px -10px 10px -10px;
  text-align: right;
  padding: 10px;
`;

export const LeaderBoardInfo = () => {
  const [isOpen, toggleOpen] = useToggle();
  return (
    <Info onClick={toggleOpen}>
      <div style={{ marginBottom: 5 }}>
        Leaderboard helps track students' success through Gems and Points
        <InfoIcon
          style={{ position: "relative", top: 5, marginLeft: 20 }}
        />{" "}
        <LinkButton>More Info</LinkButton>
      </div>

      {isOpen && (
        <Sidebar>
          <Close>⨉</Close>

          <b>Leaderboard</b>
          <p>
            Gems are earned during Practice assignments. If a student gets the
            correct answer on the first try consecutively, they will earn a Gem.
            If the streak ends, the gem tracker resets back to zero. Every 3
            questions correct on the first try, the gem tier goes up, with the
            exception of the Diamond. A student only earns a Diamond if they
            answer every question correctly on the first try (a perfect score).
          </p>

          <p>Please refer to the list below.</p>
          <img src={tiersImage} alt="Gem Tiers" />
          <p>
            Students can also earn points. As they progress through Waggle and
            complete assignments, students will earn points that will be added
            to their total score.
          </p>
        </Sidebar>
      )}
    </Info>
  );
};

const Info = styled.div({});

const Sidebar = styled.div`
  pointer-events: all;

  position: fixed;
  top: 120px;
  bottom: 0px;
  overflow-y: auto;
  right: 0;
  width: 300px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-left: 2px solid #e0e0e0;
`;
