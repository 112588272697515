import React, { useState } from "react";
import styled from "@emotion/styled";
import { ReportTabs } from "./ReportTabs";
import TrackAssignments from "./TrackAssignments";
import Growth from "./Growth";
import { OtherReports } from "./OtherReports/OtherReports";
import { useHistory, useParams } from "react-router-dom";
import { useGradeSubject } from "../../routing-util";
import { LightColors } from "../../light-theme";
import { StudentReport } from "./StudentReport/StudentReport";
import { SkillReport } from "./SkillReport/SkillReport";
import { NationalStandardReport } from "./NationalStandardReport/NationalStandardReport";

const ReportsContainer = styled.div({
  backgroundColor: LightColors.white,
  paddingTop: 10,
});

export const Reports = ({ params }) => {
  const history = useHistory();
  const { subject, grade } = useGradeSubject();
  const { reportType } = useParams();
  const activeReport = reportType || "track";

  const reportTab =
    activeReport === "student_detail" ? "student" : activeReport;

  const setActiveReport = (reportName) => {
    history.push(`reports-${subject}-${grade}-${reportName}`);
  };

  return (
    <ReportsContainer>
      <ReportTabs value={reportTab} onChange={setActiveReport} grade={grade} />

      {activeReport === "student_detail" && (
        <StudentReport
          key={`${subject}_${grade}`}
          detail={true}
          subject={subject}
          grade={grade}
        />
      )}

      {activeReport === "student" && (
        <StudentReport
          key={`${subject}_${grade}`}
          subject={subject}
          grade={grade}
        />
      )}

      {activeReport === "skill" && (
        <SkillReport
          key={`${subject}_${grade}`}
          subject={subject}
          grade={grade}
        />
      )}

      {activeReport === "national" && (
        <NationalStandardReport
          key={`${subject}_${grade}`}
          subject={subject}
          grade={grade}
        />
      )}

      {activeReport === "track" && (
        <TrackAssignments
          key={`${subject}_${grade}`}
          subject={subject}
          grade={grade}
        />
      )}

      {activeReport === "growth" && (
        <Growth key={`${subject}_${grade}`} subject={subject} grade={grade} />
      )}

      {activeReport === "other" && (
        <OtherReports subject={subject} grade={grade} />
      )}
    </ReportsContainer>
  );
};
