import React from "react";
import Link from "../../../../components/Link";
import Row from "../Row";
import css from "./EntryRow.module.css";
import printIcon from "./printIcon.png";
import profileIcon from "./viewProfileIcon.png";
import { TinyBlueButton } from "../../../../components/BlueButton";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

const EntryRow = (props) => {
  const viewReportHandler = () => {
    props.onViewReport(props);
  };
  return (
    <div className={css.root + (props.bottom ? " " + css.bottom : "")}>
      <Row>
        <div className={css.link}>{props.name}</div>
        <div>
          <TinyBlueButton
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "Home_roster_turnonELbutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            <img
              style={{
                marginRight: "4px",
              }}
              src="https://practice.gogetwaggle.com/teacher/assets/images/power.svg"
            />
            <span>Turn On</span>
          </TinyBlueButton>
        </div>
        <div>{props.id}</div>
        <div>
          <TinyBlueButton
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "Home_roster_turnonvocabbutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            <img
              style={{
                marginRight: "5px",
              }}
              src="https://practice.gogetwaggle.com/teacher/assets/images/power.svg"
            />
            <span>Turn On</span>
          </TinyBlueButton>
        </div>
        <div>
          <TinyBlueButton
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "Home_roster_viewprofilebutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            <img
              style={{
                marginRight: "4px",
              }}
              src={profileIcon}
            />
            <span>View Profile</span>
          </TinyBlueButton>
        </div>
        <div>
          <TinyBlueButton
            onMouseEnter={(ev) =>
              rolloverTipBegin(ev, "Home_roster_printbutton")
            }
            onMouseLeave={rolloverTipEnd}
          >
            <img
              style={{
                marginRight: "4px",
              }}
              src={printIcon}
            />

            <span>Print</span>
          </TinyBlueButton>
        </div>
        <div
          onMouseEnter={(ev) =>
            rolloverTipBegin(ev, "Home_roster_viewreportbutton")
          }
          onMouseLeave={rolloverTipEnd}
          onMouseDown={viewReportHandler}
          className={css.link}
        >
          View Report
        </div>
      </Row>
    </div>
  );
};

export default EntryRow;
