import styled from "@emotion/styled";
import React from "react";
import { BlueButton, SmallWhiteButton, SmallBlueButton } from "../../../components/BlueButton";
import { rolloverTipBegin, rolloverTipEnd } from "../../../data/rollovertips";
import { LightColors } from "../../../light-theme";

const Backdrop = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(180,180,180,0.25)",
  zIndex: 99,
});
const AboutLessonContainer = styled.div({
  backgroundColor: LightColors.white,
  borderRadius: 8,  
  position: "fixed",
  top: 300,
  left: "50%",
  marginLeft: -250,
  width: 495,
  height: 416,
  zIndex: 100,
  boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.40)"

});
const Options = styled.div({
  display: "flex",
  alignItems: "center",
  paddingBottom: 10,
  justifyContent: "space-between",
  borderBottom: `1px solid ${LightColors.grey1}`,
  marginBottom: 10,
});
const Heading = styled.div({
  fontFamily: "Open Sans",
  fontSize: 20,
  color: LightColors.black,
  textAlign: "center",
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,

  padding: 15,
  backgroundColor: LightColors.white,
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  borderTop: `8px solid ${LightColors.yellow}`,
  borderBottom: `1px solid ${LightColors.grey1}`
});

const CloseButton = styled.button({
  cursor: "pointer",
  fontFamily: "Open Sans",
  fontSize: 24,
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,
  textAlign: "center",
  color: LightColors.black,
  backgroundColor: LightColors.white,
  border: "none",
  outline: "none",
  position: "absolute",
  right: 10,
  top: 8,
});

const Content = styled.div({
  padding: 15,
  overflow: "auto",
  height: 320,
  "& b": {
    fontSize: 14,
  },
});
export const AboutLessonPopup = ({ lesson, onClose }) => {
  return (
    <Backdrop>
      <AboutLessonContainer>
        <Heading>
          {lesson.title}
          <CloseButton onClick={onClose}>x</CloseButton>
        </Heading>
        <Content>
          <Options>
            <span>Skills Covered</span>
            <SmallWhiteButton
              onMouseEnter={(ev) => rolloverTipBegin(ev, "TA_NotAvailable")}
              onMouseLeave={rolloverTipEnd}
            >
              Preview
            </SmallWhiteButton>
          </Options>
          {lesson.popup.entries.map((entry, index) => (
            <div key={index}>
              <b>{entry.title}</b>
              <br />
              <span dangerouslySetInnerHTML={{ __html: entry.description }} />
            </div>
          ))}
        </Content>
      </AboutLessonContainer>
    </Backdrop>
  );
};
