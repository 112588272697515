import { useCallback, useState } from "react";

export const useToggle = (defaultValue = false) => {
  const [s, setS] = useState(defaultValue);
  const toggleOn = useCallback(() => setS(true), []);
  const toggleOff = useCallback(() => setS(false), []);
  const toggle = useCallback(() => setS((v) => !v), []);

  return [s, toggle, toggleOn, toggleOff];
};
