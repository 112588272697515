import * as React from "react"

function GreenCheckCircle(props) {
    return (
        <svg
            width={21}
            height={21}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={10.5} cy={10.5} r={10} fill="#2F8718" />
            <path
                d="M8.969 14.47l-3.763-3.834 1.4-1.427 2.363 2.408 5.425-5.529 1.4 1.427-6.825 6.956z"
                fill="#FBFCFD"
            />
        </svg>
    )
}

export default GreenCheckCircle
