import React from "react";
import css from "./StudentRow.module.css";

const StudentRow = (props) => {
  return (
    <div className={css.root}>
      <div className={css.description}>{props.data.student}</div>
      <div className={css.count}>{`${props.data.value} ${props.type} Gem${
        props.data.value > 1 ? "s" : ""
      }`}</div>
    </div>
  );
};

export default StudentRow;
