import React from "react";
import styled from "@emotion/styled";
import students from "../../../../../data/students.json";
import back from "./assets/back.png";
import {
  Cell,
  Header,
  HeaderRow,
  Row,
  Table,
} from "../../../../../components/ReportTables";
import { LightHeaderRow } from "./WaggleGrowthReport";
import Trend from "./Trend";

const Title = styled.div({
  fontWeight: "bold",
  marginTop: 15,
  fontSize: 22,
  "> small": {
    paddingLeft: 30,
    color: "#333",
    fontWeight: "normal",
    fontSize: 12,
  },
});

const LightHeader = styled(Header)({
  textAlign: "left",
  backgroundColor: "#FBFCFD",
  width: 350,
  "&&": { paddingLeft: 30 },
  boxSizing: "border-box",
});

const ClassHeader = styled(Header)({
  textAlign: "left",
  paddingLeft: 10,
  fontWeight: "inherit",
});

const Detail = styled.div({ padding: 20, paddingBottom: 100 });
const GradeLevel = styled.div({
  fontSize: 20,
});
const HeaderStat = styled.div({
  fontSize: 16,
});
const BackLink = styled.span({
  cursor: "pointer",
  color: "#165B79",
  fontWeight: "bold",
  "> img": {
    marginRight: 6,
    position: "relative",
    top: 7,
  },
});
const HeaderStatLabel = styled.div({ marginBottom: 5 });
const Pair = styled.div({ display: "flex", direction: "row", gap: 30 });
const Labels = styled.div({});
const StatCell = styled(Cell)({ textAlign: "center" });
const StudentRow = styled(Row)({ backgroundColor: "#E0F7FB" });
const getStudent = (id) => students.find((s) => s.id === id);

const levels = ["Above", "On", "Below"];
const scores = [
  [81, 100],
  [60, 80],
  [0, 59],
];

const studentScores = [
  [27, 75],
  [31, 63],
  [18, 68],
  [22, 76],
  [53, 79],
  [44, 73],
  [21, 76],
  [42, 66],
  [28, 60],
];

const Student = ({ index, student, row, lesson }) => {
  const sd = getStudent(student.id);
  const [low, high] = scores[row];
  const spread = high - low;

  sd.scores = sd.scores || {};

  const [start, end] = studentScores[index % studentScores.length];

  return (
    <StudentRow>
      <Cell>{sd.name}</Cell>
      <Cell>Smith, Jane</Cell>
      <StatCell>{start}</StatCell>
      <StatCell>{end}</StatCell>
      <StatCell> ▲ {end - start}</StatCell>
    </StudentRow>
  );
};

export const WaggleGrowthDetail = ({
  classLabel,
  column,
  row,
  students,
  onBack,
}) => (
  <Detail>
    <BackLink onClick={onBack}>
      <img src={back} />
      Back
    </BackLink>
    <Title>
      {column.name}
      <small>Last Updated: 8 min ago</small>
    </Title>
    <Table width="">
      <thead>
        <HeaderRow>
          <ClassHeader colSpan={5}>{classLabel}</ClassHeader>
        </HeaderRow>
        <LightHeaderRow>
          <LightHeader>
            <Pair>
              <Trend row={row} up={true} value={students.length} />
              <Labels>
                <GradeLevel>{levels[row]}</GradeLevel>
                {scores[row].join("-")} SCORE
              </Labels>
            </Pair>
          </LightHeader>
          <LightHeader>
            <HeaderStat>{column.avgProficiency}</HeaderStat>
            <HeaderStatLabel>AVERAGE CLASS PROFICIENCY</HeaderStatLabel>
            <HeaderStat>{column.avgTimeSpent}</HeaderStat>
            <HeaderStatLabel>AVERAGE CLASS TIME</HeaderStatLabel>
          </LightHeader>
          <LightHeader colSpan={3}>
            <GradeLevel>{levels[row]}</GradeLevel>
            GRADE LEVEL ASSIGNMENTS
          </LightHeader>
        </LightHeaderRow>
        <HeaderRow>
          <Header rowSpan={2}>CLASS/STUDENT NAME</Header>
          <Header rowSpan={2}>TEACHER(S)</Header>
          <Header colSpan={3}>AVERAGE PROFICIENCY</Header>
        </HeaderRow>
        <HeaderRow>
          <Header>11/11/2021</Header>
          <Header>5/10/2022</Header>
          <Header>CHANGE</Header>
        </HeaderRow>
      </thead>
      <tbody>
        {students.map((student, index) => (
          <Student
            index={index}
            key={index}
            lesson={column.name}
            student={student}
            row={row}
          />
        ))}
      </tbody>
    </Table>
  </Detail>
);
