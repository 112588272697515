import React from "react";
import Status from "./Status";
import css from "./Summary.module.css";

import { components } from "@indieasy.software/indieasy-components";
import Button from "../../Button";
import { SmallWhiteButton } from "../../../../../../components/BlueButton";
const { CircleChart } = components;

const Summary = (props) => {
  const profNames = ["Minimal", "Partial", "Proficient", "Blank"];
  const mindsetNames = ["High", "Average", "Low"];

  return (
    <div className={css.root}>
      <div className={css.column}>
        <div className={css.title}>Student Status</div>
        <Status
          color="#657276"
          status="Not Started"
          value={props.data.notStarted}
          id="TA_literarystyle_summary_notstartedlink"
        />
        <Status
          color="#ED1F24"
          status="Needs Help"
          value={props.data.needsHelp}
          id="TA_literarystyle_summary_needshelplink"
        />
        <Status
          color="#0388C6"
          status="Making Progress"
          value={props.data.makingProgress}
          id="TA_literarystyle_summary_makingprogresslnk"
        />
        <Status
          color="#4FED8B"
          status="Completed"
          value={props.data.completed}
          id="TA_literarystyle_summary_completedlink"
        />
      </div>
      <div className={css.column}>
        <CircleChart
          dropshadow
          style={{
            marginLeft: "83px",
            marginTop: "-30px",
            pointerEvents: "none",
          }}
          scale="1"
          strokeWidth="25px"
          base={{ stroke: "#657276" }}
          data={[
            { percent: props.data.notStarted * 0.1, stroke: "#657276" },
            { percent: props.data.needsHelp * 0.1, stroke: "#ED1F24" },
            { percent: props.data.makingProgress * 0.1, stroke: "#0388C6" },
            { percent: props.data.completed * 0.1, stroke: "#4FED8B" },
          ]}
        />
        <div className={css.details}>
          <div className={css.value}>{props.data.completed}</div>
          <div className={css.text}>COMPLETED</div>
          <div className={css.button} onMouseDown={props.onViewSkillGaps}>
            <SmallWhiteButton>View Skill Gaps</SmallWhiteButton>
          </div>
        </div>
      </div>
      <div className={css.column}>
        <div className={css.pair}>
          <div className={css.type}>Average Proficiency</div>
          <div className={css.value}>
            {profNames[props.data.averageProficiency]}
          </div>
        </div>
        <div className={css.pair}>
          <div className={css.type}>Average Mindset</div>
          <div className={css.value}>
            {mindsetNames[props.data.averageMindset]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
