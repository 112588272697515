import React, { useCallback, useEffect, useReducer, useState } from "react";

import css from "./GroupDialog.module.css";
import { GroupNamePrompt } from "./GroupNamePrompt";
import { StudentList } from "./StudentList";
import pencil from "./Pencil.svg";
import { DeleteConfirmation } from "./DeleteConfirmation";

const filterLeft = (groupStudents, allStudents) =>
  allStudents.filter((name) => groupStudents.indexOf(name) === -1);

const moveRight = (state) => {
  if (!state.selectedLeft) return state;
  const students = [...state.group.students, state.selectedLeft];
  return {
    ...state,
    selectedLeft: null,
    nonGroupStudents: filterLeft(students, state.allStudents),
    group: {
      ...state.group,
      students,
    },
  };
};

const moveLeft = (state) => {
  if (!state.selectedRight) return state;
  const students = state.group.students.filter(
    (name) => name != state.selectedRight
  );
  return {
    ...state,
    selectedRight: null,
    nonGroupStudents: filterLeft(students, state.allStudents),
    group: {
      ...state.group,
      students,
    },
  };
};

const reducer = (state, action) => {
  switch (action && action.type) {
    case "move-right":
      return moveRight(state);
    case "move-left":
      return moveLeft(state);
    case "select-left":
      return {
        ...state,
        selectedLeft: action.payload.name,
      };
    case "select-right":
      return {
        ...state,
        selectedRight: action.payload.name,
      };
    case "set-name":
      return {
        ...state,
        group: {
          ...state.group,
          name: action.payload.name,
        },
      };
    case "show-delete":
      return { ...state, deleteVisible: true };
    case "edit-name":
      return {
        ...state,
        nameEditable: true,
      };
    default:
      return state;
  }
};

const initialState = (group, allStudents) => {
  return {
    deleteVisible: false,
    selectedLeft: null,
    selectedRight: null,
    allStudents,
    nameEditable: false,
    nonGroupStudents: filterLeft(group?.students || [], allStudents),
    group: group || { name: "", students: [] },
  };
};

export const GroupDialog = ({
  group,
  allStudents,
  onGroupChanged,
  onCanceled,
  onDelete,
}) => {
  const [pastCreate, setPastCreate] = useState(false);
  const [state, dispatch] = useReducer(
    reducer,
    initialState(group, allStudents)
  );

  const showDelete = useCallback(() => dispatch({ type: "show-delete" }), []);

  const setName = useCallback((name) => {
    setPastCreate(true);
    dispatch({ type: "set-name", payload: { name } });
  }, []);

  const editName = useCallback((name) => dispatch({ type: "edit-name" }), []);

  const moveRight = useCallback(
    () => dispatch({ type: "move-right", payload: {} }),
    []
  );
  const moveLeft = useCallback(
    () => dispatch({ type: "move-left", payload: {} }),
    []
  );
  const selectLeft = useCallback(
    (name) => dispatch({ type: "select-left", payload: { name } }),
    []
  );
  const selectRight = useCallback(
    (name) => dispatch({ type: "select-right", payload: { name } }),
    []
  );
  const editableGroup = state.group;

  if (!editableGroup.name && !pastCreate)
    return (
      <div className={css.backdrop}>
        <GroupNamePrompt
          name={editableGroup.name}
          onCanceled={onCanceled}
          onChange={setName}
        />
      </div>
    );

  if (state.deleteVisible) {
    return (
      <div className={css.backdrop}>
        <DeleteConfirmation
          onCanceled={onCanceled}
          onDelete={onDelete}
          groupName={editableGroup.name}
          studentCount={editableGroup.students.length}
        />
      </div>
    );
  }

  return (
    <div className={css.backdrop}>
      <div className={css.editDialog}>
        <div className={css.header}>
          Edit Group
          <button className={css.closeButton} onClick={onCanceled} />
        </div>
        <div className={css.nameRow}>
          {state.nameEditable && (
            <span className={css.nameInput}>
              Group Name:
              <input
                type="text"
                autoFocus={true}
                value={editableGroup.name}
                onChange={(event) => setName(event.target.value)}
              />
            </span>
          )}

          {state.nameEditable || (
            <span>
              {editableGroup.name}
              <img onClick={editName} src={pencil} />
            </span>
          )}
          <button onClick={showDelete} className={css.deleteButton}>
            Delete Group
          </button>
        </div>
        <div className={css.body}>
          <StudentList
            students={state.nonGroupStudents}
            selectStudent={selectLeft}
            selectedStudent={state.selectedLeft}
          >
            Students
          </StudentList>
          <div className={css.buttonColumn}>
            <button
              className={`${css.moveButton} ${css.moveRight}`}
              onClick={moveRight}
            />
            <button
              className={`${css.moveButton} ${css.moveLeft}`}
              onClick={moveLeft}
            />
          </div>
          <StudentList
            students={state.group.students}
            selectStudent={selectRight}
            selectedStudent={state.selectedRight}
            selectedClass={css.rightArrow}
          >
            Group
          </StudentList>
        </div>
        <div className={css.footer}>
          <button className={css.cancelButton} onClick={onCanceled}>
            Cancel
          </button>
          <button
            className={css.saveButton}
            onClick={() => onGroupChanged(editableGroup)}
          >
            Save Group
          </button>
        </div>
      </div>
    </div>
  );
};
