
/*
 With the new light theme, this isn't really a "window" anymore, but I'm not swapping out a proper route for it, so I'm leaving the name as-is.

*/

import React, { useState } from "react";
import styled from "@emotion/styled";
import studentData from "../../../data/students.json";
import DropDown from "../../../components/DropDown";
import data from "../../../data/findcontent-with-blocks.json";
import { SmallBlueButton, SmallPurpleButton, SmallWhiteButton } from "../../../components/BlueButton";

import {
  Cell,
  CellNoBorderLeft,
  CellNoBorderRight,
  Header,
  HeaderRow,
  Row,
  Table,
  YellowRow,
} from "../../../components/ReportTables";
import { Calendar as CalendarMockup } from "./Calendar";
import { initialGroups } from "../defaultGroups";
import { LightColors } from "../../../light-theme";
import PencilIcon from "./PencilIcon";
import TrashCan from "./TrashCan";


const Link = styled.a({
  color: LightColors.blue,

  marginLeft: 5, marginRight: 5,
  display: "inline-block",
  fontSize: 16,
  fontFamily: 'Open Sans',
  fontWeight: 700,
  svg: {
    marginLeft: 5,
    marginRight: 5,
  }
})


const CalendarHolder = styled.div({
  position: "absolute",
  top: "0",
  left: "0",
  zIndex: "500",
});

const Calendar = ({ onClick }) => {
  return (
    <CalendarHolder onClick={onClick}>
      <CalendarMockup onClick={onClick} />
    </CalendarHolder>
  );
};
const OptionsRow = styled.div({
  display: "flex",
  gap: 10,
  paddingRight: 10,
  alignItems: "center",
  justifyContent: "space-between"
});

const Banner = styled.div({
  position: "fixed",
  bottom: 0,
  left: 0,
  backgroundColor: LightColors.purple,
  right: 0,
  height: 70,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  padding: 10,
  boxSizing: "border-box",
  fontFamily: "Open Sans",
  fontSize: 14,
  fontWeight: 400,

  letterSpacing: 0.4000000059604645,
  textAlign: "right",
  gap: 15,
  color: "#FBFCFD",
  alignItems: "center",
});

const Backdrop = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.1)",
  zIndex: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Window = styled.div({
  position: "fixed",
  top: 100,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: "auto",
  paddingBottom: 100,

  border: "1px solid #FBFCFD",
  backgroundColor: "#FBFCFD",
});

const Content = styled.div({ padding: 15 });

const Title = styled.div({
  color: LightColors.black,
  fontSize: 18,
  fontFamily: 'Open Sans',
  fontWeight: 700,
  padding: 15,

});

const Spacer = styled.div({ flexGrow: 1 });

const idToTitle = (id) => data.lessons[id] || "?";

const AssignmentRow = ({ lesson }) => {
  const [calendar, setCalendar] = useState(-1);
  const showCalendar = (which) => (event) => setCalendar(which);
  const hideCalendar = (event) => {
    setCalendar(-1);
  };
  return (
    <Row>
      <Cell>{idToTitle(lesson)}</Cell>
      <Cell>
        <Link onClick={showCalendar(0)}>5/12/2022</Link>
        {calendar === 0 && <Calendar onClick={hideCalendar} />}
      </Cell>
      <Cell>
        <Link onClick={showCalendar(1)}>5/31/2022</Link>
        {calendar === 1 && <Calendar onClick={hideCalendar} />}
      </Cell>
      {/* <Cell>Choose students</Cell> */}
      <Cell>
        <Link>
          <PencilIcon />
          Class</Link>
      </Cell>
      <Cell>
        <TrashCan />
      </Cell>
    </Row>
  );
};

const BackLink = styled.a({
  color: LightColors.blue,
  display: "block",

  fontSize: 16,
  marginTop: 20,
  padding: 15,
  fontFamily: 'Open Sans',
  fontWeight: 700,
  cursor: "pointer",
})

const groupsToDropdown = (groups) =>
  groups.map((g) => ({
    ...g,
    text: g.name === "Select Group" ? "All Students" : g.name,
  }));

export const AssignmentWindow = ({ selectedLessons, onClose, groups }) => {
  const [groupIndex, setGroupIndex] = useState(0);
  const students = (groups && groups[groupIndex]?.students) || [];
  const groupsFor = (studentId) => {
    return groups
      ?.filter((g) => g.students.find((s) => s.id === studentId))
      .map((g) => g.name)
      .filter((name) => name !== "Select Group")
      .join(", ");
  };
  return (
    <Backdrop>
      <Window>
        <BackLink onClick={onClose}>&lt; Continue browsing assignments</BackLink>
        <Title>Assignment Details</Title>
        <Content>
          <p>Below are recommended due dates for each assigment. </p>
          <p>
            Important: Changing due dates after students have started an
            assignment could impact your reports.
          </p>
          <p>
            You can assign Skill Quiz up to 3 times. Please check that students
            have completed the prior assigment before reassigning, and make the
            start and due dates unique.
          </p>
          <OptionsRow>
            {/* <DropDown
              width="200px"
              selectedIndex={groupIndex}
              selectIndex={setGroupIndex}
              data={groupsToDropdown(groups || initialGroups)}
              defaultText="Choose Program"
              content
            />
            <Spacer /> */}
            <Link>Click on the start or end dates to update</Link>
            <SmallWhiteButton>Update Multiple Dates</SmallWhiteButton>
          </OptionsRow>
          <Table>
            <thead>
              <HeaderRow>
                <Header>Assignment</Header>
                <Header>Start Date</Header>
                <Header>Due Date</Header>
                <Header>Assign To</Header>
                <Header>Delete</Header>
              </HeaderRow>
              <YellowRow><td colSpan={5} /></YellowRow>
            </thead>
            <tbody>
              {selectedLessons.map((lesson, index) => {
                return <AssignmentRow key={index} lesson={lesson} />;
              })}
            </tbody>
          </Table>

          {/* <Table>
            <thead>
              <HeaderRow>
                <Header colSpan={2}>Name</Header>
                <Header>Nickname</Header>
                <Header>ID</Header>
                <Header>Group(s)</Header>
              </HeaderRow>
            </thead>
            <tbody>
              {students.map((s, index) => {
                return (
                  <Row key={index}>
                    <CellNoBorderRight width={15}>
                      <input type="checkbox" />
                    </CellNoBorderRight>
                    <CellNoBorderLeft>{s.name}</CellNoBorderLeft>
                    <Cell>{s.nickname}</Cell>
                    <Cell>{s.id}</Cell>
                    <Cell>{groupsFor(s.id)}</Cell>
                  </Row>
                );
              })}
            </tbody>
          </Table> */}
        </Content>
        <Banner>
          <SmallPurpleButton onClick={onClose} color={LightColors.purple}>Cancel</SmallPurpleButton>
          <SmallWhiteButton onClick={onClose} color={LightColors.purple}>Assign</SmallWhiteButton>
        </Banner>

      </Window>
    </Backdrop>
  );
};
