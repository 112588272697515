import styled from "@emotion/styled";
import { LightColors } from "../../../light-theme";
export const Title = styled.div({  
  label: "Title",
  fontFamily: "Open Sans",  
  fontSize: 17,
  fontWeight: 700,
  letterSpacing: 0.4000000059604645,
  textAlign: "left",
  margin: 20,
  
});

export const Container = styled.div({ 
  label: "Container",
  margin: 10, paddingBottom: 200 });

export const StrandBlock = styled.div({
  label: "StrandBlock",
  border: `1px solid ${LightColors.grey1}`,
  backgroundColor: LightColors.white,
  margin: 18,
  borderRadius: 8,
  display: "flex",
  padding: 18,
  alignItems: "center",
  borderLeft: `6px solid ${LightColors.blue}`,

});

export const LessonTable = styled.table({
  label: "LessonTable",
  marginLeft: -19,
  marginRight: -19,
  marginTop: 18,
  marginBottom: -19,
  backgroundColor: LightColors.white,
  borderCollapse: "collapse",
  "& tr": {
    border: `1px solid ${LightColors.grey1}`,
    "& td": {
      borderTop: `1px solid ${LightColors.grey1}`,
      padding: 10,
    },
    "& td:nth-of-type(1)": { width: 20 },
    "& td:nth-of-type(2)": { width: 500 },
    "& td:nth-of-type(3)": {
      width: 20,
      "> svg": { position: "relative", top: 2 },
    },
    "& td:nth-of-type(4)": { width: 120 },
    "& td:nth-of-type(5)": { width: 500 },
    "& td:nth-of-type(6)": { width: 20 },
  },
});

export const BackOrOptions = styled.div({
  label: "BackOrOptions",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const Options = styled.div({
  label: "Options", 
  display: "flex",
  flexDirection: "row",
  gap: 10,
  alignItems: "center",
});

export const DetailContainer = styled(StrandBlock)(
  ({ backgroundColor, borderRadius = 0, leftBorder = false }) => ({
    label: "DetailContainer",
    borderRadius,
    flexDirection: "column",
    backgroundColor,
    borderLeft: leftBorder ? `6px solid ${LightColors.blue}` : `1px solid ${LightColors.grey1}`,
  })
);

export const YellowDiv = styled.div({
  label: "YellowDiv",
  backgroundColor: LightColors.yellow,
  height: 5,
  width: "100%",
})
export const DetailRow = styled.div(({ backgroundColor }) => ({
  label: "DetailRow",
  display: "flex",
  flexDirection: "row",
  backgroundColor,
}));

export const DetailDetails = styled.div({
  label: "DetailDetails",
  display: "flex",
  flexDirection: "column",
  width: 1080,
});

export const DetailExpand = styled.div({ 
  label: "DetailExpand",
  width: 30 });

export const Filters = styled.div({
  label: "Filters",
  paddingLeft: 17,
  display: "flex",
  paddingRight: 25,
  justifyContent: "space-between",
});
