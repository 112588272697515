import React, { Fragment } from "react";
import css from "./GemEntry.module.css";
import StudentRow from "./StudentRow";

import Diamond from "./assets/diamond.png";
import Emerald from "./assets/emerald.png";
import Ruby from "./assets/ruby.png";
import Sapphire from "./assets/sapphire.png";

const types = {
  Diamond: Diamond,
  Sapphire: Sapphire,
  Ruby: Ruby,
  Emerald: Emerald,
};

const GemEntry = (props) => {
  const studentRows = props.data.map((x, index) => (
    <StudentRow key={index} type={props.type} data={x} />
  ));

  const content = <div className={css.contents}>{studentRows}</div>;

  return (
    <div className={css.root}>
      <div className={css.type}>
        <div className={css.icon}>
          <img src={types[props.type]} />
        </div>
        <div className={css.text}>{props.type}</div>
      </div>
      <div className={css.divider} />
      {content}
    </div>
  );
};

export default GemEntry;
