import React from "react";

import css from "./ShoutOuts.module.css";

import data from "../../../../../data/shoutout.json";
import { Cell, CellNoBorder, CellNoBorderRight, LightRowNoBorder, Row, Table } from "../../../../../components/ReportTables";

import styled from "@emotion/styled";
import LearnIcon from "./LearnIcon";
import PointsIcon from "./PointsIcon";
import PracticeIcon from "./PracticeIcon";
import GemsIcon from "./GemsIcon";
import { rolloverTipBegin, rolloverTipEnd } from "../../../../../data/rollovertips";
import { LightColors } from "../../../../../light-theme";

const FakeLink = styled.span({
  color: LightColors.blue
});

const Entry = styled.div({


  fontFamily: 'Open Sans',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",

  letterSpacing: '0em',
  textAlign: 'left',
  marginBottom: 10,


  paddingLeft: 40,
  position: "relative",
  "svg": {
    position: "absolute",
    top: -2,
    left: 0,
  },
  "ul": {
    listStyle: "none",
    paddingLeft: 0,
  }
})

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
]

const ShoutOuts = (props) => {
  const entries = data[props.subject][props.grade];


  return (
    <div className={css.root}>
      <Table>
        <thead>
          <LightRowNoBorder>
            <CellNoBorder colSpan={2}><b>SHOUT-OUT FOR THIS WEEK</b></CellNoBorder>
            <CellNoBorderRight width={150}>This Week |
              <FakeLink
                onMouseEnter={(ev) =>
                  rolloverTipBegin(
                    ev,
                    "ShoutOutLastWeek"
                  )
                }
                onMouseLeave={rolloverTipEnd}
              >Last Week</FakeLink></CellNoBorderRight>
          </LightRowNoBorder>
        </thead>
        <tbody>
          {days.map((day, index) => (
            <>
              <Row>
                <Cell colSpan={3}>{day}</Cell>
              </Row>
              {

                entries.filter(e => e.day === day).map((entry, index) => {
                  const hasEntry = !!entry.student;
                  return (
                    <LightRowNoBorder key={index}>
                      {(hasEntry) || (<Cell colSpan={3} style={{ paddingLeft: 50 }}><b>No Shout-Outs</b></Cell>)}
                      {(hasEntry) && (
                        <>
                          <Cell width={200} >
                            <b>{entry.student}</b>
                          </Cell>
                          <Cell colSpan={2}>
                            <Learns learns={entry.lessons} />
                            <Points points={entry.points} />
                            <Practices practices={entry.skill} />
                            <Gems gems={entry.gems} />
                          </Cell></>)}
                    </LightRowNoBorder>
                  )
                })
              }
            </>))}



        </tbody>
      </Table>

    </div>
  );
};

const Learns = ({ learns }) => {
  if (!learns) return null;
  return (
    <Entry>
      <LearnIcon />
      Completed Learn(s): {learns.join(", ")}
    </Entry>
  )
}

const Points = ({ points }) => {
  if (!points) return null;
  return (
    <Entry>
      <PointsIcon />
      Earned {points} points
    </Entry>
  )
}

const Practices = ({ practices }) => {
  if (!practices) return null;
  return (
    <Entry>
      <PracticeIcon />
      Completed Practice(s): {practices}
    </Entry>
  )
}

const Gems = ({ gems }) => {
  if (!gems) return null;
  return gems.map((gem, index) => (
    < Entry >
      <GemsIcon />
      {gem}
    </Entry >
  ))

}





export default ShoutOuts;
